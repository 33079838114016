import * as React from "react";
import {
  Button,
  DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm,
  ExpandableSection,
  HelperText, HelperTextItem, InputGroup,
  Popover,
  TextInput, InputGroupItem
} from "@patternfly/react-core";

import {
  CheckCircleIcon, DownloadIcon,
  ExclamationTriangleIcon,
  HelpIcon,
  PencilAltIcon
} from "@patternfly/react-icons";

import { useContext, useState } from "react";
import { Ticket, TicketApi, Order } from "@src/api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFTicket } from "@app/Webshop/TicketPortal/PDFTicket";
import { SystemContext } from "@app/AppContext/SystemContext";

interface TicketProps {
  ticket: Ticket;
  order: Order;
}

const TicketCard: React.FunctionComponent<TicketProps> = (props: TicketProps) => {

  const systemContext = useContext(SystemContext);

  const [personName, setPersonName] = useState(props.ticket.ownerName)
  const [actionRequired, setActionRequired] = useState(props.ticket.ownerName == null || props.ticket.ownerName == "")
  const visibleTicketNumber = (props.ticket.orderRank || 0) + 1;

  const renderCardColor = () => {
    if (actionRequired) {
      return "#ffd8d8";
    } else if(props.ticket.used) {
      return "#f1f1f1";
    } else {
      return "#dbffd8";
    }
  }

  const renderSaveButtonDisplayingMode = () => {
    return actionRequired ? "" : "none";
  }

  const renderEditButtonDisplayingMode = () => {
    return (actionRequired || props.ticket.used ) ? "none" : "";
  }

  const saveName = () => {

    (new TicketApi()).saveName( { name : personName, key : props.order.orderKey, orderRank : props.ticket.orderRank })
      .then(() => {
        setActionRequired(false)
        systemContext.addNotification("gespeichert!", "success", new Date().getTime())
      })
      .catch(err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })

    return actionRequired ? "none" : "";
  }

  const getPDFFileName = () : string => {
    const eventName = props.order.event?.eventName || "myTicket"
    const customerName = props.ticket.ownerName || ""

    const modifiedEventName = eventName.replace(/[^a-zA-Z0-9öÖäÄüÜ]/g, '')
    const modifiedCustomerName = customerName.replace(/[^a-zA-Z0-9öÖäÄüÜ]/g, '')

    return modifiedEventName + "_" + modifiedCustomerName + "_Nr" + props.ticket.orderRank
  }


  const renderToggleContent = () => {
    if (actionRequired) {
      return (
        <div>
          <ExclamationTriangleIcon color="red"/>
          <span> {props.order.event?.eventName} #{visibleTicketNumber}: Personalisierung erforderlich!</span>
        </div>
      );
    } else {
      return (
        <div>
          <CheckCircleIcon color="green"/>
          <span translate={"no"}> {props.order.event?.eventName} #{visibleTicketNumber}: {personName}</span>
        </div>
      );
    }
  }

  const renderDownload = () => {
    return (<>
      <PDFDownloadLink document={<PDFTicket ticket={props.ticket} order={props.order} />} fileName={getPDFFileName()}>
        {({ blob, url, loading, error }) => {
          if(error) {
            systemContext.addNotification(error.message, "danger", new Date().getTime())
            return 'Fehler beim generieren des Tickets. Versuchen Sie es später erneut oder kontaktieren Sie den Support!'
          }
          return loading
            ? 'Ticket wird geladen...'
            : <Button name={"download"} aria-label={"download"} icon={<DownloadIcon />} variant="primary" size="sm">Ticket herunterladen</Button>
        }}
      </PDFDownloadLink>
    </>);
  }

  return (<>
    <ExpandableSection style={{backgroundColor: renderCardColor()}}
                       toggleContent={renderToggleContent()}
                       displaySize="lg">
      <DescriptionList columnModifier={{ lg: '2Col' }}>
        <DescriptionListGroup>
          <DescriptionListTerm>
            { actionRequired ? "Wem gehört dieses Ticket?" : "Dieses Ticket gehört:" }


            <span style={ { color: "red" } }>*</span>
            <Popover
              headerContent={<div>Warum werden diese Daten benötigt?</div>}
              bodyContent={
                <div>
                  <p>
                    Zur Abwicklung der Print@Home Bestellung ist es notwendig, dass jedes Ticket einem eindeutigen Besitzer zugeordnet ist.
                    Bei Angabe von falschen Informationen besteht keinerlei Anspruch auf die erworbene Leistung!
                  </p>
                  <br/>
                  <p>
                    Die Daten werden keinesfalls an Dritte weitergegeben und können bis zum Beginn der Veranstaltung selbstständig geändert werden.
                    Nach der Veranstaltung werden die Daten laut DSGVO gelöscht bzw. unkenntlich gemacht!
                  </p>
                </div>
              }
            >
              <Button variant="plain" size="sm" style={ {padding: "5px"} }>
                <HelpIcon  />
              </Button>
            </Popover>
          </DescriptionListTerm>

          <DescriptionListDescription>
            <InputGroup>
              <InputGroupItem isFill ><TextInput
                isRequired
                isDisabled={!actionRequired}
                type="text"
                id={"name" + props.ticket.orderRank}
                name={"name" + props.ticket.orderRank}
                aria-describedby="name-input-helper"
                value={personName}
                onChange={(e, val) => setPersonName(val)}
              /></InputGroupItem>

              <InputGroupItem><Button
                style={{display: renderSaveButtonDisplayingMode()}}
                variant="tertiary"
                name="save"
                isDisabled={!actionRequired}
                onClick={() => saveName()}
              >
                Speichern
              </Button></InputGroupItem>

              <InputGroupItem><Button
                style={{display: renderEditButtonDisplayingMode()}}
                variant="tertiary"
                name="edit"
                isDisabled={actionRequired}
                onClick={() => setActionRequired(true)}
              >
                <PencilAltIcon/>
              </Button></InputGroupItem>
            </InputGroup>
            <HelperText>
              <HelperTextItem>Für ein gültiges Ticket ist ein vollständiger Name (Vor- und Nachname) notwendig.</HelperTextItem>
            </HelperText>
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup>
          <DescriptionListTerm>
            Ihr Ticket:
          </DescriptionListTerm>

          <DescriptionListDescription>
            { !actionRequired ? renderDownload() : <></> }
          </DescriptionListDescription>
        </DescriptionListGroup>



        <DescriptionListGroup>
          <DescriptionListTerm>
            TicketCode (unvollständig):
          </DescriptionListTerm>

          <DescriptionListDescription>
            { props.ticket.ticketCode?.substring(0, 13) }...
          </DescriptionListDescription>
        </DescriptionListGroup>


      </DescriptionList>
    </ExpandableSection>

    <br/>
  </>);
}

export {TicketCard}
