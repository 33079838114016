/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TicketCountResponse
 */
export interface TicketCountResponse {
    /**
     * 
     * @type {string}
     * @memberof TicketCountResponse
     */
    eventName?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketCountResponse
     */
    maxAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketCountResponse
     */
    sumNew?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketCountResponse
     */
    sumPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketCountResponse
     */
    sumStorno?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketCountResponse
     */
    sumDone?: number;
}

/**
 * Check if a given object implements the TicketCountResponse interface.
 */
export function instanceOfTicketCountResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketCountResponseFromJSON(json: any): TicketCountResponse {
    return TicketCountResponseFromJSONTyped(json, false);
}

export function TicketCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'maxAvailable': !exists(json, 'maxAvailable') ? undefined : json['maxAvailable'],
        'sumNew': !exists(json, 'sumNew') ? undefined : json['sumNew'],
        'sumPayed': !exists(json, 'sumPayed') ? undefined : json['sumPayed'],
        'sumStorno': !exists(json, 'sumStorno') ? undefined : json['sumStorno'],
        'sumDone': !exists(json, 'sumDone') ? undefined : json['sumDone'],
    };
}

export function TicketCountResponseToJSON(value?: TicketCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventName': value.eventName,
        'maxAvailable': value.maxAvailable,
        'sumNew': value.sumNew,
        'sumPayed': value.sumPayed,
        'sumStorno': value.sumStorno,
        'sumDone': value.sumDone,
    };
}

