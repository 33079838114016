import React from "react";
import { Card, CardTitle, CardBody } from "@patternfly/react-core";
import { Link } from "react-router-dom";

const Impressum: React.FunctionComponent = () => (
  <>

    <Card isFlat isRounded isLarge>
      <CardTitle>Impressum gemäß § 25 Mediengesetz</CardTitle>
      <CardBody>
        <b>Verantwortlich für den Inhalt dieser Webseite:</b><br/>
        Peirlberger Jürgen (Privatperson)<br/>
        Niederndorf 16, <br/>
        4274 Schönau im Mühlkreis <br/>
        Österreich

        <br/><br/>

        <b>Kontakt:</b><br/>
        E-Mail: office[at]tickatr.at

        <br/><br/>

        <b>Haftungsausschluss:</b><br/>

        Wir entwickeln dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.
        Für gegebene Informationen zu Veranstaltungen sind die jeweiligen Veranstalter verantwortlich.
        Die Veröffentlichung neuer Veranstaltungen wird von unserem Team sorgfältig geprüft.
        Daher kann keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen übernommen werden.
        Für Inhalte von externen Links sind ausschließlich die jeweiligen Betreiber verantwortlich.

        <br/><br/>

        <b>Urheberrecht:</b><br/>
        Die auf dieser Webseite veröffentlichten Inhalte und Werke unterliegen dem österreichischen Urheberrecht.
        Jegliche Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der schriftlichen Zustimmung des Autors bzw. Erstellers.

        Veranstalter sind beim Hochladen neuer Inhalte verantwortlich, urheberrechtliche Fragen zu klären und übernehmen die Verantwortung für alle Inhalte, die im Bezug auf deren Veranstaltungen stehen.
        Wir können für diese Inhalte keine Verantwortung übernehmen.
        <br/>
        <b>Verwendete Bildressourcen:</b> <a href="https://storyset.com/enjoy">Used illustrations by Storyset.</a>

        <br/><br/>

        <b>Datenschutz:</b><br/>
        Bitte beachten Sie unsere <Link to="/datenschutz" target="_blank">Datenschutzerklärung</Link>.

        <br/><br/>

        <b>Bitte beachten Sie, dass dieses Impressum für eine private Webseite dient und nicht die Anforderungen an gewerbliche Betreiber erfüllt.</b>
      </CardBody>
    </Card>
  </>
)

export { Impressum };