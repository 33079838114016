/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRole } from './UserRole';
import {
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    displayName?: string;
    /**
     * 
     * @type {UserRole}
     * @memberof Session
     */
    userRole?: UserRole;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    authenticationTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    authenticationToken?: string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    authenticationLeaseTime?: number;
}

/**
 * Check if a given object implements the Session interface.
 */
export function instanceOfSession(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'userRole': !exists(json, 'userRole') ? undefined : UserRoleFromJSON(json['userRole']),
        'authenticationTime': !exists(json, 'authenticationTime') ? undefined : (new Date(json['authenticationTime'])),
        'authenticationToken': !exists(json, 'authenticationToken') ? undefined : json['authenticationToken'],
        'authenticationLeaseTime': !exists(json, 'authenticationLeaseTime') ? undefined : json['authenticationLeaseTime'],
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'displayName': value.displayName,
        'userRole': UserRoleToJSON(value.userRole),
        'authenticationTime': value.authenticationTime === undefined ? undefined : (value.authenticationTime.toISOString()),
        'authenticationToken': value.authenticationToken,
        'authenticationLeaseTime': value.authenticationLeaseTime,
    };
}

