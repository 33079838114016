import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { SystemContext } from "@app/AppContext/SystemContext";
import {
  Bullseye,
  Button, EmptyState,
  PageSection,
  Title
} from "@patternfly/react-core";
import { EventReportResponse, ReportApi } from "@src/api";
import ErrorFactory from "@app/Page/Error";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DownloadIcon } from "@patternfly/react-icons";
import { EventReportPDF } from "@app/Admin/Events/EventReportPDF";
import { ContentLoading } from "@app/Page/ContentLoading";

export const EventReportRenderer : React.FunctionComponent = () => {

  const { id } = useParams();
  const navigate = useNavigate()
  const systemContext = useContext(SystemContext)
  const [eventReportData, setEventReportData] = React.useState<EventReportResponse>();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const eventId = parseInt(id || "-1");
    fetchReportData(eventId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  const fetchReportData = (eventId) => {
    setLoading(true);
    (new ReportApi(systemContext.apiConfig)).getEventSummaryReport({ eventId : eventId })
      .then(res => {
        setEventReportData(res)
        setLoading(false);
      })
      .catch(err => navigate(ErrorFactory.generatePath(err, "FinalizeEvent / fetchReportData")))
  }

  const getPDFFileName = () : string => {
    const eventName = eventReportData?.event?.eventName || "event"
    const modifiedEventName = eventName.replace(/[^a-zA-Z0-9öÖäÄüÜ]/g, '')

    return modifiedEventName + "_report"
  }

  const renderDownload = () => {
    if(!eventReportData) {
      return <></>
    }

    return (<>
      <PDFDownloadLink document={<EventReportPDF eventReportData={eventReportData} />} fileName={getPDFFileName()}>
        {({ blob, url, loading, error }) => {
          if(error) {
            systemContext.addNotification(error.message, "danger", new Date().getTime())
            return 'Fehler beim generieren der Event-Zusammenfassung. Versuchen Sie es später erneut oder kontaktieren Sie den Support!'
          }
          return loading
            ? 'Event-Zusammenfassung wird geladen...'
            : <Button name={"download"} aria-label={"download"} icon={<DownloadIcon />} variant="primary" size="sm">Event-Zusammenfassung herunterladen</Button>
        }}
      </PDFDownloadLink>
    </>);
  }

  return (<>
    <PageSection isFilled>
      <Title headingLevel="h1">Eventbericht wird erstellt für <b>{ eventReportData?.event?.eventName }</b>: </Title>

      {
        loading ? <ContentLoading /> : <><EmptyState><div>{ renderDownload() }</div></EmptyState></>
      }

      <EmptyState>
        <Button variant={"secondary"} onClick={() => navigate("./..")}>
          zurück zur Veranstaltung
        </Button>
      </EmptyState>
    </PageSection>

  </>)
}