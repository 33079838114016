/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TicketRedeemRequest
 */
export interface TicketRedeemRequest {
    /**
     * 
     * @type {number}
     * @memberof TicketRedeemRequest
     */
    orderNum?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketRedeemRequest
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketRedeemRequest
     */
    beginningKeyChars?: string;
}

/**
 * Check if a given object implements the TicketRedeemRequest interface.
 */
export function instanceOfTicketRedeemRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketRedeemRequestFromJSON(json: any): TicketRedeemRequest {
    return TicketRedeemRequestFromJSONTyped(json, false);
}

export function TicketRedeemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketRedeemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNum': !exists(json, 'orderNum') ? undefined : json['orderNum'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'beginningKeyChars': !exists(json, 'beginningKeyChars') ? undefined : json['beginningKeyChars'],
    };
}

export function TicketRedeemRequestToJSON(value?: TicketRedeemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNum': value.orderNum,
        'rank': value.rank,
        'beginningKeyChars': value.beginningKeyChars,
    };
}

