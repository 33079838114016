/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShippingType = {
    None: 'NONE_',
    PrintAtHome: 'PRINT_AT_HOME',
    Post: 'POST'
} as const;
export type ShippingType = typeof ShippingType[keyof typeof ShippingType];


export function ShippingTypeFromJSON(json: any): ShippingType {
    return ShippingTypeFromJSONTyped(json, false);
}

export function ShippingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingType {
    return json as ShippingType;
}

export function ShippingTypeToJSON(value?: ShippingType | null): any {
    return value as any;
}

