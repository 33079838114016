import * as React from "react";
import { CalculatePriceRequest, EventInfo, OrderApi, PriceResult, WebShopOrder } from "@src/api";
import { Divider, Flex, FlexItem, Spinner, TextContent } from "@patternfly/react-core";
import { useContext, useEffect } from "react";
import { SystemContext } from "@app/AppContext/SystemContext";

interface IWizardProps {
  order : WebShopOrder
  event : EventInfo
  isSubmitted : boolean
}
const Step4Check : React.FunctionComponent<IWizardProps> = (props: IWizardProps) => {

  const systemContext = useContext(SystemContext);

  const [priceResult, setPriceResult] = React.useState<PriceResult>();

  useEffect(() => {
    (new OrderApi()).calculatePrice({
      shippingType : props.order.shippingType,
      ticketAmount: props.order.numberOfTickets,
      event: props.event.eventId
    } as CalculatePriceRequest)
      .then(res => setPriceResult(res))
      .catch( err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      } )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.event.eventId, props.order.numberOfTickets, props.order.shippingType])


  if(props.isSubmitted) {
    return (<>
      <Flex
        direction={{ default: 'column' }}
        alignItems={{ default: 'alignItemsCenter' }}
        spaceItems={{ default: 'spaceItemsLg' }}
      >
        <div>Bestellung wird verarbeitet...</div>
        <Spinner />
      </Flex>
    </>);
  }

  return (<>
    <TextContent>
      <div><b>Veranstaltung:</b> { props.event.eventName}</div>
      <div><b>Veranstalter:</b> { props.event.operator?.operatorName}</div>
      <div><b>Ort:</b> { props.event.eventLocation } </div>
      <div><b>Datum:</b> {props.event.eventDate?.toLocaleDateString() } </div>
    </TextContent>

    <br/>
    <Divider />
    <br/>

    <TextContent>
      <div><b>Käufer:</b> { props.order.customerName}</div>
      <div><b>E-Mail:</b> { props.order.customerMail} </div>
      <div><b>Adresse:</b> {props.order.customerAddress}, {props.order.customerPostcode} {props.order.customerCity} ({props.order.customerCountry}) </div>
    </TextContent>

    <br/>
    <Divider />
    <br/>

    <TextContent>
      <div><b>Anzahl der Tickets:</b> { props.order.numberOfTickets } </div>
      <div><b>Versandart:</b> { props.order.shippingType}</div>
    </TextContent>

    <br/>
    <Divider />
    <br/>

    <Flex justifyContent={{ default : 'justifyContentCenter' }} width="100%">
      <FlexItem>
        <div>
          <b>&euro; {priceResult?.ticketPrice}</b> Tickets +
          <b>&euro; {priceResult?.shippingPrice}</b> Versand: <br />
          <b>Gesamtpreis:</b> &euro; {priceResult?.totalPrice}
        </div>
      </FlexItem>
    </Flex>

    <br/>
    <Divider />
    <br/>

    <TextContent>
      <div><b>ZAHLUNG & VERSAND:</b> Die Zahlung der Tickets erfolgt mittels Überweisung auf unser Bankkonto.
        Sie erhalten alle Informationen nach Abschluss der Bestellung.
      </div>
    </TextContent>

    <br/>
    <Divider />
    <br/>

  </>)
}

export { Step4Check }
