import * as React from "react";
import {Step1Overview} from "@app/Webshop/OrderWizard/Step1Overview";
import {Step2Tickets} from "@app/Webshop/OrderWizard/Step2Tickets";
import {Step3Data} from "@app/Webshop/OrderWizard/Step3Data";
import {Step4Check} from "@app/Webshop/OrderWizard/Step4Check";
import { useContext, useEffect } from "react";
import { EventInfo, OrderApi, ShippingType, StoreOrderRequest, WebShopOrder } from "@src/api";
import * as Util from "@app/utils/nvl"
import {
  Modal,
  ModalVariant,
  Wizard,
  WizardHeader,
  WizardStep
} from "@patternfly/react-core";
import OrderSuccessPathFactory from "@app/Webshop/OrderWizard/OrderSuccess";
import ErrorFactory from "@app/Page/Error";
import { useNavigate } from "react-router";
import { SystemContext } from "@app/AppContext/SystemContext";

interface IOrderWizardProps {
  isWizardOpen : boolean
  setWizardOpen : React.Dispatch<React.SetStateAction<boolean>>
  wizardEvent : EventInfo
}

const OrderWizard : React.FunctionComponent<IOrderWizardProps> = (props: IOrderWizardProps) => {

  const systemContext = useContext(SystemContext);

  const [isStep2Valid, setStep2Valid] = React.useState(false);
  const [isStep3Valid, setStep3Valid] = React.useState(false);
  const [order, setOrder] = React.useState({} as WebShopOrder);
  const [isSubmitted, setSubmitted] = React.useState(false);

  const navigate = useNavigate();

  useEffect( () => {
    // re-init wizard form
    setStep2Valid(false)
    setStep3Valid(false)

    setOrder(() => ({
      ...{} as WebShopOrder,
      eventId: Util.nvlN(props.wizardEvent.eventId),
      shippingType: (props.wizardEvent.printAtHomeEnabled ? ShippingType.PrintAtHome : (props.wizardEvent.shippingEnabled ? ShippingType.Post : ShippingType.None)),
      customerCountry : "Austria",
      agbChecked: false,
      dsgvoChecked: false,
    }))

  }, [props.wizardEvent])

  const maxTickets = () => {
    const available = Util.nvlN(props.wizardEvent.maxAvailableTickets) - Util.nvlN(props.wizardEvent.ticketsSold)
    return Math.min(available, 8)
  }


  const submit = () => {
    setSubmitted(true); // enable loading

    (new OrderApi()).storeOrder( { webShopOrder : order } as StoreOrderRequest )
      .then( res => {
        if(res.length >= 0) {
          // success
          navigate(OrderSuccessPathFactory.generatePath(res.toString()))
          return null
        } else {
          navigate(ErrorFactory.generatePath("Failed to store order", "OrderWizard"))
          return null
        }
      })
      .catch(err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
  }

  const buttonText = { nextButtonText : "weiter", backButtonText : "zurück", cancelButtonText : "Abbrechen" }

  return (<>
    <Modal
      isOpen={props.isWizardOpen}
      variant={ModalVariant.large}
      showClose={false}
      hasNoBodyWrapper
      aria-describedby="wiz-modal-demo-description"
      aria-labelledby="wiz-modal-demo-title"
    >

    <Wizard
      isVisitRequired
      header={
        <WizardHeader title={props.wizardEvent.eventName || ""}
                      titleId="wiz-modal-demo-title"
                      description="Ticket Bestellung"
                      descriptionId="wiz-modal-demo-description"
                      onClose={() => props.setWizardOpen(false)}
        />
      }

      onClose={() => props.setWizardOpen(false)}
    >

      <WizardStep name="Veranstaltung" id="wizard-step-1" footer={{ ...buttonText }}>
        <Step1Overview key={1} event={props.wizardEvent} />
      </WizardStep>

      <WizardStep name="Tickets" id="wizard-step-2" footer={{ isNextDisabled: !isStep2Valid, ...buttonText }}>
        <Step2Tickets key={2}
                      setValidity={setStep2Valid}
                      order={order}
                      setOrder={setOrder}
                      maxTicketsToBuy={maxTickets()}
                      printAtHomeEnabled={Util.nvlB(props.wizardEvent.printAtHomeEnabled)}
                      postEnabled={Util.nvlB(props.wizardEvent.shippingEnabled)} />
      </WizardStep>

      <WizardStep name="Daten" id="wizard-step-3" footer={{ isNextDisabled: !isStep3Valid, ...buttonText }}>
        <Step3Data key={3} setValidity={setStep3Valid} order={order} setOrder={setOrder} />
      </WizardStep>

      <WizardStep name="Überprüfung" id="wizard-review"
                  footer={{
                    isBackDisabled: isSubmitted,
                    isNextDisabled: isSubmitted || !isStep2Valid || !isStep3Valid,
                    ...buttonText,
                    nextButtonText: 'Bestellung absenden!',
                    onNext: () => submit() }}>
        <Step4Check key={4} order={order} event={props.wizardEvent}  isSubmitted={isSubmitted} />
      </WizardStep>

    </Wizard>
    </Modal>
  </>)
}

export { OrderWizard }
