import React, { useContext, useEffect } from "react";

import brandImg from '@images/logoOutlineW.svg';

import {
  LoginFooterItem,
  LoginForm,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  ListVariant, Alert, Modal, ModalVariant, Button, Popover
} from "@patternfly/react-core";

import { Configuration, SessionApi } from "@src/api";
import { HelpIcon } from "@patternfly/react-icons";
import { useCookies } from "react-cookie";
import { SystemContext } from "@app/AppContext/SystemContext";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ContentLoading } from "@app/Page/ContentLoading";

export const Login: React.FunctionComponent = () => {
  const [showHelperText, setShowHelperText] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [isValidUsername, setIsValidUsername] = React.useState(!!username);
  const [password, setPassword] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(!!password);
  const [cookies, setCookies] = useCookies(['tickrLogin', "tickrSession"])
  const [invalidLogins, setInvalidLogins] = React.useState(cookies.tickrLogin || 0);

  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    setInvalidLogins(cookies.tickrLogin)
  }, [cookies.tickrLogin])

  useEffect(() => {
    if(cookies.tickrSession && cookies.tickrSession?.authenticationToken) {
      // show loading screen while stored session will be checked
      setLoading(true)

      const locSession = cookies.tickrSession
      systemContext.setSession(locSession);

      // verify session
      // using systemContext.apiConfig will not work as it is still null at this point
      (new SessionApi(new Configuration({ accessToken : locSession?.authenticationToken })).verify()
        .then(() => {
          // verification OK => redirect
          setLoading(false)
          navigate(location.state?.from?.pathname || '/eventadmin/')
        })
        .catch(() => {
          // 401 Unauthorized => session not valid
          setLoading(false)
          systemContext.setSession(undefined)
        }))
    } else {
      // no cookies found => login
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ /* execute once */ ])

  const onLoginAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if(invalidLogins >= 5) {
      return;
    }

    (new SessionApi()).login({userPassword : { username: username, password: password }})
      .then(res => {
        systemContext.setSession(res)

        setCookies("tickrSession", res, {
          maxAge : (res.authenticationLeaseTime ||  0) - 60,
          sameSite : "strict",
          path : "/eventadmin/",
          secure: true
        })

        // verification OK => redirect
        navigate(location.state?.from?.pathname || '/eventadmin/')
      })
      .catch(() => {
        setShowHelperText(true)
        setInvalidLogins(invalidLogins + 1)
        setCookies("tickrLogin", invalidLogins + 1, { maxAge : 600, sameSite : "strict"})
      })
  };

  const onChangeUserName = (event, value) => {
    setUsername(value)
    setIsValidUsername(!!value);
  }

  const onChangePassword = (event, value) => {
    setPassword(value)
    setIsValidPassword(!!value);
  }

  const signUpForAccountMessage = (
    <LoginMainFooterBandItem>
      Sie haben noch keinen Zugang? <a href="mailto:QUARKUS_PROFILE@tickatr.at">Hier anfordern.</a>
    </LoginMainFooterBandItem>
  );

  const forgotCredentials = (
    <LoginMainFooterBandItem>
      <a href="mailto:office@tickatr.at">Benutzer oder Passwort vergessen?</a>
    </LoginMainFooterBandItem>
  );

  const listItem = (
    <React.Fragment>
      <ListItem>
        <LoginFooterItem href="./impressum">Impressum</LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="./datenschutz">Datenschutz</LoginFooterItem>
      </ListItem>
    </React.Fragment>
  );

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText={<Alert variant="danger" isInline isPlain title="Ungültige Login Informationen" />}
      usernameLabel="Benutzername / E-Mail"
      usernameValue={username}
      onChangeUsername={(ev, value) => onChangeUserName(ev, value)}
      isValidUsername={isValidUsername}
      passwordLabel="Passwort"
      passwordValue={password}
      isShowPasswordEnabled
      onChangePassword={(ev, value) => onChangePassword(ev, value)}
      isValidPassword={isValidPassword}
      onLoginButtonClick={onLoginAction}
      loginButtonLabel="Login"
      isLoginButtonDisabled={!isValidUsername || !isValidPassword}
    />
  );

  if(loading) {
    return <ContentLoading />
  }

  return (
    <>
      <LoginPage
        footerListVariants={ListVariant.inline}
        brandImgSrc={brandImg}
        brandImgAlt="Logo"
        backgroundImgSrc={"/other"}
        footerListItems={listItem}
        textContent="Herzlich Willkommen zurück! Bitte melden Sie sich an, um auf Ihr Tick@r-Konto zuzugreifen und vollen Zugriff auf unsere Event-Welt zu erhalten."
        loginTitle="Anmelden"
        loginSubtitle="Bitte melden sie sich mit ihren Zugangsdaten an."
        signUpForAccountMessage={signUpForAccountMessage}
        forgotCredentials={forgotCredentials}
      >
        {loginForm}
      </LoginPage>

      <Modal
        variant={ModalVariant.small}
        title="Login blockiert!"
        titleIconVariant="danger"
        titleLabel="Login blockiert"
        isOpen={invalidLogins >= 5}
        showClose={false}
        help={
          <Popover
            headerContent={<div>Sie benötigen Hilfe?</div>}
            bodyContent={
            <div>
              Versuchen Sie den Login zu einem späteren Zeitpunkt erneut.
              Sollte dies ebenfalls Probleme verursachen, kontaktieren Sie ihren Administrator.
              Wir bitten um Entschuldigung!
            </div>
            }
          >
            <Button variant="plain" aria-label="Help">
              <HelpIcon />
            </Button>
          </Popover>
        }
      >
        Zu viele fehlgeschlagene Versuche.
        Daher wurde der Login vorübergehend blockiert.
      </Modal>
    </>
  );
};
