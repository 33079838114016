/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';

/**
 * 
 * @export
 * @interface ChangeStatusRequest
 */
export interface ChangeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusRequest
     */
    orderKey?: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof ChangeStatusRequest
     */
    toStatus?: OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusRequest
     */
    stornoPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusRequest
     */
    stornoReason?: string;
}

/**
 * Check if a given object implements the ChangeStatusRequest interface.
 */
export function instanceOfChangeStatusRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeStatusRequestFromJSON(json: any): ChangeStatusRequest {
    return ChangeStatusRequestFromJSONTyped(json, false);
}

export function ChangeStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderKey': !exists(json, 'orderKey') ? undefined : json['orderKey'],
        'toStatus': !exists(json, 'toStatus') ? undefined : OrderStatusFromJSON(json['toStatus']),
        'stornoPerson': !exists(json, 'stornoPerson') ? undefined : json['stornoPerson'],
        'stornoReason': !exists(json, 'stornoReason') ? undefined : json['stornoReason'],
    };
}

export function ChangeStatusRequestToJSON(value?: ChangeStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderKey': value.orderKey,
        'toStatus': OrderStatusToJSON(value.toStatus),
        'stornoPerson': value.stornoPerson,
        'stornoReason': value.stornoReason,
    };
}

