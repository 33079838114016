import React, { useContext, useEffect } from "react";
import { Tabs, Tab, TabTitleText, TabTitleIcon } from "@patternfly/react-core";
import { EyeIcon, OutlinedClockIcon } from "@patternfly/react-icons";
import { EventApi, EventFilterTypes, EventInfo } from "@src/api";
import { SystemContext } from "@app/AppContext/SystemContext";
import { ContentLoading } from "@app/Page/ContentLoading";
import { useLocation } from "react-router-dom";
import { EventTable } from "@app/Admin/Events/EventTable";

export const EventOverview : React.FunctionComponent = () => {
  const location = useLocation();

  const [events, setEvents] = React.useState<EventInfo[]>([]);
  const [isLoading, setLoading] = React.useState(true);
  const [activeTabIndex, setActiveTabIndex] = React.useState<string | number>(location.state?.tabIndex || 0);

  const systemContext = useContext(SystemContext)

  const fetchEvents = (activeTabIndex : number | string) => {

    // published
    if(activeTabIndex == 0) {
      (new EventApi(systemContext.apiConfig)).getEventList( { filter : EventFilterTypes.AdminCurrent } )
        .then(res => {
          setLoading(false)
          setEvents(res)
        })
        .catch(err => {
          systemContext.addNotification(err.toString(), "danger", new Date().getTime())
        })
    }
    // archived
    else if(activeTabIndex == 1) {
      (new EventApi(systemContext.apiConfig)).getEventList( { filter : EventFilterTypes.AdminArchived } )
        .then(res => {
          setLoading(false)
          setEvents(res)
        })
        .catch(err => {
          systemContext.addNotification(err.toString(), "danger", new Date().getTime())
        })
    }
  }

  useEffect(() => {
    fetchEvents(activeTabIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  const handleTabClick = (event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent, tabIndex: string | number) => {
    setActiveTabIndex(tabIndex);
    setEvents([])
    fetchEvents(tabIndex)
  };

  return (<>
    <div>
      <Tabs
        activeKey={activeTabIndex}
        onSelect={handleTabClick}
        aria-label="Events"
        role="region"
      >
        <Tab eventKey={0} title={<><TabTitleIcon><EyeIcon /></TabTitleIcon> <TabTitleText>Veranstaltungen</TabTitleText></>} aria-label="neue Bestellungen">
          {isLoading && <ContentLoading /> }
          {!isLoading && <EventTable title={"Veranstaltungen"} events={events} activeTabIndex={activeTabIndex} /> }
        </Tab>
        <Tab eventKey={1} title={<><TabTitleIcon><OutlinedClockIcon /></TabTitleIcon><TabTitleText>Archivierte Veranstaltungen</TabTitleText></>}>
          {isLoading && <ContentLoading /> }
          {!isLoading && <EventTable title={"Archivierte Veranstaltungen"} events={events} activeTabIndex={activeTabIndex} /> }
        </Tab>
      </Tabs>
    </div>
  </>);
}