import React from "react";

import hero from '@images/frontUI/hero.svg'
import { Button } from "@patternfly/react-core";
import { NavLink } from "react-router-dom";
import { ArrowRightIcon } from "@patternfly/react-icons";

const Hero : React.FunctionComponent = () => {
    return (
        <section className="hero-section">
            <div className="container-hero">
                <div className="content-hero">
                    <div className="left-side">
                        <h1><b>Tick@R.</b> Deine Eintrittskarte zur Welt der Erlebnisse!</h1>
                        <p>
                            Gemeinsam erleben, was das Leben zu bieten hat – Tick@R öffnet die Tür zu unvergesslichen Momenten.
                            Starte jetzt deine Reise mit Tick@R!
                        </p>
                        <form>
                            <div className="form-group">
                                <NavLink to={"/events"}>
                                    <Button><b>check</b> @ <b>events</b> <ArrowRightIcon /> </Button>
                                </NavLink>
                            </div>
                        </form>
                    </div>
                    <div className="right-side">
                        <img src={hero} alt={"hero image"} />
                    </div>
                    <div className="responsive">
                        <NavLink to={"/events"}>
                            <Button><b>check</b> @ <b>events</b> <ArrowRightIcon /> </Button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;