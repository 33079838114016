import React from "react";

import IcoPayment from "@images/frontUI/icoPayment.svg"
import IcoShipping from "@images/frontUI/icoShipping.svg"
import IcoDone from "@images/frontUI/icoDone.svg"

const Services : React.FunctionComponent = () => {
    return (
        <section className="services" >
            <div className="header-services">
                <h2>Ablauf & Service</h2>
            </div>
            <div className="container-services">
                <div className="grid-services">
                  <div className="card" id="0_payment">
                    <img src={IcoPayment} alt="Bestellung und Zahlung" width={200} height={200} />
                    <div className="info">
                      <h3>Bestellung & Zahlung</h3>
                      <p>
                        Zahlung erfolgt ausschließlich per <b>Überweisung</b>.
                        Alle erforderlichen Informationen erhalten Sie nach Abschluss der Bestellung per E-Mail.
                      </p>
                    </div>
                  </div>

                  <div className="card" id="1_shipping">
                    <img src={IcoShipping} alt="versand" width={200} height={200} />
                    <div className="info">
                      <h3>Versand</h3>
                      <p>
                        Sie erhalten ihre Tickets einfach und komfortabel als <b>Print@Home</b> oder traditionell per <b>Post</b>.
                        Beachten Sie bei Postversand die zusätzlichen Versandkosten und die längere Lieferzeit.
                      </p>
                    </div>
                  </div>

                  <div className="card" id={"0"}>
                    <img src={IcoDone} alt="fertig" width={200} height={200} />
                    <div className="info">
                      <h3>Personalisieren & Fertig!</h3>
                      <p>
                        Bringen Sie das Ticket ausgedruckt oder in <b>digitaler Form</b> zur Veranstaltung mit.
                        Um Missbrauch zu minimieren, ist für Print@Home-Tickets eine <b>Personalisierung erforderlich</b>.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
        </section>
    )
}

export default Services;