import { IAppRoute } from "@app/Routing/IAppRoute";
import { Route, useLocation } from "react-router-dom";
import { NotFound } from "@app/Page/NotFound";
import * as React from "react";
import { Navigate, Routes } from "react-router";
import { AppRouteConfig } from "@app/Routing/AppRouteConfig";
import { useContext } from "react";
import { SystemContext } from "@app/AppContext/SystemContext";

const RouteLoader = ( props : { routes : AppRouteConfig[], isSecured : boolean } ): React.ReactElement => {

  const systemContext = useContext(SystemContext)
  const location = useLocation();

  const flattenedRoutes: IAppRoute[] = props.routes.reduce(
    (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])], [] as IAppRoute[]
  );

  const ComponentWithTitleUpdate = ( { component : Component, title : title }: any) => {
    useDocumentTitle(title);
    return <Component />;
  };

  const PageNotFound = ({ title } : { title: string }) => {
    useDocumentTitle(title);
    return <NotFound />
  };

  const useDocumentTitle = (title: string) => {
    React.useEffect(() => {
      const originalTitle = document.title;
      document.title = title;

      return () => {
        document.title = originalTitle;
      };
    }, [title]);
  }


  if(props.isSecured && !systemContext.session?.authenticationToken) {
    return <Navigate to={"/eventadmin/login"} replace state={{ from: location }}  />
  }
  return (
    <Routes>
      {
        flattenedRoutes
          .filter(({ visibleFor}) => (
            visibleFor === undefined || visibleFor.length === 0 || visibleFor.includes(systemContext.session?.userRole || "")
          ))
          .map(({ path, component, title }, idx) => (
          <Route
            key={idx}
            path={path}
            caseSensitive={false}
            element={<ComponentWithTitleUpdate component={component} key={idx} title={title} />} />
        ))
      }

      <Route path="*" element={<PageNotFound title="404 Page Not Found" />}/>
    </Routes>
  )
}

export { RouteLoader }