/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventInfo } from './EventInfo';
import {
    EventInfoFromJSON,
    EventInfoFromJSONTyped,
    EventInfoToJSON,
} from './EventInfo';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { ShippingType } from './ShippingType';
import {
    ShippingTypeFromJSON,
    ShippingTypeFromJSONTyped,
    ShippingTypeToJSON,
} from './ShippingType';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderKey?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderNum?: number;
    /**
     * 
     * @type {EventInfo}
     * @memberof Order
     */
    event?: EventInfo;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerMail?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerCity?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerCountry?: string;
    /**
     * 
     * @type {ShippingType}
     * @memberof Order
     */
    shippingType?: ShippingType;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    numberOfTickets?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    totalPrice?: number;
    /**
     * 
     * @type {OrderStatus}
     * @memberof Order
     */
    orderStatus?: OrderStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    mailOrderConfirmationSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    mailTicketsSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    mailEventReminderSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    paymentType?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    stornoPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    stornoReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    creationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    modificationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    modificationDate?: Date;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderKey': !exists(json, 'orderKey') ? undefined : json['orderKey'],
        'orderNum': !exists(json, 'orderNum') ? undefined : json['orderNum'],
        'event': !exists(json, 'event') ? undefined : EventInfoFromJSON(json['event']),
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerMail': !exists(json, 'customerMail') ? undefined : json['customerMail'],
        'customerAddress': !exists(json, 'customerAddress') ? undefined : json['customerAddress'],
        'customerPostcode': !exists(json, 'customerPostcode') ? undefined : json['customerPostcode'],
        'customerCity': !exists(json, 'customerCity') ? undefined : json['customerCity'],
        'customerCountry': !exists(json, 'customerCountry') ? undefined : json['customerCountry'],
        'shippingType': !exists(json, 'shippingType') ? undefined : ShippingTypeFromJSON(json['shippingType']),
        'numberOfTickets': !exists(json, 'numberOfTickets') ? undefined : json['numberOfTickets'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : OrderStatusFromJSON(json['orderStatus']),
        'mailOrderConfirmationSent': !exists(json, 'mailOrderConfirmationSent') ? undefined : json['mailOrderConfirmationSent'],
        'mailTicketsSent': !exists(json, 'mailTicketsSent') ? undefined : json['mailTicketsSent'],
        'mailEventReminderSent': !exists(json, 'mailEventReminderSent') ? undefined : json['mailEventReminderSent'],
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'stornoPerson': !exists(json, 'stornoPerson') ? undefined : json['stornoPerson'],
        'stornoReason': !exists(json, 'stornoReason') ? undefined : json['stornoReason'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderKey': value.orderKey,
        'orderNum': value.orderNum,
        'event': EventInfoToJSON(value.event),
        'customerName': value.customerName,
        'customerMail': value.customerMail,
        'customerAddress': value.customerAddress,
        'customerPostcode': value.customerPostcode,
        'customerCity': value.customerCity,
        'customerCountry': value.customerCountry,
        'shippingType': ShippingTypeToJSON(value.shippingType),
        'numberOfTickets': value.numberOfTickets,
        'totalPrice': value.totalPrice,
        'orderStatus': OrderStatusToJSON(value.orderStatus),
        'mailOrderConfirmationSent': value.mailOrderConfirmationSent,
        'mailTicketsSent': value.mailTicketsSent,
        'mailEventReminderSent': value.mailEventReminderSent,
        'paymentType': value.paymentType,
        'stornoPerson': value.stornoPerson,
        'stornoReason': value.stornoReason,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
    };
}

