/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SystemInfo,
} from '../models';
import {
    SystemInfoFromJSON,
    SystemInfoToJSON,
} from '../models';

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     */
    async getSystemInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/getSystemInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemInfoFromJSON(jsonValue));
    }

    /**
     */
    async getSystemInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemInfo> {
        const response = await this.getSystemInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async pingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ping(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pingRaw(initOverrides);
    }

}
