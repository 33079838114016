import * as React from "react";
import { Alert, AlertGroup, AlertVariant } from "@patternfly/react-core";
import { useContext } from "react";
import { SystemContext } from "@app/AppContext/SystemContext";

const NotificationToastLayout : React.FunctionComponent = () => {

  const systemContext = useContext(SystemContext);

  return(<>
    <AlertGroup isToast isLiveRegion>
      {systemContext.notifications.map(({ key, variant, title }) => (
        <Alert
          variant={AlertVariant[variant || "info"]}
          title={title}
          timeout={4000}
          key={key}
        />
        ))}
    </AlertGroup>
  </>);
}

export { NotificationToastLayout };