import * as React from 'react';
import { Alert, PageSection, Title } from "@patternfly/react-core";
import { useEffect, useState } from "react";
import ErrorFactory from "@app/Page/Error";
import {TicketCard} from "@app/Webshop/TicketPortal/TicketCard";
import { Ticket, TicketApi, Order, OrderApi, ShippingType } from "@src/api";
import { ContentLoading } from "@app/Page/ContentLoading";
import { useNavigate, useParams } from "react-router";

const TicketPortal: React.FunctionComponent = () => {
  const { key } = useParams();

  const [tickets, setTickets] = useState<Ticket[]>([])
  const [order, setOrder] = useState<Order>({} as Order)
  const [redirectTo, setRedirectTo] = useState({error: false, msg: ""});
  const [isLoading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const keyParam = key || "undefined";

    (new TicketApi()).getTickets({ key : keyParam })
      .then(res => {
        setLoading(false)
        setTickets(res)
      })
      .catch(() => {
        setRedirectTo({error: true, msg: "Fehler beim Laden der Tickets!"})
      });

    (new OrderApi()).getOrder({ key : keyParam })
      .then(res => {
        setOrder(res)
      })
      .catch(() => {
        setRedirectTo({error: true, msg: "Fehler beim Laden der Bestellung!"})
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirectTo.error) {
    navigate(ErrorFactory.generatePath(redirectTo.msg, "TicketPortal"))
    return null
  }

  const renderTickets = () => {

    if(tickets.length > 0) {
      return tickets
        .map( (elem, idx) => { return (<div key={idx}> <TicketCard ticket={elem} order={order} /></div>);} )
    }

    if(order.shippingType != ShippingType.PrintAtHome) {
      return <Alert variant="warning" title="Für die gewünschten Tickets ist Print@Home nicht verfügbar!" />
    }

    return <Alert variant="danger" title="Unter diesem Link wurden keine Tickets gefunden!" />
  }

  if(isLoading) {
    return <ContentLoading />
  }

  return (
    <PageSection>
      <Title headingLevel="h1" size="lg" translate={"no"}>
        Deine Tickets für {order.event?.eventName}:
      </Title>

      <br />

      { renderTickets() }

    </PageSection>
  );
};

export { TicketPortal };
