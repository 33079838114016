import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorFactory from "@app/Page/Error";
import { Order, OrderApi } from "@src/api";
import {
  Alert,
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  TextContent, EmptyStateHeader, EmptyStateFooter, ClipboardCopy
} from "@patternfly/react-core";
import { CheckCircleIcon } from "@patternfly/react-icons";
import { useNavigate, useParams } from "react-router";
import { ContentLoading } from "@app/Page/ContentLoading";

export default class OrderSuccessPathFactory {
  public static generatePath (orderNumber : string) : string {
    return generatePath(`/order/success/${encodeURIComponent(orderNumber)}`)
  }
}

const OrderSuccess: React.FunctionComponent = () => {
  const { key } = useParams();
  const [order, setOrder] = useState<Order>()
  const [redirectTo, setRedirectTo] = useState({error: false, msg: ""});
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const keyParam = key || "undefined";

    (new OrderApi()).getOrder( { key : keyParam } )
      .then(res => {
        setLoading(false)
        setOrder(res)
      })
      .catch(() => {
        setRedirectTo({error: true, msg: "Fehlerhafter Orderkey oder Zugriff verweigert!"})
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirectTo.error) {
    navigate(ErrorFactory.generatePath(redirectTo.msg, "OrderSuccess"))
    return null
  }

  if(loading) {
    return <ContentLoading />
  }

  return (<>
    <EmptyState>
      <EmptyStateHeader titleText="Vielen Dank - Ihre Bestellung war erfolgreich!" icon={<EmptyStateIcon icon={CheckCircleIcon} color={"green"}/>} headingLevel="h4" />
      <EmptyStateBody>
        <TextContent>Um die Bestellung abzuschließen, überweisen Sie bitte den Gesamtbetrag auf das angeführte Bankkonto:</TextContent>

        <br />

        <Alert variant="info" title="Zahlungsinformationen" >
          <b>Überweisen an: </b> {order?.event?.operator?.operatorBankName}, <b> IBAN: </b>
          <ClipboardCopy isReadOnly hoverTip="Copy" clickTip="Copied" variant="inline-compact">
            {order?.event?.operator?.operatorBankIban}
          </ClipboardCopy>
          <br/>

          <b>Verwendungszweck: </b> TickR{ order?.orderNum?.toString().padStart(4, '0')} <br/>
          <b>Gesamtpreis: </b> &euro; {order?.totalPrice} <br/><br/>
          <b>Bei Onlinebanking unbedingt Verwendungszweck angeben!</b>
        </Alert>

        {/*<Alert variant="warning" title="Bei Onlinebanking unbedingt Verwendungszweck angeben!" />*/}

        <br />

        Beachten Sie, dass Tickets erst nach vollständigem Zahlungseingang ausgestellt und übermittelt werden.
        Zusätzlich kann es bis Zustellung der Tickets aufgrund der Überweisungs- und Bearbeitungszeit bis zu 1 Woche dauern. Wir bitten um Verständnis!
        Können wir binnen 14 Tagen oder bis zu 24 Stunden vor Beginn der Veranstaltung keinen Zahlungseingang feststellen, wird die Bestellung automatisch storniert.
        Es besteht kein Leistungsanspruch ohne vollständige Zahlung.
        Alle gültigen / nicht verwendeten Tickets können 14 Tage nach Bestellung, spätestens bis 24 Stunden vor Veranstaltungsbeginn, retourniert werden.
        Die Rückgabefrist erlischt automatisch 24 Stunden vor Veranstaltungsbeginn, unabhängig zu welchem Zeitpunkt die Tickets gekauft wurden.
        Eine spätere Retournierung ist nicht möglich.
      </EmptyStateBody>

      <EmptyStateFooter>
        <Link to={"/"}><Button variant="primary" src={"/"}>Zur Startseite</Button></Link>
      </EmptyStateFooter>
    </EmptyState>
  </>)

}

export { OrderSuccess }
