import React, { useContext } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import {
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateIcon,
  FormGroup,
  Hint,
  HintBody,
  InputGroup,
  InputGroupItem,
  InputGroupText,
  Label,
  Modal,
  TextInput,
  ValidatedOptions
} from "@patternfly/react-core";
import { EyeIcon } from "@patternfly/react-icons";
import { Status, TicketApi, TicketCheckResponse } from "@src/api";
import { SystemContext } from "@app/AppContext/SystemContext";
import { numberValidation, textValidation } from "@app/utils";

export const Scanner: React.FunctionComponent = () => {

  const systemContext = useContext(SystemContext);

  const [processing, setProcessing] = React.useState(false)
  const [lastResponse, setLastResponse] = React.useState<TicketCheckResponse>({})
  const [isResponseModalOpen, setResponseModalOpen] = React.useState(false);
  const [isManualCheckModalOpen, setManualCheckModalOpen] = React.useState(false);

  const [manualKey, setManualKey] = React.useState("")
  const [manualOrderNum, setManualOrderNum] = React.useState(-1)
  const [manualOrderRank, setManualOrderRank] = React.useState(-1)

  const checkTicket = (code) =>  {
    setProcessing(true);

    (new TicketApi(systemContext.apiConfig)).redeemTicket({ body : code })
      .then(res => {
        setLastResponse(res)
        setResponseModalOpen(true)
      })
      .catch(err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
  }

  const checkTicketManual = (orderNum, rank, beginningKeyChars) =>  {
    setProcessing(true);

    (new TicketApi(systemContext.apiConfig))
      .redeemTicketManual({ ticketRedeemRequest : { orderNum:orderNum, rank:rank, beginningKeyChars:beginningKeyChars }})
      .then(res => {
        setLastResponse(res)
        setResponseModalOpen(true)
        setManualOrderNum(-1)
        setManualOrderRank(-1)
        setManualKey("")
      })
      .catch(err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
  }

  const renderModalTitle = () => {
    if(lastResponse.status == Status.ValidAndSuccessful) {
      return "Ticket GÜLTIG";
    }

    if(lastResponse.status == Status.EventNotEnabledForScan) {
      return "Überprüfung nicht möglich";
    }

    return "Ticket UNGÜLTIG";
  }

  const renderModalIcon = () => {
    if(lastResponse.status == Status.ValidAndSuccessful) {
      return "success";
    }

    if(lastResponse.status == Status.AlreadyUsed) {
      return "warning";
    }

    return "danger";
  }

  const renderModalStatusLabel = () => {
    switch (lastResponse.status) {
      case "VALID_AND_SUCCESSFUL":
        return <Label color="green">Gültig</Label>

      case "ALREADY_USED":
        return <Label color="red">Bereits eingelöst</Label>

      case "EVENT_NOT_ENABLED_FOR_SCAN":
        return <Label color="gold">Veranstaltung nicht freigegeben - Admin kontaktieren</Label>

      case "INVALID_CODE":
        return <Label color="red">Code ungültig</Label>
    }

    return <Label color="red">Unbekannter Fehler</Label>
  }

  const qrContainerStyle = {
    display: 'flex',
    justifyContent: 'center'
  }

  return (<>
     <EmptyState key="qrMainContainer">
      <EmptyStateHeader titleText="Ticketüberprüfung" headingLevel="h1" icon={<EmptyStateIcon icon={EyeIcon} />} />
      <EmptyStateBody>
        <div key="infoText">
          Halten Sie den QR-Code auf dem Ticket in die Kamera um das Ticket zu lösen...
        </div>

        <div key="qrContainer" style={qrContainerStyle} >
          <QrScanner
            scanDelay={1000}
            tracker={true}
            hideCount={false}
            onDecode={(result) => {
              if(!processing && result != null) {
                checkTicket(result)
              }
            }}
            onError={(error) => console.log(error)}
          />
        </div>
        <br  />
        <div key="manualCheckContainer">
          <Button variant="secondary" onClick={() => setManualCheckModalOpen(true)}>Manuelle Überprüfung</Button>
        </div>

      </EmptyStateBody>
    </EmptyState>

    <Hint key="qrResultBox">
      <HintBody>
        <b><u>Infos zum letzten Ticket: </u></b><br />
        <b>Veranstaltung</b> { lastResponse.eventName || "unbekannt" },<br />
        <b>Name</b> { lastResponse.ownerName || "unbekannt"} <br />
        <b>Status</b> { lastResponse.status || "unbekannt" }: { lastResponse.errorMessage }
      </HintBody>
    </Hint>

    <div key="qrResultModalContainer">
      <Modal
        key="qrResultModal"
        title={renderModalTitle()}
        titleIconVariant={renderModalIcon()}
        isOpen={isResponseModalOpen}
        aria-describedby="ticket-server-response"
        onClose={ () => {
          setProcessing(false)
          setResponseModalOpen(false)
        }}
        onEscapePress={ () => {
          setProcessing(false)
          setResponseModalOpen(false)
        }}
        actions={[
          <Button key="ok" variant="primary" onClick={() => {
            setProcessing(false)
            setResponseModalOpen(false)
          }}>OK</Button>,
        ]}
      >
        <b>Status:</b> { renderModalStatusLabel() } <br/>
        <b>Name:</b> {lastResponse.ownerName} <br/>
        <b>Veranstaltung:</b> {lastResponse.eventName} <br/>

        <b>Ticket zuletzt bearbeitet </b> am {lastResponse.modificationDate?.toLocaleString()} von {lastResponse.modificationUser}. <br/>
        <b>(Fehler)Meldung:</b> {lastResponse.errorMessage || "alles in Ordnung!"}
      </Modal>
    </div>

    <div key="manualCheckModalContainer">
      <Modal
        key="manualCheck"
        title="Manuelle Überprüfung"
        isOpen={isManualCheckModalOpen}
        aria-describedby="check-ticket-manually"
        onClose={ () => {
          setManualCheckModalOpen(false)
        }}
        onEscapePress={ () => {
          setManualCheckModalOpen(false)
        }}
        actions={[
          <Button key="ok"
                  variant="primary"
                  onClick={() => {
                    setManualCheckModalOpen(false)
                    checkTicketManual(manualOrderNum, manualOrderRank, manualKey)
                  }}
                  isDisabled={
                    textValidation(manualKey, 4) == ValidatedOptions.error
                    || numberValidation(manualOrderNum, 0, Number.MAX_SAFE_INTEGER) == ValidatedOptions.error
                    || numberValidation(manualOrderRank, 0, Number.MAX_SAFE_INTEGER) == ValidatedOptions.error
                  }
          >
            Überprüfen
          </Button>,
        ]}
      >
        <FormGroup label="Ersten 4 Zeichen vom Ticket-Code" isRequired fieldId="beginningKeyChars">
          <TextInput
            isRequired
            type="text"
            id="beginningKeyChars"
            name="beginningKeyChars"
            placeholder="0000xxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
            onChange={(e, val) => setManualKey(val)}
            validated={textValidation(manualKey, 4)}
          />
        </FormGroup>
        <br />
        <FormGroup label="Bestellnummer und Ticketnummer" isRequired fieldId="orderNum">
          <InputGroup>
            <InputGroupItem isFill>
              <TextInput
                id="orderNum"
                type="number"
                aria-label="orderNumber"
                onChange={(e, val) => setManualOrderNum(parseInt(val))}
                validated={numberValidation(manualOrderNum, 0, Number.MAX_SAFE_INTEGER)}
              />
            </InputGroupItem>
            <InputGroupText id="email-example">#</InputGroupText>
            <InputGroupItem isFill>
              <TextInput
                id="orderRank"
                type="number"
                aria-label="orderRank"
                onChange={(e, val) => setManualOrderRank(parseInt(val))}
                validated={numberValidation(manualOrderRank, 0, Number.MAX_SAFE_INTEGER)}
              />
            </InputGroupItem>
          </InputGroup>
        </FormGroup>

      </Modal>
    </div>
  </>);
}