import {About} from "@app/Webshop/FrontUI/About/About";
import {Events} from "@app/Webshop/Events/Events";
import {Home} from "@app/Webshop/FrontUI/Home/Home";
import {TicketPortal} from "@app/Webshop/TicketPortal/TicketPortal";
import {Error} from "@app/Page/Error";
import { OrderSuccess } from "@app/Webshop/OrderWizard/OrderSuccess";
import { Datenschutz } from "@app/Webshop/FrontUI/Home/Datenschutz";
import { Impressum } from "@app/Webshop/FrontUI/Home/Impressum";
import { AppRouteConfig } from "@app/Routing/AppRouteConfig";

const titlePreFix = "Tick@R | "

const routes: AppRouteConfig[] = [
  {
    id: 1,
    component: Home,
    label: 'Home',
    path: '/',
    exact : true,
    title: titlePreFix + 'Home',
  },
  {
    id: 2,
    component: Datenschutz,
    path: '/datenschutz',
    exact : true,
    title: titlePreFix + 'Datenschutz',
  },
  {
    id: 3,
    component: Impressum,
    path: '/impressum',
    exact : true,
    title: titlePreFix + 'Impressum',
  },
  {
    id: 4,
    component: Events,
    label: 'Events',
    path: '/events',
    exact : true,
    title: titlePreFix + 'Events',
  },
  {
    id: 5,
    component: OrderSuccess,
    path: '/order/success/:key',
    exact : false,
    title: titlePreFix + 'Successful',
  },
  {
    id: 6,
    component: About,
    label: 'Über Tick@R',
    path: '/about',
    exact : true,
    title: titlePreFix + 'About',
  },
  {
    id: 7,
    component: TicketPortal,
    path: '/portal/show/:key',
    exact : false,
    title: titlePreFix + 'Ticketportal',
  },
  {
    id: 999,
    component: Error,
    path: '/error',
    exact : false,
    title: titlePreFix + 'Error',
  },
];

export { routes };
