import {About} from "@app/Webshop/FrontUI/About/About";
import {Error} from "@app/Page/Error";
import { AppRouteConfig } from "@app/Routing/AppRouteConfig";
import { Dashboard } from "@app/Admin/Dashboard/Dashboard";
import { OrderOverview } from "@app/Admin/Orders/OrderOverview";
import { OrderDetail } from "@app/Admin/Orders/OrderDetail";
import { Scanner } from "@app/Admin/Scanner/Scanner";
import { UserRole } from "@src/api";
import { EventOverview } from "@app/Admin/Events/EventOverview";
import { EventDetail } from "@app/Admin/Events/EventDetail";
import { EventReportRenderer } from "@app/Admin/Events/EventReportRenderer";

const titlePreFix = "Tick@R Admin | "

const routes: AppRouteConfig[] = [
  {
    id : 0,
    label : "Home",
    routes : [
      {
        component: Dashboard,
        label: 'Dashboard',
        path: '/',
        exact : false,
        title: titlePreFix + 'Dashboard',
      }
    ]
  },
  {
    id : 10,
    label : "Veranstaltungsmanagement",
    routes : [

      {
        component: OrderOverview,
        label: 'Bestellungen',
        path: '/manage/orders',
        exact : true,
        title: titlePreFix + 'Bestellungen',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin, UserRole.OperatorUser ]
      },
      {
        component: OrderDetail,
        path: '/manage/orders/:key',
        exact : false,
        title: titlePreFix + 'Edit',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin, UserRole.OperatorUser ]
      },

      {
        component: EventOverview,
        label: 'Veranstaltungen',
        path: '/manage/events',
        exact : true,
        title: titlePreFix + 'Veranstaltungen',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin ]
      },
      {
        component: EventDetail,
        path: '/manage/events/:key',
        exact : false,
        title: titlePreFix + 'Edit',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin ]
      },

      {
        component: EventReportRenderer,
        path: '/manage/events/:id/report',
        exact : false,
        title: titlePreFix + 'Eventbericht',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin ]
      }
    ]
  },
  {
    id : 20,
    label : "(Benutzer) Verwaltung",
    routes : [
      {
        component: About,
        label: 'Mein Benutzer',
        path: '/settings/my-user',
        exact : false,
        title: titlePreFix + 'Mein Benutzer',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin, UserRole.OperatorUser ]
      },
      {
        component: About,
        label: 'Benutzer verwalten',
        path: '/settings/all-users',
        exact : false,
        title: titlePreFix + 'Benutzer',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin ]
      },
      {
        component: About,
        label: 'Veranstalter verwalten',
        path: '/settings/operators/',
        exact : false,
        title: titlePreFix + 'Veranstalter',
        visibleFor: [ UserRole.SystemAdmin, UserRole.OperatorAdmin ]
      }
    ]
  },
  {
    id : 30,
    label : "Überprüfung",
    routes : [
      {
        component: Scanner,
        label: 'QR Scanner',
        path: '/scan/qr',
        exact : false,
        title: titlePreFix + 'Scanner',
      }
    ]
  },
  {
    id: 999,
    component: Error,
    path: '/error',
    exact : false,
    title: titlePreFix + 'Error',
  },
];

export { routes };
