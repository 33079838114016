import React, { useContext, useEffect } from "react";
import { OrderTable } from "@app/Admin/Orders/OrderTable";
import { Tabs, Tab, TabTitleText, TabTitleIcon, TabContent } from "@patternfly/react-core";
import { AddCircleOIcon, CheckCircleIcon, EyeIcon, SearchIcon, TimesCircleIcon } from "@patternfly/react-icons";
import { Order, OrderApi } from "@src/api";
import { OrderStatus } from "@src/api/models/OrderStatus";
import { SystemContext } from "@app/AppContext/SystemContext";
import { ContentLoading } from "@app/Page/ContentLoading";
import { useLocation } from "react-router-dom";

export const OrderOverview : React.FunctionComponent = () => {
  const location = useLocation();

  const [orders, setOrders] = React.useState<Order[]>([]);
  const [isLoading, setLoading] = React.useState(true);
  const [activeTabIndex, setActiveTabIndex] = React.useState<string | number>(location.state?.tabIndex || 0);

  const systemContext = useContext(SystemContext)

  const fetchOrders = (queryStats : OrderStatus[]) => {
    (new OrderApi(systemContext.apiConfig)).getOrders({ status : queryStats })
      .then(res => {
        setLoading(false)
        setOrders(res)
      })
      .catch(err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
  }

  const fetchOrdersBy = (tabIndex : number | string) => {
    switch (tabIndex) {
      case 0: // Neue Bestellungen
        fetchOrders([ OrderStatus.New ])
        break
      case 1: // Bezahlte Bestellungen
        fetchOrders([ OrderStatus.Payed ])
        break
      case 2: // Abgeschlossene Bestellungen
        fetchOrders([ OrderStatus.Done ])
        break
      case 3: // Stornierte Bestellungen
        fetchOrders([ OrderStatus.Storno ])
        break
      case 4: // Alle Bestellungen
        fetchOrders([ OrderStatus.New, OrderStatus.Payed, OrderStatus.Done, OrderStatus.Storno ])
        break
    }
  }

  useEffect(() => {
    fetchOrdersBy(activeTabIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  const handleTabClick = (event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent, tabIndex: string | number) => {
    setActiveTabIndex(tabIndex);
    setOrders([])
    fetchOrdersBy(tabIndex)
  };

  const updateStatus = (orderKey : string | undefined, toStatus : OrderStatus) => {
    setOrders([...orders].map(obj => {
      if(obj.orderKey == orderKey) { return {...obj, orderStatus : toStatus }}
      else { return obj }
    }))
  }

  return (<>
    <div>
      <Tabs
        activeKey={activeTabIndex}
        onSelect={handleTabClick}
        aria-label="Orders"
      >
        <Tab eventKey={0} title={<><TabTitleIcon><AddCircleOIcon /></TabTitleIcon> <TabTitleText>Neu</TabTitleText></>} aria-label="neue Bestellungen">
          <TabContent id={"TCNew"}>
            {isLoading && <ContentLoading /> }
            {!isLoading && <OrderTable title={"Neue Bestellungen"} orders={orders}  activeTabIndex={activeTabIndex}  setStatus={updateStatus}/> }
          </TabContent>
        </Tab>
        <Tab eventKey={1} title={<><TabTitleIcon><EyeIcon /></TabTitleIcon><TabTitleText>Bezahlt</TabTitleText></>}>
          {isLoading && <ContentLoading /> }
          {!isLoading && <OrderTable title={"Bezahlte Bestellungen"} orders={orders} activeTabIndex={activeTabIndex} setStatus={updateStatus} /> }
        </Tab>
        <Tab eventKey={2} title={<><TabTitleIcon><CheckCircleIcon /></TabTitleIcon><TabTitleText>Abgeschlossen</TabTitleText></>}>
          {isLoading && <ContentLoading /> }
          {!isLoading && <OrderTable title={"Abgeschlossene Bestellungen"} orders={orders} activeTabIndex={activeTabIndex} setStatus={updateStatus} /> }
        </Tab>
        <Tab eventKey={3} title={<><TabTitleIcon><TimesCircleIcon /></TabTitleIcon> <TabTitleText>Storniert</TabTitleText></>}>
          {isLoading && <ContentLoading /> }
          {!isLoading && <OrderTable title={"Stornierte Bestellungen"} orders={orders} activeTabIndex={activeTabIndex} setStatus={updateStatus} /> }
        </Tab>
        <Tab eventKey={4} title={<><TabTitleIcon><SearchIcon /></TabTitleIcon> <TabTitleText>Suchen</TabTitleText></>}>
          <TabContent id={"TCSearch"}>
            {isLoading && <ContentLoading /> }
            {!isLoading && <OrderTable title={"Alle Bestellungen (durchsuchen)"} orders={orders} activeTabIndex={activeTabIndex} setStatus={updateStatus} /> }
          </TabContent>
        </Tab>
      </Tabs>
    </div>
  </>);
}