import React, { CSSProperties, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { SystemContext } from "@app/AppContext/SystemContext";
import { ContentLoading } from "@app/Page/ContentLoading";
import { Order, OrderApi, OrderStatus, ShippingType, Ticket, TicketApi } from "@src/api";
import {
  ActionGroup,
  Alert,
  Button,
  Card,
  CardBody,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Dropdown,
  DropdownItem,
  DropdownList,
  Form,
  FormGroup,
  FormHelperText,
  FormSelect,
  FormSelectOption,
  HelperText,
  HelperTextItem,
  Label,
  MenuToggle,
  MenuToggleElement,
  Modal,
  ModalVariant,
  PageSection,
  TextInput,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  ValidatedOptions
} from "@patternfly/react-core";

import * as Util from "@app/utils/";
import { ActionsColumn, IAction, Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { CheckCircleIcon, TimesCircleIcon } from "@patternfly/react-icons";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFTicket } from "@app/Webshop/TicketPortal/PDFTicket";


export const OrderDetail: React.FunctionComponent = () => {
  const { key } = useParams();
  const systemContext = useContext(SystemContext)
  const [isLoading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState<Order>({} as Order);
  const [tickets, setTickets] = React.useState<Ticket[]>([]);

  const [isStatusChangeModalOpen, setStatusChangeModalOpen] = React.useState(false);
  const [isStatusChangeDropdownOpen, setStatusChangeDropDownOpen] = React.useState(false);
  const [statusChangeDropdownValue, setStatusChangeDropDownValue] = React.useState<OrderStatus>();
  const [stornoReason, setStornoReason] = React.useState("");
  const [stornoPerson, setStornoPerson] = React.useState("");

  const navigate = useNavigate()
  const location = useLocation()

  const isShipping = () => {
    return order.shippingType == ShippingType.Post
  }

  const addressValidation = () => {
    const valueValidation = Util.textWithSpaceValidation(order?.customerAddress)
    return isShipping() ? valueValidation : ValidatedOptions.default
  }

  const postCodeValidation = () => {
    const valueValidation = Util.numberValidation(parseInt(order?.customerPostcode || "-1"), 0, 99999)
    return isShipping() ? valueValidation : ValidatedOptions.default
  }

  const cityValidation = () => {
    const valueValidation = Util.textValidation(order?.customerCity, 2)
    return isShipping() ? valueValidation : ValidatedOptions.default
  }

  useEffect(() => {
    setLoading(true)
    const param = key || "";

    (new OrderApi(systemContext.apiConfig)).getOrder( { key : param })
      .then(res => {
        setOrder(res)
        setLoading(false)
        loadTickets(res.orderKey || "-1")
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  const loadTickets = (key : string) => {
    (new TicketApi(systemContext.apiConfig)).getTickets( { key : key })
      .then(res => {
        setTickets(res)
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  if(isLoading) {
    return <ContentLoading />
  }

  const tableHeaders = ["Rank", "Name", "Status", "Ticket Code", ""];

  const renderTicketAction = (currentRow : Order): IAction[] => [
    { title: 'bearbeiten', onClick: () => console.log(`TODO: Bearbeiten`)},
    { isSeparator: true },
    { title: 'stornieren', onClick: () => console.log(`TODO: Stornieren`)}
  ];

  const renderTicketUsed = (used : boolean) => {
    if(used) {
      return <Label color={"red"} icon={<TimesCircleIcon />}>verbraucht</Label>
    } else {
      return <Label color={"green"} icon={<CheckCircleIcon />}>gültig</Label>
    }
  }

  const renderStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.New:
        return <Label color="blue">Neu</Label>;
      case OrderStatus.Payed:
        return <Label color="orange">Bezahlt</Label>;
      case OrderStatus.Done:
        return <Label color="green">Abgeschlossen</Label>;
      case OrderStatus.Storno:
        return <Label color="red">Storniert</Label>;
    }
    return "";
  };

  const onStatusChangeModalClose = () => {
    setStatusChangeDropDownValue(undefined)
    setStatusChangeModalOpen(false)
  }

  const renderStatusChangeAlertText = () =>  {
    if(order?.orderStatus == OrderStatus.Payed && statusChangeDropdownValue == OrderStatus.New) {
      return <Alert variant="warning" ouiaId="WarningAlert"
                    title="ACHTUNG: Wird der Status zurückgesetzt, werden alle ausgestellten Tickets unwiderruflich gelöscht! Sind sie sicher?"  />
    }

    if(order?.orderStatus == OrderStatus.Done && statusChangeDropdownValue == OrderStatus.Payed) {
      return <Alert variant="warning" ouiaId="WarningAlert"
                    title="ACHTUNG: Benachrichtigung wurde bereits an den Kunden gesendet (dies kann nicht rückgängig gemacht werden)."  />
    }

    if((order?.orderStatus == OrderStatus.Payed || order?.orderStatus == OrderStatus.Done) && statusChangeDropdownValue == OrderStatus.Storno) {
      return <Alert variant="warning" ouiaId="WarningAlert"
                    title="Sind sie sicher, dass die gesamte Bestellung storniert werden soll? Diese Aktion kann nicht rückgängig gemacht werden! Wenn JA, Tickets wurden bereits bezahlt. Leiten Sie eine zügige Rückzahlung ein!"  />
    }

    if(statusChangeDropdownValue == OrderStatus.Storno) {
      return <Alert variant="warning" ouiaId="WarningAlert"
                    title="Sind sie sicher, dass die gesamte Bestellung storniert werden soll? Diese Aktion kann nicht rückgängig gemacht werden!"  />

    }

    return <></>
  }

  const submitStatusChange = () => {
    if(order?.orderKey === undefined || statusChangeDropdownValue === undefined) return;

    (new OrderApi(systemContext.apiConfig)).changeStatus( {changeStatusRequest : {
        orderKey : order?.orderKey,
        toStatus : statusChangeDropdownValue,
        stornoReason : stornoReason,
        stornoPerson : stornoPerson
    }})
      .then(() => {
        order.orderStatus = statusChangeDropdownValue;
        order.stornoPerson = stornoPerson;
        order.stornoReason = stornoReason;
        onStatusChangeModalClose();
        loadTickets(order.orderKey || "-1")
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  const renderStornoFormVisibility = () : CSSProperties => {
    if(statusChangeDropdownValue != OrderStatus.Storno) {
      return { display : "none" }
    }

    return {}
  }

  const submitUpdateOrder = () => {
    (new OrderApi(systemContext.apiConfig)).updateOrder( { order : order } )
      .then(() => {
        systemContext.addNotification("erfolgreich gespeichert!", "success", new Date().getTime())
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  return (<>
    <PageSection isFilled>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <Title headingLevel="h1">Bestelldetails bearbeiten</Title>
          </ToolbarItem>

          <ToolbarItem>
            {renderStatus(order?.orderStatus || OrderStatus.New) }
          </ToolbarItem>

          <ToolbarItem align={{default : "alignRight"}}>
            <Button variant="secondary" onClick={() => setStatusChangeModalOpen(true)}>Status ändern</Button>
          </ToolbarItem>

          <ToolbarItem variant="separator"></ToolbarItem>

          <ToolbarItem>
            <Button variant="link" onClick={() => navigate("/eventadmin/manage/orders", {state : {tabIndex : location.state?.tabIndex}})} >Abbrechen</Button>
          </ToolbarItem>
        </ToolbarContent>
      </Toolbar>

      {
        order?.orderStatus == OrderStatus.Storno && <>
          <br />
          <Alert variant="info" title={`Storniert von ${order?.stornoPerson}. Grund: ${order?.stornoReason}`}  ouiaId="StornoInfo" />
        </>
      }

      <br />

      <DescriptionList columnModifier={{ lg: '3Col' }}>
        <Card component="div">
          <DescriptionListTerm>Bestellnummer</DescriptionListTerm>
          <DescriptionListDescription> {order?.orderNum} / {order?.orderKey} </DescriptionListDescription>
        </Card>

        <Card component="div">
          <DescriptionListTerm>Veranstalter & Veranstaltung</DescriptionListTerm>
          <DescriptionListDescription> {order?.event?.operator?.operatorName} / {order?.event?.eventName} </DescriptionListDescription>
        </Card>

        <Card component="div">
          <DescriptionListTerm>Bearbeitungsverlauf:</DescriptionListTerm>
          <DescriptionListDescription>
            <b>erstellt:</b> {order?.creationDate?.toLocaleString()} (Benutzer: {order?.creationUser})
            {
              order?.modificationDate &&
              <><br/><b>letzte Änderung:</b> {order?.modificationDate?.toLocaleString()} (Benutzer: {order?.modificationUser})</>
            }
          </DescriptionListDescription>
        </Card>
      </DescriptionList>

      <br />

      <Card isCompact isRounded>
        <CardBody>
          <Form>
            {/* Name */}
            <DescriptionList columnModifier={{ lg: '2Col' }}>
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <FormGroup label="Vor- und Nachname" isRequired fieldId="name">
                    <TextInput
                      isRequired
                      type="text"
                      id="name"
                      name="name"
                      aria-describedby="simple-form-name-01-helper"
                      value={order?.customerName}
                      onChange={(e, val) => setOrder(old => ( {...old, customerName : val} )) }
                      validated={Util.textWithSpaceValidation(order?.customerName)}
                    />
                    <FormHelperText>
                      <HelperText>
                        <HelperTextItem>Für gültige Tickets ist der vollständige Name (Vor- und Nachname) notwendig.</HelperTextItem>
                      </HelperText>
                    </FormHelperText>
                  </FormGroup>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* E-Mail */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <FormGroup label="Email" isRequired fieldId="mail">
                    <TextInput
                      isRequired
                      type="email"
                      id="mail"
                      name="mail"
                      value={order?.customerMail}
                      onChange={(e, val) => setOrder(old => ( {...old, customerMail : val} ))}
                      validated={Util.mailValidation(order?.customerMail)}
                    />
                  </FormGroup>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* Adresse und PLZ*/}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Adresse" isRequired={isShipping()} fieldId="address">
                          <TextInput
                            isRequired={isShipping()}
                            type="text"
                            id="address"
                            name="address"
                            value={order?.customerAddress}
                            onChange={(e, val) => setOrder(old => ( {...old, customerAddress : val} ))}
                            validated={addressValidation()}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="PLZ" isRequired={isShipping()} fieldId="postcode">
                          <TextInput
                            isRequired={isShipping()}
                            type="number"
                            min={0}
                            max={99999}
                            id="postcode"
                            name="postcode"
                            value={order?.customerPostcode}
                            onChange={(e, val) => setOrder(old => ( {...old, customerPostcode : val} ))}
                            validated={postCodeValidation()}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* Ort und Land */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Ort" isRequired={isShipping()} fieldId="city">
                          <TextInput
                            isRequired={isShipping()}
                            type="text"
                            id="city"
                            name="city"
                            value={order?.customerCity}
                            onChange={(e, val) => setOrder(old => ( {...old, customerCity : val} ))}
                            validated={cityValidation()}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Land" isRequired fieldId="country">
                          <TextInput
                            isRequired
                            type="text"
                            id="country"
                            name="country"
                            value={order?.customerCountry}
                            onChange={(e, val) => setOrder(old => ( {...old, customerCountry : val} ))}
                            validated={Util.textValidation(order?.customerCountry, 2)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* Shipping Type & Payment Type */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Versandart" isRequired fieldId="shippingType">
                          <FormSelect
                            aria-label="shippingType"
                            value={order?.shippingType}
                            onChange={(e, val) => setOrder(old => ( {...old, shippingType : val as ShippingType}))}
                          >
                            <FormSelectOption key={0} value={ShippingType.PrintAtHome} label={"Print@Home"} />
                            <FormSelectOption key={1} value={ShippingType.Post} label={"Post"} />
                          </FormSelect>

                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Zahlungsart" isRequired fieldId="paymentType">
                          <TextInput
                            isRequired
                            type="text"
                            id="paymentType"
                            name="paymentType"
                            value={order?.paymentType}
                            onChange={(e, val) => setOrder(old => ( {...old, paymentType : val} ))}
                            validated={Util.textValidation(order?.paymentType, 2)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* number tickets and total price */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Anzahl Tickets" isRequired fieldId="numberTickets">
                          <TextInput
                            isRequired
                            type="number"
                            id="numberTickets"
                            name="numberTickets"
                            value={order?.numberOfTickets}
                            isDisabled={order.orderStatus != OrderStatus.New}
                            onChange={(e, val) => {
                              setOrder(old => ( {...old, numberOfTickets : parseInt(val)}))
                              // update price if number of tickets is changed
                              const newPrice = parseInt(val) * (order?.event?.ticketPrice || 1)
                              setOrder(old => ( {...old, totalPrice : newPrice }))
                            }}
                            validated={Util.numberValidation(order?.numberOfTickets, 0, Number.MAX_SAFE_INTEGER)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Gesamtpreis" isRequired fieldId="totalPrice">
                          <TextInput
                            isRequired
                            type="number"
                            id="totalPrice"
                            name="totalPrice"
                            value={order?.totalPrice}
                            onChange={(e, val) => setOrder(old => ( {...old, totalPrice : parseFloat(val)} ))}
                            validated={Util.numberValidation(order?.totalPrice, 0, Number.MAX_SAFE_INTEGER)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>
            </DescriptionList>

            <ActionGroup>
              <Button variant="primary" onClick={() => submitUpdateOrder()}>Speichern</Button>
            </ActionGroup>
          </Form>
        </CardBody>
      </Card>
    </PageSection>

    <PageSection isFilled hasShadowTop={true}>
      <Title headingLevel="h3">Tickets</Title>
      <Table variant="compact" aria-label="Compact Table">
        <Thead>
          <Tr>
            <Th key={0}>{tableHeaders[0]}</Th>
            <Th key={1}>{tableHeaders[1]}</Th>
            <Th key={2}>{tableHeaders[2]}</Th>
            <Th key={3}>{tableHeaders[3]}</Th>
            <Th key={4}>{tableHeaders[4]}</Th>
            <Th key={5}>{tableHeaders[4]}</Th>
          </Tr>
        </Thead>
        <Tbody>
          { tickets.length > 0 && tickets.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              <>
                <Td dataLabel={tableHeaders[0]}>{row.orderRank}</Td>
                <Td dataLabel={tableHeaders[1]}>{row.ownerName}</Td>
                <Td dataLabel={tableHeaders[2]}>{renderTicketUsed(row.used || false)}</Td>
                <Td dataLabel={tableHeaders[3]}>{row.ticketCode}</Td>
                <Td dataLabel={tableHeaders[4]} isActionCell>
                  <PDFDownloadLink document={<PDFTicket ticket={row} order={order} />} fileName={"copy_of_ticket"}>
                    {({ blob, url, loading, error }) => {
                      return loading ? 'Ticket wird geladen...' : 'Ticket'
                    }}
                  </PDFDownloadLink>
                </Td>
                <Td dataLabel={tableHeaders[4]} isActionCell>
                  <ActionsColumn items={renderTicketAction(row)} />
                </Td>
              </>
            </Tr>
          ))}

          {tickets.length <= 0 &&
            <Tr key={"no_data"}>
              <>
                <Td span={5}>Keine Tickets </Td>
              </>
            </Tr>
          }

        </Tbody>
      </Table>

    </PageSection>

    {/* Modal / PopUps */}
    <Modal
      title="Status ändern"
      titleIconVariant="info"
      variant={ModalVariant.small}
      isOpen={isStatusChangeModalOpen}
      onClose={() => onStatusChangeModalClose()}
      onEscapePress={() => onStatusChangeModalClose()}
      actions={[
        <Button key="confirm" variant="primary" onClick={() => submitStatusChange()}
                isDisabled={!statusChangeDropdownValue || (statusChangeDropdownValue == OrderStatus.Storno && (stornoPerson == "" || stornoReason == ""))}>
          Speichern
        </Button>,
        <Button key="cancel" variant="link" onClick={() => onStatusChangeModalClose()}>
          Abbrechen
        </Button>
      ]}
    >
      <div>
        { renderStatusChangeAlertText() }
      </div>
      <br />
      <div>
        <Form id="modal-with-form-form">
          <FormGroup label="Status auswählen" isRequired fieldId="asd">
            <Dropdown
              isOpen={isStatusChangeDropdownOpen}
              onSelect={(e, val) => {
                setStatusChangeDropDownValue(val as OrderStatus)
                setStatusChangeDropDownOpen(!isStatusChangeDropdownOpen)
              }}
              selected={statusChangeDropdownValue}
              onOpenChange={(isOpen: boolean) => setStatusChangeDropDownOpen(isOpen)}
              toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                <MenuToggle ref={toggleRef} isExpanded={isStatusChangeDropdownOpen} isFullWidth
                            onClick={() => setStatusChangeDropDownOpen(!isStatusChangeDropdownOpen)}>
                  { statusChangeDropdownValue || "Status auswählen" }
                </MenuToggle>
              )}
            >
              <DropdownList>
                <DropdownItem value={OrderStatus.New} description={"Neue Bestellung aber nicht bezahlt"} key="NEW"
                              isDisabled={order?.orderStatus == OrderStatus.New || order?.orderStatus == OrderStatus.Done}>
                  Neu
                </DropdownItem>
                <DropdownItem value={OrderStatus.Payed} description={"Bestellung bezahlt aber noch nicht versendet"} key="PAYED"
                              isDisabled={order?.orderStatus == OrderStatus.Payed || order?.orderStatus == OrderStatus.Storno}>
                  Bezahlt
                </DropdownItem>
                <DropdownItem value={OrderStatus.Done} description={"Bestellung abgeschlossen & versendet"} key="DONE"
                              isDisabled={order?.orderStatus == OrderStatus.New || order?.orderStatus == OrderStatus.Done || order?.orderStatus == OrderStatus.Storno}>
                  Abgeschlossen
                </DropdownItem>
                <DropdownItem value={OrderStatus.Storno} description={"Bestellung & zugehörige Tickets storniert"} key="STORNO"
                              isDisabled={order?.orderStatus == OrderStatus.Storno}>
                  Storniert
                </DropdownItem>
              </DropdownList>
            </Dropdown>
          </FormGroup>
          <FormGroup label="Storno im Auftrag von" isRequired fieldId="storno-person" style={renderStornoFormVisibility()}>
            <TextInput
              isRequired
              type="text"
              id="storno-person"
              name="storno-person"
              value={stornoPerson}
              onChange={(e, val) => setStornoPerson(val)}
            />
          </FormGroup>
          <FormGroup label="Storno-Grund" isRequired fieldId="storno-reason" style={renderStornoFormVisibility()}>
            <TextInput
              isRequired
              type="text"
              id="storno-reason"
              name="storno-reason"
              value={stornoReason}
              onChange={(e, val) => setStornoReason(val)}
            />
          </FormGroup>
        </Form>
      </div>
    </Modal>
  </>);
}