/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceResult
 */
export interface PriceResult {
    /**
     * 
     * @type {number}
     * @memberof PriceResult
     */
    shippingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceResult
     */
    ticketPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceResult
     */
    totalPrice?: number;
}

/**
 * Check if a given object implements the PriceResult interface.
 */
export function instanceOfPriceResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PriceResultFromJSON(json: any): PriceResult {
    return PriceResultFromJSONTyped(json, false);
}

export function PriceResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingPrice': !exists(json, 'shippingPrice') ? undefined : json['shippingPrice'],
        'ticketPrice': !exists(json, 'ticketPrice') ? undefined : json['ticketPrice'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
    };
}

export function PriceResultToJSON(value?: PriceResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shippingPrice': value.shippingPrice,
        'ticketPrice': value.ticketPrice,
        'totalPrice': value.totalPrice,
    };
}

