import * as React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { AlertProps } from "@patternfly/react-core";

import '@patternfly/react-core/dist/styles/base.css';
import '@app/app.css';
import { NotificationToastLayout } from "@app/AppLayout/NotificationToastLayout";
import { SystemContext } from "@app/AppContext/SystemContext";
import { Routes } from "react-router";
import { WebShopLayout } from "@app/Webshop/App/WebShopLayout";
import { AdminLayout } from "@app/Admin/App/AdminLayout";
import { RouteLoader } from "@app/Routing/RouteLoader";

import { routes as adminRoutes } from "@app/Admin/App/routes";
import { routes as webShopRoutes } from "@app/Webshop/App/routes";
import { Login } from "@app/Page/Login";
import { useState } from "react";
import { Configuration, Session } from "@src/api";
import { SessionHandler } from "@app/Admin/App/SessionHandler";
import { Logout } from "@app/Page/Logout";

const App: React.FunctionComponent = () => {

  const [toasts, setToasts] = useState<AlertProps[]>([]);
  const [session, setSession] = useState<Session>();

  return <>
    <SystemContext.Provider value={{
      notifications: toasts,
      session: session,
      apiConfig: new Configuration({
        accessToken : session?.authenticationToken || undefined,
        apiKey : "app@TICKATR_YGe5Z4LKiBx4N0Xv1rcNiKacrmyD11e0"
      }),

      setSession : (val) => setSession(val),
      addNotification : (title, variant, key) => { setToasts(prevAlerts => [...prevAlerts, {title, variant, key}]);},
    }}>

      <BrowserRouter>
        <Routes>
          {/* LOGIN */}
          <Route
            path={"/eventadmin/login/*"}
            caseSensitive={false}
            element={ <Login /> }
          />

          {/* LOGOUT */}
          <Route
            path={"/eventadmin/logout"}
            caseSensitive={false}
            element={  <Logout /> }
          />

          {/* ADMIN DASHBOARD */}
          <Route
            path={"/eventadmin/*"}
            caseSensitive={false}
            element={
            <>
              <AdminLayout>
                <SessionHandler />
                <RouteLoader routes={adminRoutes} isSecured={true} />
              </AdminLayout>
            </>
          }/>

          {/* WEBSHOP */}
          <Route
            path={"/*"}
            element={
              <>
                <WebShopLayout>
                  <RouteLoader routes={webShopRoutes} isSecured={false} />
                </WebShopLayout>
              </>
          }/>

        </Routes>
      </BrowserRouter>

      <NotificationToastLayout />
    </SystemContext.Provider>
  </>
}
export default App;
