
export function nvlN (val : number | undefined | null) : number {
  return (val != null && val != undefined ) ? val : -1
}

export function nvlB (val : boolean | undefined | null ) : boolean {
  return (val != null && val != undefined ) ? val : false
}

export function nvlS (val : string | undefined | null ) : string {
  return (val != null && val != undefined ) ? val : ""
}