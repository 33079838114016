import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  FormGroup,
  InputGroup,
  InputGroupItem,
  TextInput,
  ValidatedOptions
} from "@patternfly/react-core";
import { Link } from "react-router-dom";
import { ShippingType, WebShopOrder } from "@src/api";
import * as Util from "@app/utils/";

interface IWizardProps {
  setValidity : React.Dispatch<React.SetStateAction<boolean>>
  order : WebShopOrder
  setOrder : React.Dispatch<React.SetStateAction<WebShopOrder>>
}
const Step3Data : React.FunctionComponent<IWizardProps> = (props: IWizardProps) => {

  const [nameValidation, setNameValidation] = React.useState<ValidatedOptions>(ValidatedOptions.error);
  const [mailValidation, setMailValidation] = React.useState<ValidatedOptions>(ValidatedOptions.error);
  const [addressValidation, setAddressValidation] = React.useState<ValidatedOptions>(ValidatedOptions.error);
  const [cityValidation, setCityValidation] = React.useState<ValidatedOptions>(ValidatedOptions.error);
  const [postcodeValidation, setPostcodeValidation] = React.useState<ValidatedOptions>(ValidatedOptions.error);

  const isShipping = () => {
    return props.order.shippingType == ShippingType.Post
  }

  const checkAddressValidation = (value : string) => {
    const isValueValid = (new RegExp("^[\\w\\s-ßüÜöÖäÄ._]+[\\s][\\d\\w/-]+[\\w]*$").test(value))

    return isShipping()
      ? (isValueValid ? ValidatedOptions.success : ValidatedOptions.error)
      : ValidatedOptions.default
  }

  const checkPostcodeValidation = (value : string) => {
    const isValueValid = (new RegExp("^[\\d]{4,5}$").test(value))

    return isShipping()
      ? (isValueValid ? ValidatedOptions.success : ValidatedOptions.error)
      : ValidatedOptions.default
  }

  const checkCityValidation = (value : string) => {
    const valueValidation = Util.textValidation(value, 2)
    return isShipping() ? valueValidation : ValidatedOptions.default
  }

  const isValidationOK = (validation :  ValidatedOptions) => {
    return validation != ValidatedOptions.error
  }

  useEffect(() => {
    const nV = Util.textWithSpaceValidation(props.order.customerName)
    setNameValidation(nV)

    const mV = Util.mailValidation(props.order.customerMail)
    setMailValidation(mV)

    const aV = checkAddressValidation(Util.nvlS(props.order.customerAddress))
    setAddressValidation(aV)

    const pV = checkPostcodeValidation(Util.nvlS(props.order.customerPostcode))
    setPostcodeValidation(pV)

    const cV = checkCityValidation(Util.nvlS(props.order.customerCity))
    setCityValidation(cV)

    props.setValidity(
      isValidationOK(nV)
      && isValidationOK(mV)
      && isValidationOK(aV)
      && isValidationOK(pV)
      && isValidationOK(cV)
      && Util.nvlB(props.order.dsgvoChecked)
    )
  }, [props])


  return (<>
    <Form key={3}>
      <FormGroup isRequired label="Vor- und Nachname" fieldId="name" labelInfo="Für gültige Tickets ist der vollständige Name notwendig!">
        <TextInput isRequired validated={nameValidation}
                   type="text" id="name" name="name" aria-describedby="name-helper"
                   value={props.order.customerName} onChange={(_event, val) => props.setOrder(old => ({ ...old, customerName : val }))} />
      </FormGroup>

      <FormGroup label="Email" isRequired fieldId="mail">
        <TextInput isRequired validated={mailValidation}
                   type="email" id="mail" name="mail" aria-describedby="mail-helper"
                   value={props.order.customerMail} onChange={(_event, val) => props.setOrder(old => ({ ...old, customerMail : val }))} />
      </FormGroup>

      {isShipping() &&
        <FormGroup  label="Adresse und Nummer" fieldId="address" isRequired={isShipping()}>
          <TextInput isRequired={isShipping()}
                     validated={addressValidation}
                     type="text" id="address" name="address" aria-describedby="address-helper"
                     value={props.order.customerAddress} onChange={(_event, val) => props.setOrder(old => ({ ...old, customerAddress : val }))} />
        </FormGroup>
      }
      <FormGroup label="PLZ und Ort" fieldId="PstCodeCity" isRequired={isShipping()}>
        <InputGroup>
          <InputGroupItem isFill>
            <TextInput
              isRequired={isShipping()}
              validated={postcodeValidation} min={1000} max={99999}
              type="number" id="postcode" name="postcode" aria-describedby="postcode-helper"
              value={props.order.customerPostcode} onChange={(_event, val) => props.setOrder(old => ({ ...old, customerPostcode : val }))} />
          </InputGroupItem>

          <InputGroupItem isFill >
            <TextInput
              isRequired={isShipping()}
              validated={cityValidation}
              type="text" id="city" name="city" aria-describedby="address-helper"
              value={props.order.customerCity} onChange={(_event, val) => props.setOrder(old => ({ ...old, customerCity : val }))} />
          </InputGroupItem>
        </InputGroup>
      </FormGroup>

      <FormGroup role="group" isInline fieldId="basic-form-checkbox-group" label="Ich stimme zu...">
        {/*<Checkbox isRequired*/}
        {/*          isChecked={props.order.agbChecked}*/}
        {/*          onChange={(_event, val) => props.setOrder(old => ({ ...old, agbChecked : val }))}*/}
        {/*          label={<Link target={"_blank"} to=".\agb"><Button variant="link" isInline>AGB</Button></Link>}*/}
        {/*          aria-label="agb" id="agb" />*/}

        <Checkbox isRequired
                  isChecked={props.order.dsgvoChecked}
                  onChange={(_event, val) => props.setOrder(old => ({ ...old, dsgvoChecked : val }))}
                  label={<Link target={"_blank"} to="/datenschutz"><Button variant="link" isInline>DSGVO</Button></Link>}
                  aria-label="dsgvo" id="dsgvo" />
      </FormGroup>
    </Form>

  </>)
}

export { Step3Data }
