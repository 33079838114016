import * as React from "react";
import imgHeader from '@images/ticketHeader.png'
import QRCodeGenerator from 'react-hook-qrcode-svg'
import { Document, Page, Text, View, StyleSheet, Image, Svg, Path } from "@react-pdf/renderer";
import { Ticket, Order } from "@src/api";
import { nvlN } from "@app/utils/nvl";

interface IProps {
  ticket: Ticket;
  order: Order;
}
const PDFTicket: React.FunctionComponent<IProps> = ({ ticket, order } : IProps) => {

  const qrCode = QRCodeGenerator(ticket.ticketCode || "undefined");

  const styles = StyleSheet.create({
    mainPage : {
      fontSize: '11pt',
      fontFamily: 'Helvetica',
      padding: '1cm',
      margin: '0',
      flexDirection: 'column',
      backgroundColor: 'white'
    },
    hSpace15 : {
      height: '15pt',
    },
    hSpace30 : {
      height: '30pt',
    },
    subHeadline : {
      fontWeight : 'black',
      marginBottom : '8pt',
      textDecoration : 'underline',
      textDecorationStyle : 'solid',
      textDecorationColor : 'black'
    },
    ticketInfoBox: {
      padding: '10px',
      border: '1pt black solid',
      borderTopLeftRadius: '5pt',
      borderTopRightRadius: '5pt',
      borderBottomRightRadius: '5pt',
      borderBottomLeftRadius: '5pt',
    },
    ticketInfoRow1 : {
      flexDirection: 'row',
      margin: '10px',
      borderBottom: '0.5pt black solid',
      paddingBottom : '15pt'
    },
    ticketInfoRow2 : {
      flexDirection: 'row',
      margin: '10px'
    },
    ticketInfoBox_eventName : {
      fontSize: '15pt',
      fontWeight : "black",
      width: '50vw',
    },
    ticketInfoBox_ticketCode : {
      fontSize: '9pt',
      width: '50vw',
      textAlign : 'right'
    },
    ticketInfoBox_moreInfo : {
      width: '70vw',
      flexDirection: 'column',
    },
    ticketInfoBox_qrCode : {
      width: '30vw',
      textAlign : 'center',
      alignContent : 'center'
    },
    qrCodeImg : {
      width : '150px',
      textAlign : "center",
      alignContent : 'center',
      alignSelf : 'center'
    },
    generalInfoHeadline : {
      fontSize: '15pt',
      fontWeight : "black",
      textAlign : 'center'
    },
    generalInfoText : {
      fontSize: '9pt',
      textAlign : 'justify',
      marginLeft: '1.5cm',
      marginRight: '1.5cm'
    }
  });

  return (<>
    <Document>
      <Page size="A4" style={styles.mainPage}>
        <View>
          <Image src={imgHeader} cache={true} />
        </View>

        <div style={styles.hSpace30} />

        <View style={styles.ticketInfoBox}>
          <div style={styles.ticketInfoRow1}>
            <Text style={styles.ticketInfoBox_eventName}>{order.event?.eventName}</Text>
            <Text style={styles.ticketInfoBox_ticketCode}>{ticket.ticketCode}</Text>
          </div>

          <div style={styles.ticketInfoRow2}>
            <div style={styles.ticketInfoBox_moreInfo}>
              <Text style={styles.subHeadline}>Weitere Informationen:</Text>

              <Text>Dieses Ticket gehört: {ticket.ownerName}</Text>
              <Text>Info: {order.event?.ticketDescription}</Text>

              <div style={styles.hSpace15} />

              <Text>Datum: {order.event?.eventDate?.toLocaleString()}</Text>
              <Text>Ort: {order.event?.eventLocation}</Text>
              <Text>Veranstalter: {order.event?.operator?.operatorName}</Text>

              <div style={styles.hSpace15} />

              <Text>Käufer: {order.customerName} ({order.customerPostcode} {order.customerCity}) </Text>
              <Text>Kaufdatum: {order.creationDate?.toLocaleString()}</Text>
              <Text>Bestellung: {nvlN(order.orderNum).toString().padStart(4, '0')}#{ticket.orderRank}</Text>
            </div>

            <div style={styles.ticketInfoBox_qrCode}>
              <Svg style={styles.qrCodeImg}  viewBox={qrCode.viewBox}>
                <Path d={qrCode.path} fill={"#000000"} />
              </Svg>
            </div>

          </div>
        </View>

        <div style={styles.hSpace30} />

        <View>
          <Text style={styles.generalInfoHeadline}>Das ist dein persönliches Ticket!</Text>

          <div style={styles.hSpace15} />

          <Text style={styles.generalInfoText}>
            Dein persönlicher Code auf diesem Ticket ist nur einmal gültig.
            Der Code verliert nach der ersten Verwendung seine Gültigkeit und kann kein weiteres mal benutzt werden.
            ACHTUNG: &quot;First come, first serve&quot;. Daher sei vorsichtig mit der Weitergabe von Tickets & behalte dein persönliches Ticket bei dir!
          </Text>

        </View>

      </Page>
    </Document>
  </>);
}

export {PDFTicket}
