/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EventFilterTypes = {
    All: 'ALL',
    ShopPublished: 'SHOP_PUBLISHED',
    AdminCurrent: 'ADMIN_CURRENT',
    AdminArchived: 'ADMIN_ARCHIVED'
} as const;
export type EventFilterTypes = typeof EventFilterTypes[keyof typeof EventFilterTypes];


export function EventFilterTypesFromJSON(json: any): EventFilterTypes {
    return EventFilterTypesFromJSONTyped(json, false);
}

export function EventFilterTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventFilterTypes {
    return json as EventFilterTypes;
}

export function EventFilterTypesToJSON(value?: EventFilterTypes | null): any {
    return value as any;
}

