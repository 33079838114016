/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface TicketCheckResponse
 */
export interface TicketCheckResponse {
    /**
     * 
     * @type {Status}
     * @memberof TicketCheckResponse
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof TicketCheckResponse
     */
    eventName?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCheckResponse
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCheckResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCheckResponse
     */
    modificationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof TicketCheckResponse
     */
    modificationDate?: Date;
}

/**
 * Check if a given object implements the TicketCheckResponse interface.
 */
export function instanceOfTicketCheckResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketCheckResponseFromJSON(json: any): TicketCheckResponse {
    return TicketCheckResponseFromJSONTyped(json, false);
}

export function TicketCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
    };
}

export function TicketCheckResponseToJSON(value?: TicketCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusToJSON(value.status),
        'eventName': value.eventName,
        'ownerName': value.ownerName,
        'errorMessage': value.errorMessage,
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
    };
}

