/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Operator } from './Operator';
import {
    OperatorFromJSON,
    OperatorFromJSONTyped,
    OperatorToJSON,
} from './Operator';

/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    eventId?: number;
    /**
     * 
     * @type {Operator}
     * @memberof EventInfo
     */
    operator?: Operator;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    eventName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    eventLocation?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventInfo
     */
    eventDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    ticketPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    maxAvailableTickets?: number;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    ticketsSold?: number;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    ticketAmountFreeShipping?: number;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    shippingCosts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    printAtHomeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    shippingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    createTicketsForShipping?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    ticketDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventInfo
     */
    startPublishing?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventInfo
     */
    endPublishing?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    thumbnailImageLocation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    scanEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    gdprValid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    mailEventReminderSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    modificationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventInfo
     */
    modificationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    ticketsSoldPercent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    orderingStarted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    orderingExpired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    orderingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    eventInPast?: boolean;
}

/**
 * Check if a given object implements the EventInfo interface.
 */
export function instanceOfEventInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventInfoFromJSON(json: any): EventInfo {
    return EventInfoFromJSONTyped(json, false);
}

export function EventInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'operator': !exists(json, 'operator') ? undefined : OperatorFromJSON(json['operator']),
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'eventLocation': !exists(json, 'eventLocation') ? undefined : json['eventLocation'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'ticketPrice': !exists(json, 'ticketPrice') ? undefined : json['ticketPrice'],
        'maxAvailableTickets': !exists(json, 'maxAvailableTickets') ? undefined : json['maxAvailableTickets'],
        'ticketsSold': !exists(json, 'ticketsSold') ? undefined : json['ticketsSold'],
        'ticketAmountFreeShipping': !exists(json, 'ticketAmountFreeShipping') ? undefined : json['ticketAmountFreeShipping'],
        'shippingCosts': !exists(json, 'shippingCosts') ? undefined : json['shippingCosts'],
        'printAtHomeEnabled': !exists(json, 'printAtHomeEnabled') ? undefined : json['printAtHomeEnabled'],
        'shippingEnabled': !exists(json, 'shippingEnabled') ? undefined : json['shippingEnabled'],
        'createTicketsForShipping': !exists(json, 'createTicketsForShipping') ? undefined : json['createTicketsForShipping'],
        'ticketDescription': !exists(json, 'ticketDescription') ? undefined : json['ticketDescription'],
        'startPublishing': !exists(json, 'startPublishing') ? undefined : (new Date(json['startPublishing'])),
        'endPublishing': !exists(json, 'endPublishing') ? undefined : (new Date(json['endPublishing'])),
        'thumbnailImageLocation': !exists(json, 'thumbnailImageLocation') ? undefined : json['thumbnailImageLocation'],
        'scanEnabled': !exists(json, 'scanEnabled') ? undefined : json['scanEnabled'],
        'gdprValid': !exists(json, 'gdprValid') ? undefined : json['gdprValid'],
        'mailEventReminderSent': !exists(json, 'mailEventReminderSent') ? undefined : json['mailEventReminderSent'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
        'ticketsSoldPercent': !exists(json, 'ticketsSoldPercent') ? undefined : json['ticketsSoldPercent'],
        'orderingStarted': !exists(json, 'orderingStarted') ? undefined : json['orderingStarted'],
        'orderingExpired': !exists(json, 'orderingExpired') ? undefined : json['orderingExpired'],
        'orderingEnabled': !exists(json, 'orderingEnabled') ? undefined : json['orderingEnabled'],
        'eventInPast': !exists(json, 'eventInPast') ? undefined : json['eventInPast'],
    };
}

export function EventInfoToJSON(value?: EventInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'operator': OperatorToJSON(value.operator),
        'eventName': value.eventName,
        'eventLocation': value.eventLocation,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString()),
        'ticketPrice': value.ticketPrice,
        'maxAvailableTickets': value.maxAvailableTickets,
        'ticketsSold': value.ticketsSold,
        'ticketAmountFreeShipping': value.ticketAmountFreeShipping,
        'shippingCosts': value.shippingCosts,
        'printAtHomeEnabled': value.printAtHomeEnabled,
        'shippingEnabled': value.shippingEnabled,
        'createTicketsForShipping': value.createTicketsForShipping,
        'ticketDescription': value.ticketDescription,
        'startPublishing': value.startPublishing === undefined ? undefined : (value.startPublishing.toISOString()),
        'endPublishing': value.endPublishing === undefined ? undefined : (value.endPublishing.toISOString()),
        'thumbnailImageLocation': value.thumbnailImageLocation,
        'scanEnabled': value.scanEnabled,
        'gdprValid': value.gdprValid,
        'mailEventReminderSent': value.mailEventReminderSent,
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'ticketsSoldPercent': value.ticketsSoldPercent,
        'orderingStarted': value.orderingStarted,
        'orderingExpired': value.orderingExpired,
        'orderingEnabled': value.orderingEnabled,
        'eventInPast': value.eventInPast,
    };
}

