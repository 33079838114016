import React from "react";
import { ChartDonutThreshold, ChartDonutUtilization } from "@patternfly/react-charts";

interface IProps {
  name: string;
  title: string;
  numberPayed : number;
  numberNotPayed : number;
  maxAvailable : number;

}
export const SoldTicketChart: React.FunctionComponent<IProps> = (props : IProps) => {

  const percentPayed = props.numberPayed / props.maxAvailable  * 100
  const percentNotPayed = props.numberNotPayed / props.maxAvailable * 100
  const totalSold = props.numberNotPayed + props.numberPayed

  const totalPercent = percentPayed + percentNotPayed


  return (<>
    {/* threshold outside = number of paid and not paid tickets */}
    {/* colored value = total number of sold tickets */}
      <ChartDonutThreshold
        ariaDesc={props.title}
        ariaTitle={props.title}
        name={props.name}
        constrainToVisibleArea
        invert
        data={[{ x: 'bereits bezahlt', y: percentPayed }, { x: 'nicht bezahlt', y: totalPercent }, { x: 'verfügbar', y: 100 }]}
        labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
        padding={{
          bottom: 20,
          left: 40,
          right: 40,
          top: 0
        }}
      >
        <ChartDonutUtilization
          data={{ x: 'verkauft', y: totalPercent }}
          labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
          legendData={[{ name: `Tickets verkauft: ${totalPercent}%` }]}
          legendPosition="bottom"
          title={`${totalSold}`}
          subTitle={`von ${props.maxAvailable}`}
          thresholds={[
            { value: 50, color: 'green' },
            { value: 70, color: 'yellow' },
            { value: 90, color: 'red' }
          ]}
        />
      </ChartDonutThreshold>
  </>);
}