import { createContext, Key } from "react";
import { AlertProps } from "@patternfly/react-core";
import { Configuration, Session } from "@src/api";

/* eslint-disable */
export const SystemContext = createContext ({
  notifications: [] as AlertProps[],
  addNotification : (title: string, variant: AlertProps['variant'], key : Key) => { /**/ },

  session : {} as Session | undefined,
  setSession :(session : Session | undefined) => { /* void **/ },
  apiConfig : {} as Configuration
})