import React from 'react';
import {
  CalendarMonth,
  InputGroup,
  InputGroupItem,
  TextInput,
  Button,
  Popover,
  Dropdown,
  DropdownItem,
  DropdownList,
  MenuToggle,
  MenuToggleElement
} from '@patternfly/react-core';
import OutlinedCalendarAltIcon from '@patternfly/react-icons/dist/esm/icons/outlined-calendar-alt-icon';
import OutlinedClockIcon from '@patternfly/react-icons/dist/esm/icons/outlined-clock-icon';

interface IProps {
  currentDate : Date | undefined;
  setDate : (newDate : Date) => void;
}

export const DateTimePicker: React.FunctionComponent<IProps> = (props : IProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [isTimeOpen, setIsTimeOpen] = React.useState(false);
  const times = Array.from(new Array(20), (_, i) => i + 4);

  const onToggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
    setIsTimeOpen(false);
  };

  const onToggleTime = () => {
    setIsTimeOpen(!isTimeOpen);
    setIsCalendarOpen(false);
  };

  const onSelectCalendar = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newValueDate: Date) => {
    props.setDate(
      new Date(
        newValueDate.getFullYear(),
        newValueDate.getMonth(),
        newValueDate.getDate(),
        props.currentDate?.getHours() || 20,
        props.currentDate?.getMinutes() || 0
      )
    )

    setIsCalendarOpen(!isCalendarOpen);
  };

  const onSelectTime = (ev: React.MouseEvent<Element, MouseEvent> | undefined) => {
    const timeStr = ev?.currentTarget?.textContent as string

    const timeArr = timeStr.split(':')

    props.setDate(
      new Date(
        props.currentDate?.getFullYear() || (new Date().getFullYear()),
        props.currentDate?.getMonth() || 1,
        props.currentDate?.getDate() || 1,
        parseInt(timeArr[0]),
        parseInt(timeArr[1])
      )
    )

    setIsTimeOpen(!isTimeOpen);
  };

  const timeOptions = times.map((time) => <DropdownItem key={time}>{`${time}:00`}</DropdownItem>);

  const calendar = <CalendarMonth date={props.currentDate} onChange={onSelectCalendar} />;

  const time = (
    <Dropdown
      onSelect={onSelectTime}
      isOpen={isTimeOpen}
      onOpenChange={(isOpen: boolean) => setIsTimeOpen(isOpen)}
      toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
        <MenuToggle
          ref={toggleRef}
          onClick={onToggleTime}
          isExpanded={isTimeOpen}
          aria-label="Time picker"
          icon={<OutlinedClockIcon aria-hidden="true" />}
        />
      )}
    >
      <DropdownList>{timeOptions}</DropdownList>
    </Dropdown>
  );

  const calendarButton = (
    <Button variant="control" aria-label="Toggle the calendar" onClick={onToggleCalendar}>
      <OutlinedCalendarAltIcon />
    </Button>
  );

  return (
    <div style={{ width: '300px' }}>
      <Popover
        position="bottom"
        bodyContent={calendar}
        showClose={false}
        isVisible={isCalendarOpen}
        hasNoPadding
        hasAutoWidth
      >
        <InputGroup>
          <InputGroupItem>
            <TextInput
              type="text"
              id="date-time"
              aria-label="date and time picker demo"
              value={props.currentDate?.toLocaleString()}
              readOnlyVariant="default"
            />
          </InputGroupItem>
          <InputGroupItem>{calendarButton}</InputGroupItem>
          <InputGroupItem>{time}</InputGroupItem>
        </InputGroup>
      </Popover>
    </div>
  );
};