import * as React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  Nav,
  NavList,
  NavItem,
  SkipToContent,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Masthead,
  MastheadMain,
  MastheadBrand,
  Brand,
  MastheadContent,
  Page,
  PageSidebar,
  PageSidebarBody,
  ToolbarGroup,
  ButtonVariant,
  Button,
  Dropdown,
  Avatar,
  MenuToggle, DropdownList, DropdownItem, NavGroup, MastheadToggle, PageToggleButton
} from "@patternfly/react-core";

import logo from '@images/logoW.png'
import { useContext, useState } from "react";
import { SystemContext } from "@app/AppContext/SystemContext";

import { IAppRoute } from "@app/Routing/IAppRoute";
import { IAppRouteGroup } from "@app/Routing/IAppRouteGroup";
import { routes } from "@app/Admin/App/routes";
import { BarsIcon, BellIcon } from "@patternfly/react-icons";

import imgAvatar from '@images/avatar-light.svg';
import { useNavigate } from "react-router";

interface IAppLayout {
  children: React.ReactNode;
}

const AdminLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {

  const location = useLocation()
  const navigate = useNavigate();
  const systemContext = useContext(SystemContext)
  const [isUserDropDownOpen, setUserDropDownOpen] = useState(false)
  const [sidebarNavOpen] = useState(false)

  const renderNavItem = (route: IAppRoute, index: number) => {
    if(route.visibleFor !== undefined && route.visibleFor?.length !== 0 && !route.visibleFor?.includes(systemContext.session?.userRole || "")) {
      return;
    }

    return (
      <NavItem
        key={route.label + "-I" + index}
        itemId={route.label + "-I" + index}
        isActive={location.pathname.endsWith(route.path)}
      >
        <NavLink to={"/eventadmin" + route.path} key={"SideBarNavLink" + route.label + "-I" + index}>
          {route.label}
        </NavLink>
      </NavItem>
    )
  };

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <>
      <NavGroup key={"NavGroup" + group.label + groupIndex} title={group.label}>
        { group.routes.map((route, idx) => route.label && renderNavItem(route, idx)) }
      </NavGroup>
    </>
  );

  const renderPageNav = () => (
    <Nav aria-label="NavSideBar" ouiaId="NavSideBar">
      <NavList>
        { routes
          .map((route, idx) => route.label && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx))) }
      </NavList>
    </Nav>
  );

  const renderHeader = () => (
    <Masthead>
      <MastheadToggle>
        <PageToggleButton variant="plain" aria-label="Global navigation">
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand>
          <Brand src={logo} alt="TICK-AT-R" heights={{ default: '36px' }} />
        </MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        <Toolbar key="MastheadContentToolbar" isFullHeight isStatic>
          <ToolbarContent key="MastheadContentToolbarContent">
            <ToolbarGroup
              key="ToolbarGroup#1"
              variant="icon-button-group"
              align={{ default: 'alignRight' }}
              spacer={{ default: 'spacerNone', md: 'spacerMd' }}
            >
              <ToolbarItem key="ToolbarItem#1">
                <Button
                  key="NotificationsButton"
                  aria-label="Notifications"
                  variant={ButtonVariant.plain}
                  icon={<BellIcon />}
                  onClick={() => { /* TODO */ }}
                />
              </ToolbarItem>
            </ToolbarGroup>

            <ToolbarItem key="ToolbarItem#2" visibility={{ default: 'hidden', md: 'visible' }}>
              <Dropdown
                key="MastheadContentToolbarDropdown"
                isOpen={isUserDropDownOpen}
                onSelect={() => setUserDropDownOpen(false)}
                onOpenChange={(isOpen) => setUserDropDownOpen(isOpen)}
                popperProps={{ position: 'right' }}
                toggle={(toggleRef) => (
                  <MenuToggle
                    key="MenuToggleNavBarTop"
                    ref={toggleRef}
                    isExpanded={isUserDropDownOpen}
                    onClick={() => setUserDropDownOpen(!isUserDropDownOpen)}
                    icon={<Avatar src={imgAvatar} alt="" />}
                    isFullHeight
                  >
                    { systemContext.session?.displayName }
                  </MenuToggle>
                )}
              >
                <DropdownList>
                  <DropdownItem key="logout" onClick={() => navigate("/eventadmin/logout")}>Abmelden</DropdownItem>
                </DropdownList>
              </Dropdown>
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </MastheadContent>
    </Masthead>
  );

  const renderSidebar = () => (
    <PageSidebar isSidebarOpen={sidebarNavOpen || false }>
      <PageSidebarBody>
        { renderPageNav() }
      </PageSidebarBody>
    </PageSidebar>
  )

  return (
    <Page
      key="AdminLayoutPage"
      header={ renderHeader() }
      isManagedSidebar
      sidebar={renderSidebar()}
      skipToContent={<SkipToContent key="SkipToContent" href={`#main-content-section`}>Skip to content</SkipToContent>}
      mainContainerId="main-content-section"
    >
      {children}
    </Page>
  );
};

export { AdminLayout };
