import { ValidatedOptions } from "@patternfly/react-core";

export function mailValidation (value : string | undefined) : ValidatedOptions {
  if (value === undefined) return ValidatedOptions.error
  return (new RegExp("^[\\wß.-]+@([\\wß-]+.)+[\\wß-]{2,6}$").test(value)) ? ValidatedOptions.success : ValidatedOptions.error
}

export function textValidation (value : string | undefined, minLength : number) : ValidatedOptions {
  if (value === undefined) return ValidatedOptions.error
  return (value != null && value != "" && value.length >= minLength) ? ValidatedOptions.success : ValidatedOptions.error;
}

export function numberValidation (value : number | undefined, min : number, max : number) : ValidatedOptions {
  if (value === undefined) return ValidatedOptions.error
  return (value != null && value >= min && value <= max) ? ValidatedOptions.success : ValidatedOptions.error;
}

export function textWithSpaceValidation (value : string | undefined) : ValidatedOptions {
  if(value === undefined || value == null || value == "") {
    return ValidatedOptions.error
  }

  const subArr : string[] = value.trim().split(" ")
  if (subArr.length <= 1) {
    return ValidatedOptions.error
  }

  return ValidatedOptions.success
}