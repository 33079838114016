import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle, DescriptionListDescription, DescriptionListGroup, Label,
  Progress,
  ProgressMeasureLocation,
  ProgressSize,
  ProgressVariant, Split, SplitItem
} from "@patternfly/react-core";
import { ArrowRightIcon, InfoCircleIcon } from "@patternfly/react-icons";
import { EventInfo } from "@src/api";
import * as Util from "@app/utils/nvl";
import { OrderWizard } from "@app/Webshop/OrderWizard/OrderWizard";

import styles from './SingleEvent.module.css';

interface IProps {
  event : EventInfo
}

const SingleEvent: React.FunctionComponent<IProps> = (props : IProps) => {

  const [isWizardOpen, setWizardOpen] = React.useState(false);


  const renderProgressType = () => {
    if (Util.nvlN(props.event.ticketsSoldPercent) >= 100) { return { variant: ProgressVariant.danger, text : "Aktuell keine Tickets verfügbar..." }}
    if (Util.nvlN(props.event.ticketsSoldPercent) >= 90) { return { variant: ProgressVariant.danger, text : "Achtung: Nur noch sehr wenige Tickets verfügbar..." }}
    if (Util.nvlN(props.event.ticketsSoldPercent) >= 75) { return { variant: ProgressVariant.warning, text : "Achtung: Nur noch wenige Tickets verfügbar..." }}
    return { variant: ProgressVariant.success, text : "" }
  }

  const renderAction = () : { isDisabled : boolean, cardAction : JSX.Element } => {
    if(Util.nvlN(props.event.ticketsSoldPercent)  >= 100) {
      const soldOutElem = <Label color="red" icon={<InfoCircleIcon />}>Ausverkauft</Label>
      return { isDisabled : true, cardAction : soldOutElem }
    }

    if(!props.event.orderingEnabled) {
      const notStartetElem = (<Label color="green" icon={<InfoCircleIcon />}>
        Verkauf startet am { props.event.startPublishing?.toLocaleDateString() } um { props.event.startPublishing?.toLocaleTimeString() }
      </Label>)

      return { isDisabled : true, cardAction : notStartetElem }
    }

    const sellNowButton = <Button variant="link">Jetzt kaufen <ArrowRightIcon /></Button>
    return { isDisabled : false, cardAction : sellNowButton }
  }

  const onWizardClickHandle = () => {
    // if sold out or ordering not startet yet, no action
    if(renderAction().isDisabled) { return; }

    // else open wizard
    setWizardOpen(true);
  }

  return (
    <>
      <DescriptionListGroup style={ renderAction().isDisabled ? { opacity: 0.6 } : {} }>
        <DescriptionListDescription>
          <OrderWizard key="OWizard" isWizardOpen={isWizardOpen} setWizardOpen={setWizardOpen} wizardEvent={props.event} />
          <Card isCompact isFlat isRounded onClick={() => onWizardClickHandle() } disabled={renderAction().isDisabled} style={ renderAction().isDisabled ? { backgroundColor: "lightgray" } : {} }>
            <CardHeader className={styles.cardHeader}>
              <img src={props.event.thumbnailImageLocation} className={styles.cardHeaderImage} alt={"ticket image"} draggable="false"/>
            </CardHeader>

            <CardTitle>
              <Split isWrappable>
                <SplitItem translate={"no"}>{props.event.eventName}</SplitItem>
                <SplitItem isFilled> {/* spacer */} </SplitItem>
                <SplitItem> { renderAction().cardAction } </SplitItem>
              </Split>

            </CardTitle>

            <CardBody>
              <b>Datum:</b> {props.event.eventDate?.toLocaleDateString()} ab {props.event.eventDate?.toLocaleTimeString()} Uhr <br/>
              <b>Ort:</b> {props.event.eventLocation} <br/>
              <b>Veranstalter:</b> {props.event.operator?.operatorName} <br/>
              <b>Beschreibung:</b> {props.event.ticketDescription} <br/>
            </CardBody>

            <CardFooter>
              <Progress value={Util.nvlN(props.event.ticketsSoldPercent)} title={renderProgressType().text} size={ProgressSize.sm} variant={renderProgressType().variant} measureLocation={ProgressMeasureLocation.outside} />
            </CardFooter>
          </Card>

        </DescriptionListDescription>
      </DescriptionListGroup>
    </>
  )}

export { SingleEvent };
