import * as React from 'react';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import {
  PageSection,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody, EmptyStateHeader, EmptyStateFooter,
} from '@patternfly/react-core';
import { useNavigate } from "react-router";

const NotFound: React.FunctionComponent = () => {
  function GoHomeBtn() {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/');
    }
    return (
      <Button onClick={handleClick}>zur Startseite</Button>
    );
  }

  return (
    <PageSection>
    <EmptyState variant="full">
      <EmptyStateHeader titleText="404 Page not found" icon={<EmptyStateIcon icon={ExclamationTriangleIcon} />} headingLevel="h1" />
      <EmptyStateBody>
        Die gewünschte Seite kann leider nicht gefunden werden.
      </EmptyStateBody><EmptyStateFooter>
      <GoHomeBtn />
    </EmptyStateFooter></EmptyState>
  </PageSection>
  )
};

export { NotFound };
