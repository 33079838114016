import * as React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  Nav,
  NavList,
  NavItem,
  SkipToContent,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Masthead,
  MastheadMain,
  MastheadBrand,
  Brand, MastheadContent, Page
} from "@patternfly/react-core";

import logo from '@images/logoW.png'

import { IAppRoute } from "@app/Routing/IAppRoute";
import { IAppRouteGroup } from "@app/Routing/IAppRouteGroup";
import { routes } from "@app/Webshop/App/routes";

interface IAppLayout {
  children: React.ReactNode;
}

const WebShopLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {

  const location = useLocation()

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem itemId={`${route.label}-${index}`} key={`${route.label}-${index}`} id={`${route.label}-${index}`} isActive={route.path === location.pathname}>
      <NavLink to={route.path} end={route.exact}>
        {route.label}
      </NavLink>
    </NavItem>
  );

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <></>
  );

  const renderPageNav = () => (
    <Nav aria-label="Nav" variant="horizontal">
      <NavList>
        { routes.map((route, idx) => route.label && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx))) }
      </NavList>
    </Nav>
  );

  const renderHeader = () => (
    <Masthead>
      <MastheadMain>
        <MastheadBrand>
          <Brand src={logo} alt="TICK-AT-R" heights={{ default: '36px' }} />
        </MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        <Toolbar id="shopToolbar" isFullHeight isStatic>
          <ToolbarContent>
            <ToolbarItem isOverflowContainer>
              { renderPageNav() }
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </MastheadContent>
    </Masthead>
  );

  return (
    <Page
      header={ renderHeader() }
      skipToContent={<SkipToContent href={`#main-content-section`}>Skip to content</SkipToContent>}
      mainContainerId="main-content-section"
    >
      {children}
    </Page>
  );
};

export { WebShopLayout };
