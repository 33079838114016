import * as React from 'react';
import { Skeleton } from "@patternfly/react-core";

const ContentLoading: React.FunctionComponent = () => {
  return <>
    <div style={{ height : "10px" }} />
    <Skeleton width="25%" height="30px" screenreaderText="loading" />
    <div style={{ height : "10px" }} />
    <Skeleton width="33%" height="30px" screenreaderText="loading" />
    <div style={{ height : "10px" }} />
    <Skeleton width="50%" height="30px" screenreaderText="loading" />
    <div style={{ height : "10px" }} />
    <Skeleton width="66%" height="30px" screenreaderText="loading" />
    <div style={{ height : "10px" }} />
    <Skeleton width="75%" height="30px" screenreaderText="loading" />
    <div style={{ height : "10px" }} />
    <Skeleton height="30px"/>
  </>
}

export { ContentLoading };
