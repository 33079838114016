import React, { useContext, useEffect, useState } from "react";
import { AboutModal, TextContent, TextList, TextListItem } from '@patternfly/react-core';
import brandImg from '@images/logoW.png';
import backgroundImg from '@images/logoOutlineW.svg';
import { Link } from "react-router-dom";
import { SystemContext } from "@app/AppContext/SystemContext";
import { SystemApi, SystemInfo } from "@src/api";

interface IProps {
  isModalOpen : boolean
  toggleModal : () => void
}

export const AboutDialog: React.FunctionComponent<IProps> = (props : IProps) => {

  const systemContext = useContext(SystemContext);
  const [systemInfo, setSystemInfo] = useState<SystemInfo>({})

  useEffect(() => {
    (new SystemApi()).getSystemInfo()
      .then(res => {
        setSystemInfo(res)
      })
      .catch( err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  return (
    <React.Fragment>
      <AboutModal
        isOpen={props.isModalOpen}
        onClose={() => props.toggleModal()}
        trademark="&copy; Tick@R / Peirlberger Jürgen"
        brandImageSrc={brandImg}
        brandImageAlt="Logo"
        backgroundImageSrc={backgroundImg}
        productName="Tick@R | TICKATR.at"
      >
        <TextContent>
          <TextList component="dl">
            <TextListItem component="dt">Kontakt</TextListItem>
            <TextListItem component="dd">Peirlberger Jürgen</TextListItem>
            <TextListItem component="dt">Mail</TextListItem>
            <TextListItem component="dd">office@tickatr.at</TextListItem>

            <TextListItem component="dt">Benutzername</TextListItem>
            <TextListItem component="dd">
              {systemContext.session?.username || "WebShop"}
            </TextListItem>

            <TextListItem component="dt">Benutzerrolle</TextListItem>
            <TextListItem component="dd">
              {systemContext.session?.userRole || "WebShop"}
            </TextListItem>

            <TextListItem component="dt">Impressum</TextListItem>
            <TextListItem component="dd"><Link to="/impressum" target="_blank">Impressum</Link></TextListItem>

            <TextListItem component="dt">DSGVO</TextListItem>
            <TextListItem component="dd"><Link to="/datenschutz" target="_blank">Datenschutz</Link></TextListItem>

            <TextListItem component="dt">System-Version</TextListItem>
            <TextListItem component="dd">{systemInfo.systemVersion}</TextListItem>

            <TextListItem component="dt">Buildtime</TextListItem>
            <TextListItem component="dd">{systemInfo.buildTime}</TextListItem>

            <TextListItem component="dt">Haftungsausschluss</TextListItem>
            <TextListItem component="dd">
              Keine gewerbliche Nutzung. Wir übernehmen keine Haftung. Für Bezahlung und Versand ist der jeweilige Verkäufer verantwortlich.
            </TextListItem>

          </TextList>
        </TextContent>
      </AboutModal>
    </React.Fragment>
  );
};
