import React, { useContext } from "react";
import { EventInfo } from "@src/api";
import {
  Card,
  PageSection,
  Toolbar,
  ToolbarContent,
  Title,
  Bullseye,
  EmptyState,
  EmptyStateVariant,
  EmptyStateHeader,
  EmptyStateIcon,
  Button,
  OverflowMenu,
  OverflowMenuContent,
  OverflowMenuGroup, OverflowMenuItem, OverflowMenuControl
} from "@patternfly/react-core";

import { Table, Thead, Tr, Th, Tbody, Td, ActionsColumn, IAction, ExpandableRowContent } from "@patternfly/react-table";
import { CheckIcon, PencilAltIcon, TimesIcon } from "@patternfly/react-icons";
import { useNavigate } from "react-router";
import * as events from "events";
import { EventProgress } from "@app/Admin/Events/EventProgress";

interface IProps {
  title: string;
  activeTabIndex : string | number;
  events: EventInfo[];
}

export const EventTable: React.FunctionComponent<IProps> = (props) => {

  const navigate = useNavigate()
  const [expandedEventNames, setExpandedEventNames] = React.useState<string[]>([]);
  const isEventExpanded = (event: EventInfo) => expandedEventNames.includes(event.eventName || "");
  const setEventExpanded = (event: EventInfo, isExpanding = true) =>
    setExpandedEventNames((prevExpanded) => {
      const otherExpandedNames = prevExpanded.filter((r) => r !== event.eventName);
      return isExpanding ? [...otherExpandedNames, (event.eventName || "")] : otherExpandedNames;
    });

  const tableToolbar = (
    <Toolbar usePageInsets id="compact-toolbar">
      <ToolbarContent>
      {/*  currently no toolbar */}
      </ToolbarContent>
    </Toolbar>
  );

  const headers = ["Name", "Ort", "Datum", "Veranstalter", "Scan aktiv", ""];

  const renderOverflowActions = (currentRow : EventInfo): IAction[] => {
    const actions : IAction[] = []
    actions.push({ title: 'bearbeiten', onClick: () => navigate(`./${currentRow.eventId}`, {state : {tabIndex : props.activeTabIndex}})})
    return actions;
  }

  return (
    <React.Fragment>
      <PageSection>
        <Title headingLevel="h1">{props.title}</Title>
      </PageSection>
      <PageSection isFilled>
        <Card>
          {tableToolbar}
          <Table variant="compact" aria-label="Compact Table" isExpandable={true}>
            <Thead>
              <Tr>
                <Th />
                <Th key={0}>{headers[0]}</Th>
                <Th key={1}>{headers[1]}</Th>
                <Th key={2}>{headers[2]}</Th>
                <Th key={3}>{headers[3]}</Th>
                <Th key={4}>{headers[4]}</Th>
                <Th key={5}>{headers[5]}</Th>
              </Tr>
            </Thead>

              {props.events.length > 0 && props.events.map((row, rowIndex) => (
                <Tbody key={row.eventName} isExpanded={isEventExpanded(row)}
                       onDoubleClick={ () => navigate(`./${row.eventId}`, {state : {tabIndex : props.activeTabIndex}}) }
                >
                  <Tr key={`${row.eventName}_head_${rowIndex}`}>
                      <Td expand={{
                        rowIndex,
                        isExpanded: isEventExpanded(row),
                        onToggle: () => setEventExpanded(row, !isEventExpanded(row)),
                        expandId: `${row.eventName}_expanded`
                      }} />

                      <Td dataLabel={headers[0]}><b>{row.eventName}</b></Td>
                      <Td dataLabel={headers[1]}>{row.eventLocation}</Td>
                      <Td dataLabel={headers[2]}>{row.eventDate?.toLocaleString()}</Td>
                      <Td dataLabel={headers[3]}>{row.operator?.operatorName}</Td>
                      <Td dataLabel={headers[4]}>{row.scanEnabled ? <CheckIcon /> : <TimesIcon /> }</Td>
                      <Td dataLabel={headers[5]} isActionCell>
                        <OverflowMenu breakpoint="lg">
                          <OverflowMenuContent>
                            <OverflowMenuGroup groupType="button">
                              <OverflowMenuItem>
                                <Button variant="plain" size="sm" onClick={() => navigate(`./${row.eventId}`, {state : {tabIndex : props.activeTabIndex}})}>
                                  <PencilAltIcon/>
                                </Button>
                              </OverflowMenuItem>
                            </OverflowMenuGroup>
                          </OverflowMenuContent>
                          <OverflowMenuControl>
                            <ActionsColumn items={renderOverflowActions(row)} />
                          </OverflowMenuControl>
                        </OverflowMenu>
                      </Td>
                  </Tr>
                  <Tr isExpanded={isEventExpanded(row)} key={`${row.eventName}_content_${rowIndex}`}>
                    <Td colSpan={6}>
                      <ExpandableRowContent>
                        <EventProgress event={row} />
                      </ExpandableRowContent>
                    </Td>
                  </Tr>
                </Tbody>
              ))}

              {props.events.length <= 0 &&
                <Tbody>
                  <Tr key={"no_data"}>
                      <Td colSpan={8}>
                        <Bullseye>
                          <EmptyState variant={EmptyStateVariant.sm}>
                            <EmptyStateHeader
                              icon={<EmptyStateIcon icon={CheckIcon} />}
                              titleText="Keine Veranstaltungen"
                              headingLevel="h2"
                            />
                          </EmptyState>
                        </Bullseye>
                      </Td>
                  </Tr>
                </Tbody>
              }
          </Table>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};