import * as React from "react";
import imgHeader from '@images/ticketHeader.png'
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { EventInfo, EventReportResponse } from "@src/api";

interface IProps {
  eventReportData: EventReportResponse;
}
const EventReportPDF: React.FunctionComponent<IProps> = ({ eventReportData } : IProps) => {

  const styles = StyleSheet.create({
    mainPage : {
      fontSize: '11pt',
      fontFamily: 'Helvetica',
      padding: '1cm',
      margin: '0',
      flexDirection: 'column',
      backgroundColor: 'white'
    },
    hSpace15 : {
      height: '15pt',
    },
    hSpace30 : {
      height: '30pt',
    },
    headline : {
      fontSize: '15pt',
      fontWeight : "black",
      textAlign : 'center'
    },
    subhead : {
      fontSize: '15pt',
      fontWeight : "black",
      paddingBottom : '5px'
    },
    textBold : {
      fontWeight : "black",
    },
    center : {
      textAlign : 'center'
    },
    table: {
      display: "flex",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableCol: {
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    width10: {
      width: "10%",
    },
    width12: {
      width: "12.5%",
    },
    width25: {
      width: "25%",
    },
    width40: {
      width: "40%",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    }
  });

  const orders = eventReportData.orders || [];
  const storno = eventReportData.storno || [];


  return (<>
    <Document>
      <Page size="A4" style={styles.mainPage} wrap>
        <View>
          <Image src={imgHeader} cache={true} />
        </View>

        <View>
          <Text style={styles.headline}>Event-Zusammenfassung für { eventReportData.event?.eventName }</Text>
          <Text style={styles.headline}>Veranstaltungsdatum: {eventReportData.event?.eventDate?.toLocaleString()}</Text>

          <Text style={styles.center}>erstellt am { new Date().toLocaleString() } </Text>
        </View>

        <div style={styles.hSpace30} />

        <View>

        {
          Object.entries(orders).map((elem, idx) => {
            const title = elem[0]
            const orders = elem[1]

            if(orders.length == 0) {
              return <></>
            }

            return (<>
              <View wrap={false}>
                <Text style={styles.subhead}> { title } </Text>
                <View style={styles.table} wrap={false}>
                  <View style={styles.tableRow}>
                    <View style={[styles.tableCol, styles.width25]}><Text style={[styles.textBold, styles.center]}>Bestellnummer</Text></View>
                    <View style={[styles.tableCol, styles.width40]}><Text style={[styles.textBold, styles.center]}>Kunde</Text></View>
                    <View style={[styles.tableCol, styles.width10]}><Text style={[styles.textBold, styles.center]}>Tickets</Text></View>
                    <View style={[styles.tableCol, styles.width25]}><Text style={[styles.textBold, styles.center]}>Gesamtpreis</Text></View>
                  </View>

                  {
                    orders.map(elem => (<>

                      <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.width25]}>
                          <Text style={styles.center}> TickR { elem.orderNum } </Text>
                        </View>
                        <View style={[styles.tableCol, styles.width40]}>
                          <Text> { elem.customerName } </Text>
                        </View>
                        <View style={[styles.tableCol, styles.width10]}>
                          <Text style={styles.center}> { elem.numberOfTickets } </Text>
                        </View>
                        <View style={[styles.tableCol, styles.width25]}>
                          <Text style={styles.center}> &euro; { (elem.totalPrice || 0).toFixed(2)} </Text>
                        </View>
                      </View>
                    </>))
                  }
                </View>
                <div style={styles.hSpace30} />
              </View>
            </>)

            }
          )
        }
        </View>

        <View wrap={false}>
          <Text style={styles.subhead}> Stornierte Bestellungen </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.width12]}><Text style={[styles.textBold, styles.center]}>Bestellnummer</Text></View>
              <View style={[styles.tableCol, styles.width25]}><Text style={[styles.textBold, styles.center]}>Kunde</Text></View>
              <View style={[styles.tableCol, styles.width12]}><Text style={[styles.textBold, styles.center]}>Tickets</Text></View>
              <View style={[styles.tableCol, styles.width25]}><Text style={[styles.textBold, styles.center]}>Storno durch</Text></View>
              <View style={[styles.tableCol, styles.width25]}><Text style={[styles.textBold, styles.center]}>Storno-Grund</Text></View>
            </View>

            {
              storno.map((elem) => (<>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.width12]}>
                    <Text style={styles.center}> TickR { elem.orderNum } </Text>
                  </View>
                  <View style={[styles.tableCol, styles.width25]}>
                    <Text> { elem.customerName } </Text>
                  </View>
                  <View style={[styles.tableCol, styles.width12]}>
                    <Text style={styles.center}> { elem.numberOfTickets } </Text>
                  </View>
                  <View style={[styles.tableCol, styles.width25]}>
                    <Text> { elem.stornoPerson } </Text>
                  </View>
                  <View style={[styles.tableCol, styles.width25]}>
                    <Text> { elem.stornoReason } </Text>
                  </View>
                </View>
              </>))
            }
          </View>
        </View>
      </Page>
    </Document>
  </>);
}

export {EventReportPDF}
