/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { ShippingType } from './ShippingType';
import {
    ShippingTypeFromJSON,
    ShippingTypeFromJSONTyped,
    ShippingTypeToJSON,
} from './ShippingType';

/**
 * 
 * @export
 * @interface OrderReportInfo
 */
export interface OrderReportInfo {
    /**
     * 
     * @type {number}
     * @memberof OrderReportInfo
     */
    orderNum?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    customerCity?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    customerCountry?: string;
    /**
     * 
     * @type {ShippingType}
     * @memberof OrderReportInfo
     */
    shippingType?: ShippingType;
    /**
     * 
     * @type {number}
     * @memberof OrderReportInfo
     */
    numberOfTickets?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderReportInfo
     */
    totalPrice?: number;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderReportInfo
     */
    orderStatus?: OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    paymentType?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    stornoPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReportInfo
     */
    stornoReason?: string;
}

/**
 * Check if a given object implements the OrderReportInfo interface.
 */
export function instanceOfOrderReportInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderReportInfoFromJSON(json: any): OrderReportInfo {
    return OrderReportInfoFromJSONTyped(json, false);
}

export function OrderReportInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderReportInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNum': !exists(json, 'orderNum') ? undefined : json['orderNum'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerCity': !exists(json, 'customerCity') ? undefined : json['customerCity'],
        'customerCountry': !exists(json, 'customerCountry') ? undefined : json['customerCountry'],
        'shippingType': !exists(json, 'shippingType') ? undefined : ShippingTypeFromJSON(json['shippingType']),
        'numberOfTickets': !exists(json, 'numberOfTickets') ? undefined : json['numberOfTickets'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : OrderStatusFromJSON(json['orderStatus']),
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'stornoPerson': !exists(json, 'stornoPerson') ? undefined : json['stornoPerson'],
        'stornoReason': !exists(json, 'stornoReason') ? undefined : json['stornoReason'],
    };
}

export function OrderReportInfoToJSON(value?: OrderReportInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNum': value.orderNum,
        'customerName': value.customerName,
        'customerCity': value.customerCity,
        'customerCountry': value.customerCountry,
        'shippingType': ShippingTypeToJSON(value.shippingType),
        'numberOfTickets': value.numberOfTickets,
        'totalPrice': value.totalPrice,
        'orderStatus': OrderStatusToJSON(value.orderStatus),
        'paymentType': value.paymentType,
        'stornoPerson': value.stornoPerson,
        'stornoReason': value.stornoReason,
    };
}

