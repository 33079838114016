/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateScanFlagRequest
 */
export interface UpdateScanFlagRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateScanFlagRequest
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScanFlagRequest
     */
    scanEnabled?: boolean;
}

/**
 * Check if a given object implements the UpdateScanFlagRequest interface.
 */
export function instanceOfUpdateScanFlagRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateScanFlagRequestFromJSON(json: any): UpdateScanFlagRequest {
    return UpdateScanFlagRequestFromJSONTyped(json, false);
}

export function UpdateScanFlagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScanFlagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'scanEnabled': !exists(json, 'scanEnabled') ? undefined : json['scanEnabled'],
    };
}

export function UpdateScanFlagRequestToJSON(value?: UpdateScanFlagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'scanEnabled': value.scanEnabled,
    };
}

