import * as React from "react";
import { Divider, Flex, FlexItem, Hint, HintBody, TextContent } from "@patternfly/react-core";
import { EventInfo } from "@src/api";
import * as Util from "@app/utils/nvl"

interface IWizardProps {
  event : EventInfo
}

const Step1Overview: React.FunctionComponent<IWizardProps> = (props: IWizardProps) => {

  const renderHintText = () => {
    if(props.event.shippingEnabled && Util.nvlN(props.event.ticketAmountFreeShipping) > 0) {
      return (<>
        <Hint>
          <HintBody>
            Tipp: Postversand ab <b>{props.event.ticketAmountFreeShipping}</b> Tickets versandkostenfrei!
          </HintBody>
        </Hint>
      </>);

    }

    return (<></>);
  }

  return (<>
    <TextContent>
      <div><b>Veranstalter:</b> { props.event.operator?.operatorName}</div>
      <div><b>Ort:</b> { props.event.eventLocation } </div>
      <div><b>Datum:</b> {props.event.eventDate?.toLocaleDateString() } </div>
    </TextContent>

    <br/>
    <Divider />
    <br/>

    <Flex justifyContent={{ default : 'justifyContentCenter' }} width="100%">
      <FlexItem>
        <div><b>Preis / Ticket:</b> &euro; {Util.nvlN(props.event.ticketPrice).toFixed(2)} </div>
      </FlexItem>
    </Flex>

    <br/>
    <Divider />
    <br/>

    { renderHintText() }
  </>)
}

export { Step1Overview }
