/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeStatusRequest,
  Order,
  OrderStatus,
  PriceResult,
  WebShopOrder,
} from '../models';
import {
    ChangeStatusRequestFromJSON,
    ChangeStatusRequestToJSON,
    OrderFromJSON,
    OrderToJSON,
    OrderStatusFromJSON,
    OrderStatusToJSON,
    PriceResultFromJSON,
    PriceResultToJSON,
    WebShopOrderFromJSON,
    WebShopOrderToJSON,
} from '../models';

export interface CalculatePriceRequest {
    event?: number;
    shippingType?: string;
    ticketAmount?: number;
}

export interface ChangeStatusOperationRequest {
    changeStatusRequest?: ChangeStatusRequest;
}

export interface GetOrderRequest {
    key: string;
}

export interface GetOrdersRequest {
    status?: Array<OrderStatus>;
}

export interface StoreOrderRequest {
    webShopOrder?: WebShopOrder;
}

export interface UpdateOrderRequest {
    order?: Order;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    async calculatePriceRaw(requestParameters: CalculatePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PriceResult>> {
        const queryParameters: any = {};

        if (requestParameters.event !== undefined) {
            queryParameters['event'] = requestParameters.event;
        }

        if (requestParameters.shippingType !== undefined) {
            queryParameters['shippingType'] = requestParameters.shippingType;
        }

        if (requestParameters.ticketAmount !== undefined) {
            queryParameters['ticketAmount'] = requestParameters.ticketAmount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/calcPrice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceResultFromJSON(jsonValue));
    }

    /**
     */
    async calculatePrice(requestParameters: CalculatePriceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PriceResult> {
        const response = await this.calculatePriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeStatusRaw(requestParameters: ChangeStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/changeStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusRequestToJSON(requestParameters.changeStatusRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeStatus(requestParameters: ChangeStatusOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeStatusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getOrderRaw(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/get/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     */
    async getOrder(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
        const response = await this.getOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrdersRaw(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Order>>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/getByStatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     */
    async getOrders(requestParameters: GetOrdersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Order>> {
        const response = await this.getOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async storeOrderRaw(requestParameters: StoreOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/orders/store`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebShopOrderToJSON(requestParameters.webShopOrder),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async storeOrder(requestParameters: StoreOrderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.storeOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrderRaw(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderToJSON(requestParameters.order),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrder(requestParameters: UpdateOrderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderRaw(requestParameters, initOverrides);
    }

}
