/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShippingType } from './ShippingType';
import {
    ShippingTypeFromJSON,
    ShippingTypeFromJSONTyped,
    ShippingTypeToJSON,
} from './ShippingType';

/**
 * 
 * @export
 * @interface WebShopOrder
 */
export interface WebShopOrder {
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    orderKey?: string;
    /**
     * 
     * @type {number}
     * @memberof WebShopOrder
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerMail?: string;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerCity?: string;
    /**
     * 
     * @type {string}
     * @memberof WebShopOrder
     */
    customerCountry?: string;
    /**
     * 
     * @type {ShippingType}
     * @memberof WebShopOrder
     */
    shippingType?: ShippingType;
    /**
     * 
     * @type {number}
     * @memberof WebShopOrder
     */
    numberOfTickets?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebShopOrder
     */
    agbChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebShopOrder
     */
    dsgvoChecked?: boolean;
}

/**
 * Check if a given object implements the WebShopOrder interface.
 */
export function instanceOfWebShopOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebShopOrderFromJSON(json: any): WebShopOrder {
    return WebShopOrderFromJSONTyped(json, false);
}

export function WebShopOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebShopOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderKey': !exists(json, 'orderKey') ? undefined : json['orderKey'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerMail': !exists(json, 'customerMail') ? undefined : json['customerMail'],
        'customerAddress': !exists(json, 'customerAddress') ? undefined : json['customerAddress'],
        'customerPostcode': !exists(json, 'customerPostcode') ? undefined : json['customerPostcode'],
        'customerCity': !exists(json, 'customerCity') ? undefined : json['customerCity'],
        'customerCountry': !exists(json, 'customerCountry') ? undefined : json['customerCountry'],
        'shippingType': !exists(json, 'shippingType') ? undefined : ShippingTypeFromJSON(json['shippingType']),
        'numberOfTickets': !exists(json, 'numberOfTickets') ? undefined : json['numberOfTickets'],
        'agbChecked': !exists(json, 'agbChecked') ? undefined : json['agbChecked'],
        'dsgvoChecked': !exists(json, 'dsgvoChecked') ? undefined : json['dsgvoChecked'],
    };
}

export function WebShopOrderToJSON(value?: WebShopOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderKey': value.orderKey,
        'eventId': value.eventId,
        'customerName': value.customerName,
        'customerMail': value.customerMail,
        'customerAddress': value.customerAddress,
        'customerPostcode': value.customerPostcode,
        'customerCity': value.customerCity,
        'customerCountry': value.customerCountry,
        'shippingType': ShippingTypeToJSON(value.shippingType),
        'numberOfTickets': value.numberOfTickets,
        'agbChecked': value.agbChecked,
        'dsgvoChecked': value.dsgvoChecked,
    };
}

