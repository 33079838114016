import React, { useContext, useState } from "react";
import { Order, OrderApi, ShippingType } from "@src/api";
import { OrderStatus } from "@src/api/models/OrderStatus";
import {
  Card,
  PageSection,
  Toolbar,
  ToolbarContent,
  Label,
  Title,
  Bullseye,
  EmptyState,
  EmptyStateVariant,
  EmptyStateHeader,
  EmptyStateIcon,
  Button,
  OverflowMenu,
  OverflowMenuContent,
  OverflowMenuGroup, OverflowMenuItem, OverflowMenuControl
} from "@patternfly/react-core";

import { Table, Thead, Tr, Th, Tbody, Td, ActionsColumn, IAction } from "@patternfly/react-table";
import { CheckCircleIcon, CheckIcon, PencilAltIcon } from "@patternfly/react-icons";
import { useNavigate } from "react-router";
import { SystemContext } from "@app/AppContext/SystemContext";

interface IProps {
  title: string;
  activeTabIndex : string | number;
  orders: Order[];
  setStatus : (orderKey : string | undefined, toStatus : OrderStatus) => void;
}

export const OrderTable: React.FunctionComponent<IProps> = (props) => {

  const navigate = useNavigate()
  const systemContext = useContext(SystemContext)
  const [actionBtnDisabled, setActionBtnDisabled] = useState(false)

  const tableToolbar = (
    <Toolbar usePageInsets id="compact-toolbar">
      <ToolbarContent>
        {/*<ToolbarItem>*/}
        {/*  <Select*/}
        {/*    id="select-example"*/}
        {/*    aria-label="Select Input"*/}
        {/*    toggle={(toggleRef: React.Ref<MenuToggleElement>) => (*/}
        {/*      <MenuToggle ref={toggleRef} onClick={() => setIsSelectOpen(!isSelectOpen)} isExpanded={isSelectOpen}>*/}
        {/*        <FilterIcon /> Status*/}
        {/*      </MenuToggle>*/}
        {/*    )}*/}
        {/*    isOpen={isSelectOpen}*/}
        {/*    onOpenChange={(isOpen) => setIsSelectOpen(isOpen)}*/}
        {/*    onSelect={() => setIsSelectOpen(!isSelectOpen)}*/}
        {/*  >*/}
        {/*    {[*/}
        {/*      <SelectOption key={0} value="Debug">Debug</SelectOption>,*/}
        {/*      <SelectOption key={1} value="Info">Info</SelectOption>,*/}
        {/*      <SelectOption key={2} value="Warn">Warn</SelectOption>,*/}
        {/*    ]}*/}
        {/*  </Select>*/}
        {/*</ToolbarItem>*/}
        {/*<ToolbarItem>*/}
        {/*  <SearchInput*/}
        {/*    placeholder="Find by name"*/}
        {/*    onChange={(_event, value) => onSearchChange(value)}*/}
        {/*    // onClear={() => onChange('')}*/}
        {/*  />*/}
        {/*</ToolbarItem>*/}
      </ToolbarContent>
    </Toolbar>
  );

  const renderStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.New:
        return <Label color="blue">Neu</Label>;
      case OrderStatus.Payed:
        return <Label color="orange">Bezahlt</Label>;
      case OrderStatus.Done:
        return <Label color="green">Abgeschlossen</Label>;
      case OrderStatus.Storno:
        return <Label color="grey">Storniert</Label>;
    }
    return "";
  };

  const headers = ["BestellNr", "Veranstaltung", "Name", "Versandart", "Anzahl", "Gesamtpreis", "Bestellzeit", "Status", ""];

  const renderShippingType = (shippingType: ShippingType | undefined) => {
    switch (shippingType) {
      case "PRINT_AT_HOME":
        return <Label color="green">Print@Home</Label>;
      case "POST":
        return <Label color="orange">Post</Label>;
    }

    return undefined;
  }

  const renderOverflowActions = (currentRow : Order): IAction[] => {
    const actions : IAction[] = []

    switch (currentRow.orderStatus) {
      case OrderStatus.New:
        actions.push({ title: 'bezahlt', color: 'green', onClick: () => changeStatus(currentRow.orderKey, OrderStatus.Payed)})
        break;

      case OrderStatus.Payed:
        actions.push({ title: 'abschließen', onClick: () => changeStatus(currentRow.orderKey, OrderStatus.Done)})
        break;
    }

    actions.push({ title: 'bearbeiten', onClick: () => navigate(`./${currentRow.orderKey}`, {state : {tabIndex : props.activeTabIndex}})})
    return actions;
  }

  const renderMainAction = (currentRow : Order): JSX.Element => {
    switch (currentRow.orderStatus) {
      case OrderStatus.New:
      return (
        <Button variant="secondary" size="sm" disabled={actionBtnDisabled} isLoading={actionBtnDisabled} onClick={() => changeStatus(currentRow.orderKey, OrderStatus.Payed)}>
          <CheckCircleIcon /> bezahlt
        </Button>
      );

      case OrderStatus.Payed:
        return (
          <Button variant="secondary" size="sm" disabled={actionBtnDisabled} isLoading={actionBtnDisabled} onClick={() => changeStatus(currentRow.orderKey, OrderStatus.Done)}>
            <CheckCircleIcon /> abgeschlossen
          </Button>
        );
    }

    return <></>
  }

  const changeStatus = (orderKey : string | undefined, toStatus : OrderStatus) => {
    if(orderKey === undefined) return;

    setActionBtnDisabled(true);

    (new OrderApi(systemContext.apiConfig)).changeStatus({changeStatusRequest : {orderKey : orderKey, toStatus : toStatus}})
      .then(() => {
        props.setStatus(orderKey, toStatus);
        setActionBtnDisabled(false);
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  const renderBgColor = (currentRow : Order) => {
    const boundaryDate = new Date();
    boundaryDate.setDate(boundaryDate.getDate() - 10);

    const orderDate = currentRow.creationDate || new Date()

    if(currentRow.orderStatus == OrderStatus.New && orderDate < boundaryDate) {
      return "#FFEAE9";
    } else {
      return "#FFFFFF"
    }
  }

  return (
    <React.Fragment>
      <PageSection>
        <Title headingLevel="h1">{props.title}</Title>
      </PageSection>
      <PageSection isFilled>
        <Card>
          {tableToolbar}
          <Table variant="compact" aria-label="Compact Table" isStriped>
            <Thead>
              <Tr>
                <Th key={0}>{headers[0]}</Th>
                <Th key={1}>{headers[1]}</Th>
                <Th key={2}>{headers[2]}</Th>
                <Th key={3}>{headers[3]}</Th>
                <Th key={4}>{headers[4]}</Th>
                <Th key={5}>{headers[5]}</Th>
                <Th key={6}>{headers[6]}</Th>
                <Th key={7}>{headers[7]}</Th>
                <Th key={8}>{headers[8]}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.orders.length > 0 && props.orders.map((row, rowIndex) => (
                <Tr key={rowIndex} onDoubleClick={ () => navigate(`./${row.orderKey}`, {state : {tabIndex : props.activeTabIndex}}) } isClickable
                style={ { backgroundColor : renderBgColor(row) } }
                >
                  <>
                    <Td dataLabel={headers[0]}>TickR#{row.orderNum?.toString().padStart(4, '0')}</Td>
                    <Td dataLabel={headers[1]}>{row.event?.eventName}</Td>
                    <Td dataLabel={headers[2]}>{row.customerName} ({row.customerCity})</Td>
                    <Td dataLabel={headers[3]}>{renderShippingType(row.shippingType)}</Td>
                    <Td dataLabel={headers[4]}>{row.numberOfTickets}</Td>
                    <Td dataLabel={headers[5]}>&euro; {row.totalPrice?.toFixed(2)}</Td>
                    <Td dataLabel={headers[6]}>{row.creationDate?.toLocaleString()}</Td>
                    <Td dataLabel={headers[7]}>{renderStatus(row.orderStatus || OrderStatus.New)}</Td>
                    <Td dataLabel={headers[8]} isActionCell>
                      <OverflowMenu breakpoint="lg">
                        <OverflowMenuContent>
                          <OverflowMenuGroup groupType="button">
                            <OverflowMenuItem>
                              { renderMainAction(row) }
                            </OverflowMenuItem>
                            <OverflowMenuItem>
                              <Button variant="plain" size="sm" onClick={() => navigate(`./${row.orderKey}`, {state : {tabIndex : props.activeTabIndex}})}>
                                <PencilAltIcon/>
                              </Button>
                            </OverflowMenuItem>
                          </OverflowMenuGroup>
                        </OverflowMenuContent>
                        <OverflowMenuControl>
                          <ActionsColumn items={renderOverflowActions(row)} />
                        </OverflowMenuControl>
                      </OverflowMenu>
                    </Td>
                  </>
                </Tr>
              ))}

              {props.orders.length <= 0 &&
                <Tr key={"no_data"}>
                    <Td colSpan={8}>
                      <Bullseye>
                        <EmptyState variant={EmptyStateVariant.sm}>
                          <EmptyStateHeader
                            icon={<EmptyStateIcon icon={CheckIcon} />}
                            titleText="Keine Bestellungen"
                            headingLevel="h2"
                          />
                        </EmptyState>
                      </Bullseye>
                    </Td>
                </Tr>
              }

            </Tbody>
          </Table>
        </Card>
      </PageSection>
    </React.Fragment>
  );
};