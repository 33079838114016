import React, { useContext, useEffect, useState } from "react";
import { SoldTicketChart } from "@app/Admin/Dashboard/SoldTicketChart";
import {
  Card,
  CardBody,
  CardTitle,
  DescriptionList, DescriptionListDescription,
  DescriptionListGroup, DescriptionListTerm, Text, TextVariants
} from "@patternfly/react-core";
import { SystemContext } from "@app/AppContext/SystemContext";
import { DashboardApi, TicketCountResponse } from "@src/api";
import { ContentLoading } from "@app/Page/ContentLoading";

export const Dashboard: React.FunctionComponent = () => {

  const systemContext = useContext(SystemContext);
  const [statistics, setStatistics] = useState<TicketCountResponse[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    while(!systemContext.session?.authenticationToken) { /* wait for context being set in login */ console.log("dashboard loop"); }

    (new DashboardApi(systemContext.apiConfig)).getTicketCountStatisticsData()
      .then(res => {
        setStatistics(res)
        setLoading(false)
      })
      .catch(err => { systemContext.addNotification(err.toString(), "danger", new Date().getTime()) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  if(loading) {
    return (<>
      <ContentLoading />
    </>)
  }

  return (<>
    <Card isFlat isRounded isLarge>
      <CardTitle>Herzlich Willkommen, { systemContext.session?.displayName }!</CardTitle>
      <CardBody>
        <DescriptionList columnModifier={{ default: '3Col' }}>
          {
            statistics.map( (elem, idx) => {
              return (
                <DescriptionListGroup key={`Group${idx}`}>
                  <DescriptionListTerm key={`Term${idx}`}> { elem.eventName }</DescriptionListTerm>
                  <DescriptionListDescription key={`Desc${idx}`}>
                    <SoldTicketChart
                      key={`Chart${idx}`}
                      name={`Chart${idx}`}
                      title={elem.eventName || ""}
                      numberPayed={(elem.sumPayed || 0) + (elem.sumDone || 0)}
                      numberNotPayed={elem.sumNew || 0}
                      maxAvailable={elem.maxAvailable || 0} />
                  </DescriptionListDescription>
                </DescriptionListGroup>
                )
            })
          }
        </DescriptionList>
      </CardBody>
    </Card>
  </>);
}