import React, { useContext, useEffect } from "react";
import { Configuration, SessionApi } from "@src/api";
import { SystemContext } from "@app/AppContext/SystemContext";

export const SessionHandler : React.FunctionComponent = () => {

  const systemContext = useContext(SystemContext);

  useEffect(() => {
    const interval = setInterval(() => {
      // verify session
      (new SessionApi(systemContext.apiConfig).verify()
        .then(() => {
          // verification OK
        })
        .catch(() => {
          // 401 Unauthorized => session not valid
          systemContext.setSession(undefined)
        }))
    }, 120000); // 2min

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ /* empty */ ]);

  return (<>

  </>);
}