/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventFilterTypes,
  EventInfo,
  UpdateScanFlagRequest,
} from '../models';
import {
    EventFilterTypesFromJSON,
    EventFilterTypesToJSON,
    EventInfoFromJSON,
    EventInfoToJSON,
    UpdateScanFlagRequestFromJSON,
    UpdateScanFlagRequestToJSON,
} from '../models';

export interface DoAnonymizationRequest {
    eventId?: number;
}

export interface GetEventRequest {
    id: number;
}

export interface GetEventListRequest {
    filter?: EventFilterTypes;
}

export interface TriggerEventReminderMailRequest {
    body?: number;
}

export interface UpdateEventRequest {
    eventInfo?: EventInfo;
}

export interface UpdateScanFlagOperationRequest {
    updateScanFlagRequest?: UpdateScanFlagRequest;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     */
    async doAnonymizationRaw(requestParameters: DoAnonymizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/anonymization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async doAnonymization(requestParameters: DoAnonymizationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.doAnonymizationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPublishedEventsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EventInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/events/getPublishedEventList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventInfoFromJSON));
    }

    /**
     */
    async getAllPublishedEvents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EventInfo>> {
        const response = await this.getAllPublishedEventsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEventRaw(requestParameters: GetEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/events/get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventInfoFromJSON(jsonValue));
    }

    /**
     */
    async getEvent(requestParameters: GetEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventInfo> {
        const response = await this.getEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEventListRaw(requestParameters: GetEventListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EventInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/eventList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventInfoFromJSON));
    }

    /**
     */
    async getEventList(requestParameters: GetEventListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EventInfo>> {
        const response = await this.getEventListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async triggerEventReminderMailRaw(requestParameters: TriggerEventReminderMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/send/reminder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async triggerEventReminderMail(requestParameters: TriggerEventReminderMailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerEventReminderMailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateEventRaw(requestParameters: UpdateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventInfoToJSON(requestParameters.eventInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateEvent(requestParameters: UpdateEventRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateEventRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateScanFlagRaw(requestParameters: UpdateScanFlagOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/scan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScanFlagRequestToJSON(requestParameters.updateScanFlagRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateScanFlag(requestParameters: UpdateScanFlagOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateScanFlagRaw(requestParameters, initOverrides);
    }

}
