import * as React from "react";
import {useEffect} from "react";
import {Form, FormGroup, Radio, TextInput, ValidatedOptions} from "@patternfly/react-core";
import {ShippingType, WebShopOrder} from "@src/api";
import * as Util from "@app/utils/nvl"

interface IWizardProps {
  setValidity : React.Dispatch<React.SetStateAction<boolean>>
  order : WebShopOrder
  setOrder : React.Dispatch<React.SetStateAction<WebShopOrder>>
  maxTicketsToBuy : number
  printAtHomeEnabled : boolean
  postEnabled : boolean
}
const Step2Tickets : React.FunctionComponent<IWizardProps> = (props: IWizardProps) => {

  const getShippingTypeValidationStatus = () => {
    return props.order.shippingType != null && props.order.shippingType != ShippingType.None
      ? ValidatedOptions.success
      : ValidatedOptions.error
  }

  const getNumTicketsValidationStatus = () => {
    return (Util.nvlN(props.order.numberOfTickets) > 0 && Util.nvlN(props.order.numberOfTickets) <= props.maxTicketsToBuy)
      ? ValidatedOptions.success
      : ValidatedOptions.error
  }

  const [numberTicketsValidated, setNumberTicketsValidated] = React.useState<ValidatedOptions>(getNumTicketsValidationStatus());

  useEffect(() =>  {
    setNumberTicketsValidated(getNumTicketsValidationStatus())
    props.setValidity (getShippingTypeValidationStatus() == ValidatedOptions.success && getNumTicketsValidationStatus() == ValidatedOptions.success)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const renderShippingTypes = () =>  {
    const form : JSX.Element[] = []

    if(props.printAtHomeEnabled) {
      form.push(<>
        <Radio key={1} isChecked={props.order.shippingType == ShippingType.PrintAtHome}
               name="shippingType" label="Print@Home" id="shipping-printAtHome"
               onClick={() => props.setOrder( (old) => ({ ...old, shippingType : ShippingType.PrintAtHome }) )}
        /></>)
    }

    if(props.postEnabled) {
      form.push(<>
        <Radio key={2} isChecked={props.order.shippingType == ShippingType.Post}
               name="shippingType" label="Postversand" id="shipping-post"
               onClick={() => props.setOrder( (old) => ({ ...old, shippingType : ShippingType.Post }) )}
      /></>)
    }

    return form
  }

  return (<>
    <Form key={2} onSubmit={e => e.preventDefault()}>
      <FormGroup isRequired label="Anzahl der Tickets" fieldId="numberTickets"  labelInfo={"mindestens 1, maximal " + props.maxTicketsToBuy + "!"}>
        <TextInput isRequired type="number" min={1} max={props.maxTicketsToBuy} value={props.order.numberOfTickets}
                   id="numberTickets" name="numberTickets" aria-describedby="numberTickets-helper" validated={numberTicketsValidated}
                   onChange={(e, val) => props.setOrder((old) => ({ ...old, numberOfTickets : parseInt(val)}))} />
      </FormGroup>

      <FormGroup isRequired role="radiogroup" isInline fieldId="shippingType" label="Versandart..." >
        { renderShippingTypes() }
      </FormGroup>
    </Form>
  </>)
}

export { Step2Tickets }
