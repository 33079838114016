import React from "react";
import { EventInfo } from "@src/api";
import { ProgressStep, ProgressStepper } from "@patternfly/react-core";

interface IProps {
  event: EventInfo;
}

export const EventProgress: React.FunctionComponent<IProps> = (props) => {

  type StepperStatus = "success" | "default" | "info" | "pending" | "warning" | "danger";
  const checkEventPublished = () : StepperStatus => {
    return "success";
  }
  const checkOrderingStarted = () : StepperStatus => {
    return props.event.orderingStarted ? "success" : "pending"
  }
  const checkOrderingStopped = () : StepperStatus => {
    return props.event.orderingExpired ? "success" : "pending"
  }
  const checkScanEnabled = () : StepperStatus => {
    if(props.event.scanEnabled) {
      return "success";
    }

    if(props.event.orderingExpired) {
      return "warning";
    }

    return "pending";
  }
  const checkReminderMailSent = () : StepperStatus => {
    return props.event.mailEventReminderSent ? "success" : "pending"
  }

  const checkGdprValid = () : StepperStatus => {
    if(props.event.gdprValid) {
      return "success";
    }

    if(props.event.eventInPast) {
      return "danger";
    }

    return "pending";
  }

  return (<>
    <ProgressStepper isCenterAligned={true} aria-label="progress stepper for event">
      <ProgressStep
        id="event-progress-step1"
        aria-label="step to verify if event created and published successfully"
        description="Veranstaltung vollständig angelegt und veröffentlicht."
        variant={ checkEventPublished() }
      >
        veröffentlicht
      </ProgressStep>

      <ProgressStep
        id="event-progress-step2"
        aria-label="step to verify if ordering in webshop has started"
        description="WebShop-Verkauf hat begonnen"
        variant={ checkOrderingStarted() }
      >
        Verkauf gestartet
      </ProgressStep>

      <ProgressStep
        id="event-progress-step3"
        aria-label="step to verify if ordering in webshop has stopped"
        description="WebShop-Verkauf nicht mehr möglich"
        variant={ checkOrderingStopped() }
      >
        Verkauf gesperrt
      </ProgressStep>

      <ProgressStep
        id="event-progress-step4"
        aria-label="step to verify if reminder mail has been sent"
        description="Erinnerungsmail mit Ticketlink wurde für alle Bestellungen gesendet"
        variant={ checkReminderMailSent() }
      >
        Erinnerungsmail gesendet
      </ProgressStep>

      <ProgressStep
        id="event-progress-step5"
        aria-label="step to verify if scan possibility is enabled"
        description="Ticket-Scan für die Veranstaltung aktiviert"
        variant={ checkScanEnabled() }
      >
        Scan freigeschaltet
      </ProgressStep>

      <ProgressStep
        id="event-progress-step6"
        aria-label="step to check if data is anonymized to fulfill regulations of the GDPR"
        description="persönlichen Daten wurden nach der Veranstaltung unkenntlich gemacht"
        variant={ checkGdprValid() }
      >
        DSGVO anonymisiert
      </ProgressStep>
    </ProgressStepper>
  </>)
}