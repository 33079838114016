/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Operator
 */
export interface Operator {
    /**
     * 
     * @type {number}
     * @memberof Operator
     */
    operatorId?: number;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorContactPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorContactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorContactMail?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorPostCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorCity?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorBankName?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorBankIban?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorBankBic?: string;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    operatorLogoLocation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Operator
     */
    operatorActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Operator
     */
    modificationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof Operator
     */
    modificationDate?: Date;
}

/**
 * Check if a given object implements the Operator interface.
 */
export function instanceOfOperator(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OperatorFromJSON(json: any): Operator {
    return OperatorFromJSONTyped(json, false);
}

export function OperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Operator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'operatorName': !exists(json, 'operatorName') ? undefined : json['operatorName'],
        'operatorContactPerson': !exists(json, 'operatorContactPerson') ? undefined : json['operatorContactPerson'],
        'operatorContactPhone': !exists(json, 'operatorContactPhone') ? undefined : json['operatorContactPhone'],
        'operatorContactMail': !exists(json, 'operatorContactMail') ? undefined : json['operatorContactMail'],
        'operatorAddress': !exists(json, 'operatorAddress') ? undefined : json['operatorAddress'],
        'operatorPostCode': !exists(json, 'operatorPostCode') ? undefined : json['operatorPostCode'],
        'operatorCity': !exists(json, 'operatorCity') ? undefined : json['operatorCity'],
        'operatorBankName': !exists(json, 'operatorBankName') ? undefined : json['operatorBankName'],
        'operatorBankIban': !exists(json, 'operatorBankIban') ? undefined : json['operatorBankIban'],
        'operatorBankBic': !exists(json, 'operatorBankBic') ? undefined : json['operatorBankBic'],
        'operatorLogoLocation': !exists(json, 'operatorLogoLocation') ? undefined : json['operatorLogoLocation'],
        'operatorActive': !exists(json, 'operatorActive') ? undefined : json['operatorActive'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
    };
}

export function OperatorToJSON(value?: Operator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operatorId': value.operatorId,
        'operatorName': value.operatorName,
        'operatorContactPerson': value.operatorContactPerson,
        'operatorContactPhone': value.operatorContactPhone,
        'operatorContactMail': value.operatorContactMail,
        'operatorAddress': value.operatorAddress,
        'operatorPostCode': value.operatorPostCode,
        'operatorCity': value.operatorCity,
        'operatorBankName': value.operatorBankName,
        'operatorBankIban': value.operatorBankIban,
        'operatorBankBic': value.operatorBankBic,
        'operatorLogoLocation': value.operatorLogoLocation,
        'operatorActive': value.operatorActive,
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
    };
}

