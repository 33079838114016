import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { SystemContext } from "@app/AppContext/SystemContext";
import { ContentLoading } from "@app/Page/ContentLoading";
import { EventApi, EventInfo } from "@src/api";
import {
  ActionGroup,
  Button,
  Card,
  CardBody, Checkbox,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup, DescriptionListTerm,
  Form,
  FormGroup,
  Label, List, ListItem, Modal, ModalVariant,
  PageSection,
  TextInput,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem
} from "@patternfly/react-core";

import * as Util from "@app/utils/"
import {
  BellIcon,
  CheckCircleIcon, CheckIcon,
  CircleNotchIcon, CrossIcon,
  TimesCircleIcon, TrashIcon
} from "@patternfly/react-icons";
import { useLocation } from "react-router-dom";
import { DateTimePicker } from "@app/Admin/Events/DateTimePicker";
import ErrorFactory from "@app/Page/Error";
import { EventProgress } from "@app/Admin/Events/EventProgress";

export const EventDetail: React.FunctionComponent = () => {
  const { key } = useParams();
  const systemContext = useContext(SystemContext)
  const [isLoading, setLoading] = React.useState(true);
  const [event, setEvent] = React.useState<EventInfo>({} as EventInfo);
  const [isEventReminderModalOpen, setEventReminderModalOpen] = React.useState(false);
  const [isGdprModalOpen, setGdprModalOpen] = React.useState(false);

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    const param = parseInt(key || "-1");

    (new EventApi().getEvent( { id : param })
      .then(res => {
        setEvent(res)
        setLoading(false)
      })
      .catch(err => navigate(ErrorFactory.generatePath(err, "FinalizeEvent"))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  const triggerEventReminderMail = () => {
    (new EventApi(systemContext.apiConfig)).triggerEventReminderMail({body : event?.eventId })
      .then(() => {
        setEvent(old => ( {...old, mailEventReminderSent : true} ))
        setEventReminderModalOpen(false)

        setTimeout(() => {
          systemContext.addNotification("E-Mails werden gesendet...", "success", new Date().getTime())
        }, 1500)
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  const doGdprAnonymization = () => {
    (new EventApi(systemContext.apiConfig)).doAnonymization( {eventId : event.eventId })
      .then(() => {
        setEvent(old => ( {...old, gdprValid : true} ))
        setGdprModalOpen(false)
      })
      .catch(err => navigate(ErrorFactory.generatePath(err, "FinalizeEvent / doAnonymization")))
  }

  if(isLoading) {
    return <ContentLoading />
  }

  const renderEventReminderButton = () => {
    return (<>
      <Button variant="secondary"
              isDisabled={event?.mailEventReminderSent || !event.orderingExpired }
              onClick={() => setEventReminderModalOpen(!isEventReminderModalOpen)}>
        { event?.mailEventReminderSent
          ? <><CheckIcon /> Eventerinnerung gesendet</>
          : <><BellIcon /> Eventerinnerung senden</> }
      </Button>
    </>)
  }

  const renderReportDownloadButton = () => {
    return(<>
      <Button variant="secondary" onClick={() => navigate("./report")}>
        Eventbericht erstellen
      </Button>
    </>)
  }

  const renderScanStatus = () => {
    if(event?.scanEnabled) {
      return <Label color={"green"} icon={<CheckCircleIcon />}>Scan freigegeben</Label>
    } else {
      return <Label color={"red"} icon={<TimesCircleIcon />}>Scan nicht freigegeben</Label>
    }
  }

  const renderScanStatusAction = () =>  {
    if(event?.scanEnabled) {
      return (
        <Button variant="warning" size="sm" onClick={() => sendScanStatusChangeRequest(false)}>
          <TimesCircleIcon/> Scan deaktivieren
        </Button>
      );
    } else {
      return (
        <Button variant="primary" size="sm" onClick={() => sendScanStatusChangeRequest(true)}>
          <CheckCircleIcon/> Scan aktivieren
        </Button>
      );
    }
  }

  const sendScanStatusChangeRequest = (isEnabled : boolean) => {
    (new EventApi(systemContext.apiConfig))
      .updateScanFlag({updateScanFlagRequest : { eventId : event?.eventId, scanEnabled : isEnabled }})
      .then(() => setEvent(old => ( {...old, scanEnabled : isEnabled} )))
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()));
  }

  const renderGdprStatus = () => {
    if(event?.gdprValid) {
      return <Label color={"green"} icon={<CheckCircleIcon />}>Danke, Daten wurden anonymisiert!</Label>
    } else if(!event?.eventInPast) {
      return <Label color={"grey"} icon={<CircleNotchIcon />}>Nein - erst nach Veranstaltung notwendig...</Label>
    }

    return (<>
      <Button variant="danger" ouiaId="Danger" onClick={() => setGdprModalOpen(true)}>
        Daten lt. DSGVO jetzt anonymisieren
      </Button>
    </>)
  }

  const submitUpdateEvent = () => {
    (new EventApi(systemContext.apiConfig)).updateEvent({eventInfo : event})
      .then(() => {
        systemContext.addNotification("erfolgreich gespeichert!", "success", new Date().getTime())
      })
      .catch(err => systemContext.addNotification(err.toString(), "danger", new Date().getTime()))
  }

  return (<>
    <PageSection isFilled>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <Title headingLevel="h1">Veranstaltung bearbeiten</Title>
          </ToolbarItem>

          <ToolbarItem align={{default : "alignRight"}}> { renderEventReminderButton() } </ToolbarItem>
          <ToolbarItem> { renderReportDownloadButton() } </ToolbarItem>

          <ToolbarItem variant="separator"></ToolbarItem>

          <ToolbarItem>
            <Button variant="link" onClick={() => navigate("/eventadmin/manage/events", {state : {tabIndex : location.state?.tabIndex}})} >Abbrechen</Button>
          </ToolbarItem>
        </ToolbarContent>
      </Toolbar>

      <br />

      <DescriptionList columnModifier={{ lg: '3Col' }}>
        <Card component="div">
          <DescriptionListDescription>
            <DescriptionList columnModifier={{ lg: '2Col' }}>
              <DescriptionListGroup>
                <DescriptionListTerm>Ticket-Scan freischalten</DescriptionListTerm>
                <DescriptionListDescription> { renderScanStatus() }</DescriptionListDescription>
              </DescriptionListGroup>
              <DescriptionListGroup>
                <DescriptionListTerm>{}</DescriptionListTerm>
                <DescriptionListDescription> { renderScanStatusAction() }</DescriptionListDescription>
              </DescriptionListGroup>
            </DescriptionList>
          </DescriptionListDescription>
        </Card>

        <Card component="div">
          <DescriptionListTerm>Daten lt. DSGVO anonymisiert</DescriptionListTerm>
          <DescriptionListDescription> { renderGdprStatus() } </DescriptionListDescription>
        </Card>

        <Card component="div">
          <DescriptionListTerm>Bearbeitungsverlauf:</DescriptionListTerm>
          <DescriptionListDescription>
            <b>letzte Änderung:</b> {event?.modificationDate?.toLocaleString()} <br />
            <b>durch Benutzer:</b>: {event?.modificationUser || "unbekannt"}
          </DescriptionListDescription>
        </Card>
      </DescriptionList>

      <br />

      <Card isCompact isRounded>
        <CardBody>
          <Form>
            {/* Name */}
            <DescriptionList columnModifier={{ lg: '2Col' }}>
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <FormGroup label="Name der Veranstaltung" isRequired fieldId="name">
                    <TextInput
                      isRequired
                      type="text"
                      id="name"
                      name="name"
                      aria-describedby="event name"
                      value={event?.eventName}
                      onChange={(e, val) => setEvent(old => ( {...old, eventName : val} )) }
                      validated={Util.textValidation(event?.eventName, 3)}
                    />
                  </FormGroup>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* Location */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <FormGroup label="Ort der Veranstaltung" isRequired fieldId="location">
                    <TextInput
                      isRequired
                      type="text"
                      id="location"
                      name="location"
                      aria-describedby="event location"
                      value={event?.eventLocation}
                      onChange={(e, val) => setEvent(old => ( {...old, eventLocation : val} )) }
                      validated={Util.textValidation(event?.eventLocation, 3)}
                    />
                  </FormGroup>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* description */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <FormGroup label="Beschreibung" isRequired fieldId="description">
                    <TextInput
                      isRequired
                      type="text"
                      id="description"
                      name="description"
                      aria-describedby="event description"
                      value={event?.ticketDescription}
                      onChange={(e, val) => setEvent(old => ( {...old, ticketDescription : val} )) }
                      validated={Util.textValidation(event?.ticketDescription, 1)}
                    />
                  </FormGroup>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* date & max available tickets */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Datum & Uhrzeit der Veranstaltung" isRequired fieldId="date">
                          <DateTimePicker currentDate={event?.eventDate}  setDate={ (newDate) => setEvent(old => ( {...old, eventDate : newDate} )) }/>
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Maximale Anzahl an Tickets" isRequired fieldId="maxTickets">
                          <TextInput
                            isRequired
                            type="number"
                            min={0}
                            max={9999}
                            id="maxTickets"
                            name="maxTickets"
                            value={event?.maxAvailableTickets}
                            onChange={(e, val) => setEvent(old => ( {...old, maxAvailableTickets : parseInt(val)} ))}
                            validated={Util.numberValidation(event?.maxAvailableTickets, 0, 9999)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>


              {/* ticket price & free shipping*/}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Preis für ein Ticket" isRequired fieldId="ticketPrice">
                          <TextInput
                            isRequired
                            type="number"
                            min={0}
                            max={999}
                            step={0.5}
                            id="ticketPrice"
                            name="ticketPrice"
                            value={event?.ticketPrice}
                            onChange={(e, val) => setEvent(old => ( {...old, ticketPrice : parseFloat(val)} ))}
                            validated={Util.numberValidation(event?.ticketPrice, 0, 999)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Gratisversand ab" isRequired fieldId="ticketAmountFreeShipping">
                          <TextInput
                            isRequired
                            type="number"
                            min={0}
                            max={10}
                            id="ticketAmountFreeShipping"
                            name="ticketAmountFreeShipping"
                            value={event?.ticketAmountFreeShipping}
                            onChange={(e, val) => setEvent(old => ( {...old, ticketAmountFreeShipping : parseInt(val)} ))}
                            validated={Util.numberValidation(event?.ticketAmountFreeShipping, 0, 10)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* shipping costs & createTicketsForShipping*/}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Versandkosten" isRequired fieldId="shippingCosts">
                          <TextInput
                            isRequired
                            type="number"
                            min={0}
                            max={10}
                            step={0.5}
                            id="shippingCosts"
                            name="shippingCosts"
                            value={event?.shippingCosts}
                            onChange={(e, val) => setEvent(old => ( {...old, shippingCosts : parseFloat(val)} ))}
                            validated={Util.numberValidation(event?.shippingCosts, 0, 10)}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Postversand-Tickets generieren?" isRequired fieldId="createTicketsForShipping">
                          <Checkbox
                            isRequired
                            id="createTicketsForShipping"
                            name="createTicketsForShipping"
                            isChecked={event?.createTicketsForShipping}
                            onChange={(e, val) => setEvent(old => ( {...old, createTicketsForShipping : val} ))}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* print@home / post enabled */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Print@Home aktiviert" isRequired fieldId="printAtHomeEnabled">
                          <Checkbox
                            isRequired
                            id="printAtHomeEnabled"
                            name="printAtHomeEnabled"
                            isChecked={event?.printAtHomeEnabled}
                            onChange={(e, val) => setEvent(old => ( {...old, printAtHomeEnabled : val} ))}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Postversand aktiviert" isRequired fieldId="shippingEnabled">
                          <Checkbox
                            isRequired
                            id="shippingEnabled"
                            name="shippingEnabled"
                            isChecked={event?.shippingEnabled}
                            onChange={(e, val) => setEvent(old => ( {...old, shippingEnabled : val} ))}
                          />
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>

              {/* publishing dates */}
              <DescriptionListGroup>
                <DescriptionListDescription>
                  <DescriptionList columnModifier={{ lg: '2Col' }}>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Verkauf möglich ab" isRequired fieldId="startOrdering">
                          <DateTimePicker currentDate={event?.startPublishing}  setDate={ (newDate) => setEvent(old => ( {...old, startPublishing : newDate} )) }/>
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                      <DescriptionListDescription>
                        <FormGroup label="Verkauf möglich bis" isRequired fieldId="stopOrdering">
                          <DateTimePicker currentDate={event?.endPublishing}  setDate={ (newDate) => setEvent(old => ( {...old, endPublishing : newDate} )) }/>
                        </FormGroup>
                      </DescriptionListDescription>
                    </DescriptionListGroup>
                  </DescriptionList>
                </DescriptionListDescription>
              </DescriptionListGroup>
            </DescriptionList>
            <ActionGroup>
              <Button variant="primary" onClick={() => submitUpdateEvent()}>Speichern</Button>
            </ActionGroup>
          </Form>
        </CardBody>
      </Card>

      <br />
      <EventProgress event={event} />

    </PageSection>

    <Modal
      title="Eventerinnerung senden?"
      titleIconVariant={BellIcon}
      isOpen={isEventReminderModalOpen}
      onClose={() => setEventReminderModalOpen(false)}
      onEscapePress={() => setEventReminderModalOpen(false)}
      variant={ModalVariant.medium}
      actions={[
        <Button key="confirm" variant="primary" onClick={() => triggerEventReminderMail()}>
          Ja, Erinnerung an alle senden!
        </Button>
      ]}
      ouiaId="SendEventerinnerung"
    >
      Sind sie sicher, dass sie die Eventerinnerung an alle Kunden mit abgeschlossener Bestellungen per E-Mail versenden möchten?
      Um Spam zu vermeiden, ist diese Aktion nur einmalig möglich!
    </Modal>

    <Modal
      title="Daten lt. DSGVO anonymisieren"
      titleIconVariant={TrashIcon}
      isOpen={isGdprModalOpen}
      onClose={() => setGdprModalOpen(false)}
      onEscapePress={() => setGdprModalOpen(false)}
      variant={ModalVariant.medium}
      actions={[
        <Button key="confirm" variant="danger" onClick={() => doGdprAnonymization()}>
          Ja, alle Daten unkenntlich machen
        </Button>
      ]}
      ouiaId="doGdprAnonymization"
    >
      <p>
        Gemäß den Bestimmungen der Datenschutz-Grundverordnung (DSGVO) möchten wir Sie darauf hinweisen, dass personenbezogene Daten, die im Rahmen dieser Veranstaltung erfasst wurden, <b>nach deren Abschluss gemäß den gesetzlichen Vorgaben anonymisiert oder gelöscht werden müssen</b>.
        Die Einhaltung der Datenschutzvorschriften liegt uns am Herzen, und wir setzen alle erforderlichen Maßnahmen ein, um sicherzustellen, dass alle Daten gemäß den gesetzlichen Anforderungen gespeichert werden.
      </p>
      <br />
      <p>
        Um statistische Auswertungen nicht zu gefährden, werden nicht alle Daten gelöscht sondern nur die folgenden Informationen unkenntlich gemacht (mit &quot;xxx&quot; ersetzt).
        <List>
          <ListItem><b>Bestellung:</b> Kundenname, E-Mail-Adresse, Adresse, Hausnummer und Storno-Person</ListItem>
          <ListItem><b>Tickets:</b> Name des Eigentümers</ListItem>
        </List>
      </p>
      <br />
      <p><b>Dieser Schritt ist zwingend notwendig!</b></p>
      <p><b>Bitte bestätigen Sie, dass die anonymisierung jetzt durchgeführt werden soll:</b></p>
    </Modal>

  </>);
}