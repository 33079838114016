import * as React from 'react';
import {
  Bullseye,
  DescriptionList,
  EmptyState, EmptyStateBody,
  EmptyStateHeader,
  EmptyStateIcon,
  PageSection,
  Title
} from "@patternfly/react-core";
import { SingleEvent } from "@app/Webshop/Events/SingleEvent";
import { useContext, useEffect } from "react";
import { EventApi, EventInfo } from "@src/api";
import * as Util from "@app/utils/nvl"
import { ContentLoading } from "@app/Page/ContentLoading";
import { SystemContext } from "@app/AppContext/SystemContext";
import { OutlinedClockIcon, PauseIcon } from "@patternfly/react-icons";

const Events: React.FunctionComponent = () => {

  const systemContext = useContext(SystemContext)

  const [eventList, setEventList] = React.useState<EventInfo[]>([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);

    (new EventApi).getAllPublishedEvents()
      .then(res => {
        setLoading(false)
        setEventList(res)
      })
      .catch( err => {
        systemContext.addNotification(err.toString(), "danger", new Date().getTime())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [''])

  if(loading) {
    return (<ContentLoading />)
  }

  const renderNoEventBanner = () => {
    return (<>
        <EmptyState>
          <EmptyStateHeader
            titleText="Derzeit sind leider keine Veranstaltungen verfügbar"
            headingLevel="h4"
            icon={<EmptyStateIcon icon={OutlinedClockIcon} />}
            // OutlinedClockIcon PauseIcon BellIcon
          />
          <EmptyStateBody>
            Wir freuen uns darauf, bald wieder spannende Events anzubieten.
            Schau später noch einmal vorbei!
          </EmptyStateBody>
        </EmptyState>
    </>)
  }

  const renderEvents = () => {
    return (
      <DescriptionList columnModifier={{ lg: '3Col' }}>
        { eventList.map( elem => { return (<SingleEvent key={Util.nvlN(elem.eventId)} event={elem} />);}) }
      </DescriptionList>
    )
  }

  return (
    <PageSection key={"eventPage"}>
      <Title headingLevel="h1" size="lg">Unsere Events & Veranstaltungen: </Title>
      {
        eventList.length == 0 ? renderNoEventBanner() : renderEvents()
      }
    </PageSection>
  )
}

export { Events };
