import React from "react";

import '../styles/globals.css'
import '../styles/style.css'
import '../styles/responsive.css'

import { Button } from "@patternfly/react-core";
import { AboutDialog } from "@app/Webshop/FrontUI/About/AboutDialog";
import { ArrowRightIcon } from "@patternfly/react-icons";

import ImgFollowUs from "@images/frontUI/followUs.svg";
import ImgAbout from "@images/frontUI/about.svg";
import ImgMission from "@images/frontUI/mission.svg";
export const About: React.FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <React.Fragment>
      <section className="sectionInfo">
        <div className="container-sectionInfo">
          <div className="content-sectionInfo">

            <div className="item" key="0_print_at_home_info">
              <div className="left-side">
                <img src={ImgAbout} alt="Über uns" width={550} height={350} />
              </div>

              <div className="right-side">
                <h3>Über uns: Tick@R</h3>

                <p>
                  Willkommen bei Tick@R - Deiner Eintrittskarte zur Welt der Erlebnisse!
                  Wir sind leidenschaftliche Event-Enthusiasten und haben es uns zur Aufgabe gemacht, die Welt der Live-Unterhaltung für Sie zu öffnen.
                  Gemeinsam erleben, was das Leben zu bieten hat – Wir öffnen die Türen zu unvergesslichen Momenten.
                </p>
              </div>
            </div>

            <div className="item" key="1_problems">
              <div className="left-side">
                <img src={ImgMission} alt="Mission" width={550} height={350} />
              </div>

              <div className="right-side">
                <h3>Unsere Mission</h3>
                <div>
                  Unsere Mission bei Tick@R ist es, Events und Erlebnisse für Menschen auf einfache und unkomplizierte Weise zugänglich zu machen.
                  Wir glauben fest daran, dass ein Ticket nicht nur der Eintritt zu einer Veranstaltung ist, sondern der Schlüssel zu einem unvergesslichen Moment, einer lebensverändernden Erfahrung oder einer herzlichen Begegnung mit Künstlern und Gleichgesinnten.
                </div>
              </div>
            </div>

            <div className="item" key="2_followUs">
              <div className="left-side">
                <img src={ImgFollowUs} alt="Probleme" width={550} height={350} />
              </div>

              <div className="right-side">
                <h3>Unser Team</h3>
                <p>
                  Mein Name ist Peirlberger Jürgen und ich habe Tick@R ins Leben gerufen.
                  Ich arbeite als Softwareentwickler und absolviere nebenberuflich mein Informatik-Studium an der JKU in Linz.
                  Tick@R ist ein privates und nicht kommerzielles Projekt.
                </p>

                <a href={"mailto:office@tickatr.at"}>
                  <Button><b>Melde dich </b> @ <b>TICKATR</b> <ArrowRightIcon /> </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Button variant="primary" onClick={toggleModal} size={"sm"}>
        Plattform-Details ansehen
      </Button>
      <AboutDialog  isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </React.Fragment>
  );
};
