/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    ticketCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    orderRank?: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    ownerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    used?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    modificationUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ticket
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    orderKey?: string;
}

/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketFromJSON(json: any): Ticket {
    return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ticket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketCode': !exists(json, 'ticketCode') ? undefined : json['ticketCode'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'orderRank': !exists(json, 'orderRank') ? undefined : json['orderRank'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'used': !exists(json, 'used') ? undefined : json['used'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
        'orderKey': !exists(json, 'orderKey') ? undefined : json['orderKey'],
    };
}

export function TicketToJSON(value?: Ticket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticketCode': value.ticketCode,
        'eventId': value.eventId,
        'orderRank': value.orderRank,
        'ownerName': value.ownerName,
        'used': value.used,
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'orderKey': value.orderKey,
    };
}

