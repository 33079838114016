import * as React from 'react';

import '../styles/globals.css'
import '../styles/style.css'
import '../styles/responsive.css'
import Hero from "@app/Webshop/FrontUI/Home/components/Hero";
import Services from "@app/Webshop/FrontUI/Home/components/Services";
import InfoSection from "@app/Webshop/FrontUI/Home/components/InfoSection";
import { Link } from "react-router-dom";

const Home: React.FunctionComponent = () => (
  <>
    <div className={"rootHome"} >
      <Hero />
      <Services />
      <InfoSection />

      <footer>
        <div>
          &copy; { new Date().getFullYear() } Tick@R / Peirlberger Jürgen &#8226;&nbsp;
          <Link to="/impressum" target="_blank">Impressum</Link> &#8226;&nbsp;
          <Link to="/datenschutz" target="_blank">Datenschutz</Link>
        </div>
      </footer>
    </div>
  </>
)

export { Home };
