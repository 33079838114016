/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventInfo } from './EventInfo';
import {
    EventInfoFromJSON,
    EventInfoFromJSONTyped,
    EventInfoToJSON,
} from './EventInfo';
import type { OrderReportInfo } from './OrderReportInfo';
import {
    OrderReportInfoFromJSON,
    OrderReportInfoFromJSONTyped,
    OrderReportInfoToJSON,
} from './OrderReportInfo';

/**
 * 
 * @export
 * @interface EventReportResponse
 */
export interface EventReportResponse {
    /**
     * 
     * @type {EventInfo}
     * @memberof EventReportResponse
     */
    event?: EventInfo;
    /**
     * 
     * @type {{ [key: string]: Array<OrderReportInfo>; }}
     * @memberof EventReportResponse
     */
    orders?: { [key: string]: Array<OrderReportInfo>; };
    /**
     * 
     * @type {Array<OrderReportInfo>}
     * @memberof EventReportResponse
     */
    storno?: Array<OrderReportInfo>;
}

/**
 * Check if a given object implements the EventReportResponse interface.
 */
export function instanceOfEventReportResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventReportResponseFromJSON(json: any): EventReportResponse {
    return EventReportResponseFromJSONTyped(json, false);
}

export function EventReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': !exists(json, 'event') ? undefined : EventInfoFromJSON(json['event']),
        'orders': !exists(json, 'orders') ? undefined : json['orders'],
        'storno': !exists(json, 'storno') ? undefined : ((json['storno'] as Array<any>).map(OrderReportInfoFromJSON)),
    };
}

export function EventReportResponseToJSON(value?: EventReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': EventInfoToJSON(value.event),
        'orders': value.orders,
        'storno': value.storno === undefined ? undefined : ((value.storno as Array<any>).map(OrderReportInfoToJSON)),
    };
}

