import React from "react";
import { Card, CardTitle, CardBody, List, ListItem } from "@patternfly/react-core";

const Datenschutz: React.FunctionComponent = () => (
  <>
    <Card isFlat isRounded isLarge>
      <CardTitle>Datenschutzerklärung Tick@R (www.TICKATR.at)</CardTitle>
      <CardBody>
        <b>Zweck der Datenverarbeitung:</b><br/>
        Die Webseite "Tick@R" dient als Verwaltungsplattform für den Verkauf von Tickets für verschiedene Veranstaltungen.
        Ihre persönlichen Daten werden ausschließlich zur Abwicklung der Ticketbestellung, zur Personalisierung der Tickets und zur Verhinderung von Missbrauch verwendet.

        <br/><br/>

        <b>Verantwortliche Personen:</b><br/>
        Verantwortlich im Sinne des Datenschutzrechts für die „Tick@R Verwaltungsplattform“ ist Peirlberger Jürgen (Privatperson). <br/>
        Kontaktmöglichkeit: office[at]tickatr.at

        <br/><br/>

        <b>Haftung für Inhalte der Website:</b><br/>
        Wir entwickeln dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.
        Für gegebene Informationen zu Veranstaltungen sind die jeweiligen Veranstalter verantwortlich.
        Die Veröffentlichung neuer Veranstaltungen wird von unserem Team sorgfältig geprüft.
        Dennoch können wir für die Korrektheit aller Inhalte auf dieser Webseite keine Haftung übernehmen. <br/>

        Ihre Privatsphäre hat für uns höchste Priorität. Im Folgenden erläutern wir Ihnen, wie wir mit Ihren Daten umgehen.

        <br/><br/>

        <b>Externe Links:</b><br/>
        Unsere Seite kann Verknüpfungen zu externen Quellen aufweisen. Zur grundlegenden Nutzung der „Tick@R Verwaltungsplattform“ sind keine Fremdquellen notwendig.
        Aus diesem Grund steht es den Nutzern frei, klar gekennzeichneten Fremdquellen ohne Anwendung dieser Datenschutzerklärung, auf eigenem Risiko, zu nutzen.

        <br/><br/>

        <b>Datenverarbeitung im Detail:</b><br/>

        <List>
          <ListItem>
            Bei der Bestellung von Tickets werden Ihre Kontaktdaten (Name, Adresse, E-Mail) erhoben, um Ihnen das Ticket zuzusenden und Sie über die Veranstaltung zu informieren zu können.
          </ListItem>
          <ListItem>
            Die Zahlungsabwicklung erfolgt direkt durch den jeweiligen Veranstalter. Wir erhalten keine Zahlungsdaten von Ihnen.
          </ListItem>
          <ListItem>
            Bei Postversand erfolgt die gesamte Versandabwicklung beim jeweiligen Veranstalter. Wir haben keinen Einfluss auf Versandzeiten und Korrektheit.
          </ListItem>
          <ListItem>
            Digital zur Verfügung gestellte Tickets (sogenannte „Print@Home“ Tickets) müssen, um Missbrauch zu minimieren, nach Erhalt personalisiert werden.
            Die Personalisierung der Tickets mit Ihren Daten dient der Sicherheit für Käufer und Veranstalter.
            Für die Geheimhaltung des einmalig gültigen QR-Codes am Ticket ist der Käufer verantwortlich.
            Bei Weitergabe ist nur die erstmalige Einlösung gültig. Weitere (missbräuchliche) Versuche sind strafbar.
          </ListItem>
          <ListItem>
            Nach der Veranstaltung werden alle persönlichen Daten unkenntlich gemacht, um Ihre Privatsphäre zu schützen.
          </ListItem>
        </List>

        <br/>

        <b>Weitergabe von Daten:</b><br/>
        Wir geben Ihre persönlichen Daten nur an den jeweiligen Veranstalter weiter, um die Ticketabwicklung zu ermöglichen.
        Ihre Daten werden nicht an Dritte verkauft und keinesfalls für Fremdwerbezwecke genutzt.

        <br/><br/>

        <b>Sicherheit der Daten:</b><br/>
        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten vor unbefugtem Zugriff und Missbrauch zu schützen.
        Eine 100%ige Sicherheit zum Schutz ihrer Daten kann nicht ausgesprochen werden.
        Wir versichern, ihre Daten mit uns bekannten Maßnahmen so gut wie möglich zu schützen.

        <br/><br/>

        <b>Ihre Rechte:</b><br/>
        Sie haben das Recht auf Auskunft über die bei uns gespeicherten Daten sowie das Recht auf Berichtigung, Löschung und Sperrung Ihrer Daten.
        Kontaktieren Sie uns hierfür bitte über die angegebenen Kontaktdaten.

        <br/><br/>

        <b>Privacy policy (english version):</b><br/>
        Our privacy policy for this homepage is written in German.
        If an English version of the privacy policy is required, we can gladly clarify individual questions by email.
        Unfortunately, we cannot offer our privacy policy in other languages.
        Therefore we recommend communication in English!
        If there are any questions, please feel free to contact us on office@tickatr.at <br/>
        Thank you for your understanding !

        <br/><br/>

        <b>Aktualisierung der Datenschutzerklärung:</b><br/>
        Diese Datenschutzerklärung kann gelegentlich aktualisiert werden, um Änderungen in unserer Datenverarbeitung zu reflektieren.
        Bitte überprüfen Sie regelmäßig die Datenschutzerklärung auf unserer Webseite.

        <br/><br/>

        <b>Datum der letzten Aktualisierung:</b> September 2023 (Version 1)

        <br/><br/>

        <b>Fragen / Kontakt:</b><br/>
        Bei Fragen zum Datenschutz oder zur Ausübung Ihrer Datenschutzrechte können Sie uns gerne kontaktieren.

      </CardBody>
    </Card>
  </>
)

export { Datenschutz };