import * as React from 'react';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import {
  PageSection,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody, EmptyStateHeader, EmptyStateFooter,
} from '@patternfly/react-core';
import {generatePath, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import { useNavigate } from "react-router";

export default class ErrorFactory {
  public static generatePath (message : string, component : string) : string {
    return generatePath(`/error?msg=${encodeURIComponent(message)}&component=${component}`)
  }
}

const Error: React.FunctionComponent = () => {

  const [errorMsg, setErrorMsg] = useState("unknown error")
  const [component, setComponent] = useState("unknown component")
  const location = useLocation();

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    const paramMsg = queryParam.get("msg");
    const paramComp = queryParam.get("component");

    if(paramMsg != null) {
      setErrorMsg(decodeURIComponent(paramMsg))
    }

    if(paramComp != null) {
      setComponent(decodeURIComponent(paramComp))
    }

  }, [location.search])

  function GoHomeBtn() {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/');
    }
    return (
      <Button onClick={handleClick}>zur Startseite</Button>
    );
  }

  return (
    <PageSection>
    <EmptyState variant="full">
      <EmptyStateHeader titleText="Es ist ein Fehler passiert..." icon={<EmptyStateIcon icon={ExclamationTriangleIcon} />} headingLevel="h1" />
      <EmptyStateBody>
        Meldung: { errorMsg } <br/>
        Komponente: { component }
      </EmptyStateBody><EmptyStateFooter>
      <GoHomeBtn />
    </EmptyStateFooter></EmptyState>
  </PageSection>
  )
};

export { Error };
