/* tslint:disable */
/* eslint-disable */
/**
 * backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Ticket,
  TicketCheckResponse,
  TicketRedeemRequest,
} from '../models';
import {
    TicketFromJSON,
    TicketToJSON,
    TicketCheckResponseFromJSON,
    TicketCheckResponseToJSON,
    TicketRedeemRequestFromJSON,
    TicketRedeemRequestToJSON,
} from '../models';

export interface GetTicketsRequest {
    key: string;
}

export interface RedeemTicketRequest {
    body?: string;
}

export interface RedeemTicketManualRequest {
    ticketRedeemRequest?: TicketRedeemRequest;
}

export interface SaveNameRequest {
    key?: string;
    name?: string;
    orderRank?: number;
}

/**
 * 
 */
export class TicketApi extends runtime.BaseAPI {

    /**
     */
    async getTicketsRaw(requestParameters: GetTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Ticket>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getTickets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tickets/get/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TicketFromJSON));
    }

    /**
     */
    async getTickets(requestParameters: GetTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Ticket>> {
        const response = await this.getTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async redeemTicketRaw(requestParameters: RedeemTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketCheckResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tickets/redeem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketCheckResponseFromJSON(jsonValue));
    }

    /**
     */
    async redeemTicket(requestParameters: RedeemTicketRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketCheckResponse> {
        const response = await this.redeemTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async redeemTicketManualRaw(requestParameters: RedeemTicketManualRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketCheckResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tickets/redeemManual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketRedeemRequestToJSON(requestParameters.ticketRedeemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketCheckResponseFromJSON(jsonValue));
    }

    /**
     */
    async redeemTicketManual(requestParameters: RedeemTicketManualRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketCheckResponse> {
        const response = await this.redeemTicketManualRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveNameRaw(requestParameters: SaveNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.orderRank !== undefined) {
            queryParameters['orderRank'] = requestParameters.orderRank;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tickets/saveName`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveName(requestParameters: SaveNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveNameRaw(requestParameters, initOverrides);
    }

}
