import React from "react";

import { Button, List, ListItem } from "@patternfly/react-core";
import { NavLink } from "react-router-dom";
import { ArrowRightIcon } from "@patternfly/react-icons";

import ImgQuestion from "@images/frontUI/question.svg"
import ImgProblem from "@images/frontUI/problem.svg"
import ImgFollowUs from "@images/frontUI/followUs.svg"

const InfoSection : React.FunctionComponent = () => {
    return (<>
        <section className="sectionInfo">
            <div className="container-sectionInfo">
                <div className="content-sectionInfo">

                    <div className="item" key="0_print_at_home_info">
                        <div className="left-side">
                            <img src={ImgQuestion} alt="Fragen" width={550} height={350} />
                        </div>

                        <div className="right-side">
                            <h3>Warum Print@Home?</h3>
                            <div>
                                <List>
                                    <ListItem><b>Bequemlichkeit:</b> Sie haben ihre Tickets am Handy immer dabei. Kein Vergessen oder Verlieren.</ListItem>
                                    <ListItem><b>Schnelligkeit:</b> Keine Versand oder Wartezeit. Sie erhalten Ihre Tickets direkt nach dem Zahlungseingang.</ListItem>
                                    <ListItem><b>Umweltfreundlichkeit:</b> Kein Ausdruck oder Versand notwendig. </ListItem>
                                    <ListItem><b>Sicherheit:</b> Die Tickets sind mit persönliche Informationen personalisiert um Missbrauch zu minimieren.</ListItem>
                                </List>
                            </div>
                            <NavLink to={"/events"}>
                                <Button><b>check</b> @ <b>events</b> <ArrowRightIcon /> </Button>
                            </NavLink>
                        </div>
                    </div>

                    <div className="item" key="1_problems">
                        <div className="left-side">
                            <img src={ImgProblem} alt="Probleme" width={550} height={350} />
                        </div>

                        <div className="right-side">
                            <h3>Fragen oder Probleme?</h3>
                            <div>
                                <List>
                                    <ListItem>
                                        <u><b>Schritt 1:</b></u>&nbsp;
                                        Für die Abwicklung von Bestellungen und die Verarbeitung von Zahlungen ist der <u>jeweilige Veranstalter verantwortlich</u>.
                                        Bitte wenden Sie sich unter Angabe ihrer Bestellnummer direkt an den Veranstalter.
                                    </ListItem>
                                    <ListItem>
                                        <u><b>Schritt 2:</b></u>&nbsp;
                                        Haben Sie mit dem Veranstalter kontakt aufgenommen?
                                        Haben Sie nach 2-3 Tagen noch keine Rückmeldung?
                                        In diesem Falle wenden Sie sich direkt an uns.
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </div>

                    <div className="item" key="2_followUs">
                        <div className="left-side">
                            <img src={ImgFollowUs} alt="Probleme" width={550} height={350} />
                        </div>

                        <div className="right-side">
                            <h3>Tick@R für deine Veranstaltung?</h3>
                            <p>
                                Unsere Plattform bietet Ihnen die <b>perfekte Lösung zur effizienten Vermarktung Ihrer Events</b>.
                                Tick@R ist die zentrale Anlaufstelle für reibungslose Ticketverkäufe.
                                Entdecken Sie, wie Tick@R die <b>Organisation ihrer Veranstaltung erleichtert</b> und starten Sie mit uns in eine erfolgreiche Event-Zukunft!
                            </p>

                            <a href={"mailto:office@tickatr.at"}>
                                <Button><b>Melde dich </b> @ <b>TICKATR</b> <ArrowRightIcon /> </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default InfoSection;