import React, { useContext, useEffect } from "react";

import { useCookies } from "react-cookie";
import { SystemContext } from "@app/AppContext/SystemContext";
import { useNavigate } from "react-router";

export const Logout: React.FunctionComponent = () => {
  const [, , deleteCookie] = useCookies(['tickrLogin', "tickrSession"])

  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();

  useEffect(() => {
    systemContext.setSession(undefined)
    deleteCookie("tickrSession", { path : "/eventadmin/" })
    deleteCookie("tickrLogin")
    navigate('/eventadmin/login')
  }, [])

  return (<></>);
};
